<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  provide () {
    return {
      dropdown: this.$attrs
    }
  }
}
</script>
