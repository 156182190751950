import Fuse from 'fuse.js'

import Model, { Request } from '../model'
import APIObject from '../object'

import { LocalFilterController, Filter, RemoteFilter, FilterCategory, FilterOptions } from '../filter'

import CustomField from './customField'
import Images from './images'
import ProductCategory from './productCategory'
import ProductBrand from './productBrand'
import Supplier from './supplier'
import ProductInventoryTransaction from './productInventoryTransaction'
import Tax from './tax'

export default class Product extends Model {
  static modelName () {
    return 'product'
  }

  objectID () {
    return this.idProduct
  }

  relations () {
    return {
      category: { type: ProductCategory },
      imageUrls: { type: Images },
      options: { type: ProductOptions },
      customFields: { type: CustomField },
      inventoryTransactions: { type: ProductInventoryTransaction }
    }
  }

  matchesQuery (query) {
    const lowerQuery = query.toLowerCase()

    if (this.name && this.name.toLowerCase().includes(lowerQuery)) {
      return true
    }

    return false
  }

  static filter (filters) {
    const url = this.modelBaseURL() + '/filter'
    const request = {
      filters
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), ProductFilterResponse)
  }

  static categorised (modifiedSince) {
    const url = this.modelBaseURL() + '/categorised'

    const headers = {}
    if (modifiedSince) {
      headers['If-Modified-Since'] = modifiedSince.toUTCString()
    }

    return this.requestList(Request.get(url, headers), ProductCategorised)
  }

  static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page

    const data = {
      ids
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static listIDs (ids) {
    // use a default runner (to call the list method)
    const that = this

    const runner = (page) => {
      return that.ids(ids, page)
    }

    return this.listRunner(runner)
  }

  duplicate () {
    const url = this.constructor.modelBaseURL() + '/duplicate?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url), this.constructor)
  }

  adjustInventory ({ adjustment, type, otherLabel } = {}) {
    const url = this.constructor.modelBaseURL() + '/adjustInventory?id=' + this.objectID()
    const params = {
      adjustment,
      type,
      otherLabel
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(params)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addPhoto (image, options) {
    const url = this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('image', image)

    const request = Request.post(url, formData)
    request.options = options

    // return this.constructor.requestItem(request, Photo)

    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deletePhoto () {
    const url = this.constructor.modelBaseURL() + '/deletePhoto?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  get image () {
    if (this.hasImage) {
      return this.images?.square || this.imageUrls?.square
    }

    return null
  }

  get initials () {
    return this.name?.charAt(0).toUpperCase()
  }
}

export class ProductCategorised extends APIObject {
  relations () {
    return {
      productCategory: { type: ProductCategory },
      products: { type: Product }
    }
  }
}

export class ProductOptions extends APIObject {
  relations () {
    return {
      productCategories: { type: ProductCategory },
      taxes: { type: Tax }
    }
  }
}

export class ProductFilterResponse extends APIObject {

}

export class ProductFilterController extends LocalFilterController {
  constructor (context) {
    super(Product, { context })

    this.query = new RemoteFilter('query', {
      label: 'Search',
      info: 'Search customer product details',
      component: 'TextField',
      getParams () {
        return {
          query: this.value
        }
      }
    })

    this.category = new RemoteFilter('category', {
      label: 'Category',
      component: 'CheckboxGroupField',
      props: {
        search: true,
        placeholder: 'Filter by category',
        class: 'h-80'
      },
      value: [],
      options: [],
      getParams () {
        return {
          categories: this.value.map(item => item?.idProductCategory || true)
        }
      },
      valueLabel () {
        return this.value.map(item => item?.name || 'No category').join(', ')
      },
      clear () {
        this.value = []
      }
    })

    this.brand = new RemoteFilter('brand', {
      label: 'Brand',
      component: 'CheckboxGroupField',
      props: {
        search: true,
        placeholder: 'Filter by brand',
        class: 'h-80'
      },
      value: [],
      options: [],
      getParams () {
        return {
          brands: this.value.map(item => item?.idProductBrand || true)
        }
      },
      valueLabel () {
        return this.value.map(item => item?.name || 'No brand').join(', ')
      },
      clear () {
        this.value = []
      }
    })

    this.supplier = new RemoteFilter('supplier', {
      label: 'Supplier',
      component: 'CheckboxGroupField',
      props: {
        search: true,
        placeholder: 'Filter by supplier',
        class: 'h-80'
      },
      value: [],
      options: [],
      getParams () {
        return {
          suppliers: this.value.map(item => item?.idSupplier || true)
        }
      },
      valueLabel () {
        return this.value.map(item => item?.name || 'No supplier').join(', ')
      },
      clear () {
        this.value = []
      }
    })

    this.categoryOptions = new FilterOptions({
      async map () {
        const productCategories = await ProductCategory.listAll()
        return productCategories
          .reduce((options, productCategory) => {
            options.push({
              label: productCategory.name,
              value: productCategory
            })

            return options
          }, [{ label: 'No category', value: 'none' }])
      },
      set: (options) => {
        this.category.options = options
      }
    })

    this.brandOptions = new FilterOptions({
      async map () {
        const productBrands = await ProductBrand.listAll()
        return productBrands
          .reduce((options, productBrand) => {
            options.push({
              label: productBrand.name,
              value: productBrand
            })

            return options
          }, [{ label: 'No brand', value: 'none' }])
      },
      set: (options) => {
        this.brand.options = options
      }
    })

    this.supplierOptions = new FilterOptions({
      async map () {
        const suppliers = await Supplier.listAll()
        return suppliers
          .reduce((options, supplier) => {
            options.push({
              label: supplier.name,
              value: supplier
            })

            return options
          }, [{ label: 'No supplier', value: 'none' }])
      },
      set: (options) => {
        this.supplier.options = options
      }
    })

    this.filters = [
      this.category,
      this.brand,
      this.supplier
    ]
  }
}

