import Fuse from 'fuse.js'
import { isValid } from 'date-fns'

import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'
import { LocalFilterController, Filter, RemoteFilter, HybridFilter, FilterCategory, FilterOptions } from '../filter'

import { plugin as $date } from '../../plugins/date'

import DateRangePresets from '../../components/fields/presets/DateRange'

import CustomerCreditCard from './customerCreditCard'
import CustomerNote from './customerNote'
import CustomerPass from './customerPass'
import CustomField from './customField'
import CustomerForm from './customerForm'
import { EmployeeEmployeeType } from './employee'
import GiftCard from './giftCard'
import Order from './order'
import Photo from './photo'
import Product from './product'
import Reservation from './reservation'
import Segment from './segment'
import Upload from './upload'
import Waitlist from './waitlist'

export default class Customer extends Model {
  static modelName () {
    return 'customer'
  }

  objectID () {
    return this.idCustomer || this.id
  }

  relations () {
    return {
      alerts: { type: CustomerNote },
      customerNotes: { type: CustomerNote },
      customFields: { type: CustomField },
      dateOfBirth: { type: DateYMD },
      gender: { type: CustomerGender },
      profilePhoto: { type: Photo },
      sendNotifications: { type: CustomerNotifications },
      stats: { type: CustomerStats },
      consentAccepted: { type: CustomerPrivacyConsent },
      marketingConsentAccepted: { type: CustomerMarketingConsent },
      massEmailUnsubscribed: { type: CustomerMassEmail },
      massSMSUnsubscribed: { type: CustomerMassSMS },
      segments: { type: Segment },
      deleted: { type: CustomerDeleted }
    }
  }

  static filter (filters) {
    const url = this.modelBaseURL() + '/filter'
    const request = {
      filters
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), CustomerFilterResponse)
  }

  /* static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page
    return this.requestList(Request.post(url, JSON.stringify(ids)), this)
  } */

  static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page

    const data = {
      ids
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static listIDs (ids) {
    // use a default runner (to call the list method)
    const that = this

    const runner = (page) => {
      return that.ids(ids, page)
    }

    return this.listRunner(runner)
  }

  erase () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/erase'
    return this.constructor.requestSuccess(Request.get(url))
  }

  static updateSegments ({ customers, add, remove }) {
    const url = this.modelBaseURL() + '/updateSegments'
    const request = {
      customers: customers.map(customer => customer.idCustomer),
      add: add.map(segment => segment.idSegment),
      remove: remove.map(segment => segment.idSegment)
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(request)))
  }

  reservations ({ page = 1, limit = 50 } = {}) {
    const url = this.constructor.modelBaseURL() + '/reservations?id=' + this.objectID() + '&page=' + page + '&limit=' + limit
    return this.constructor.requestList(Request.get(url), Reservation)
  }

  upcomingReservations ({ page = 1, limit = 50 } = {}) {
    const url = this.constructor.modelBaseURL() + '/upcomingReservations?id=' + this.objectID() + '&page=' + page + '&limit=' + limit
    return this.constructor.requestList(Request.get(url), Reservation)
  }

  pastReservations ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/pastReservations?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), Reservation)
  }

  waitlists ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/waitlists?id=' + this.objectID() + '&page=' + page + '&filter=active'
    return this.constructor.requestList(Request.get(url), Waitlist)
  }

  unpaidReservations () {
    const url = this.constructor.modelBaseURL() + '/unpaidReservations?id=' + this.objectID()
    return this.constructor.requestList(Request.get(url), Reservation)
  }

  combinedNotes ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/combinedNotes?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), CustomerCombinedNote)
  }

  orders ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/orders?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), Order)
  }

  forms ({ page = 1, limit = 10, includeItems = false } = {}) {
    const url = this.constructor.modelBaseURL() + '/forms?id=' + this.objectID() + '&page=' + page + '&limit' + limit + '&includeItems=' + (includeItems ? 1 : 0)
    return this.constructor.requestList(Request.get(url), CustomerForm)
  }

  cardsPasses () {
    const url = this.constructor.modelBaseURL() + '/cardsPasses?id=' + this.objectID()
    return this.constructor.requestList(Request.get(url), CustomerCardsPasses)
  }

  passes ({ unpaid = false, page = 1 } = {}) {
    let url = this.constructor.modelBaseURL() + '/passes?id=' + this.objectID() + '&page=' + page

    if (unpaid) {
      url += '&filter=unpaid'
    }

    return this.constructor.requestList(Request.get(url), CustomerPass)
  }

  giftCards ({ unpaid = false } = {}) {
    const url = this.constructor.modelBaseURL() + '/giftCards?id=' + this.objectID() + '&unpaid=' + (unpaid ? 1 : 0)
    return this.constructor.requestList(Request.get(url), GiftCard)
  }

  creditCards ({ unpaid = false } = {}) {
    const url = this.constructor.modelBaseURL() + '/creditCards?id=' + this.objectID()
    return this.constructor.requestList(Request.get(url), CustomerCreditCard)
  }

  creditTransactions ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/creditTransactions?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), CustomerCreditTransaction)
  }

  loyaltyTransactions ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/loyaltyTransactions?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), LoyaltyTransaction)
  }

  updateLoyaltyPointBalance ({ loyaltyPointBalance }) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID()
    const data = { loyaltyPointBalance }
    return this.constructor.requestItem(Request.jsonPut(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  recalculateLoyaltyPointBalance () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/recalculateLoyaltyPoints'
    return this.constructor.requestSuccess(Request.post(url), this.constructor)
  }

  updateCreditBalance ({ creditBalance }) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID()
    const data = { creditBalance }
    return this.constructor.requestItem(Request.jsonPut(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  productHistory () {
    const url = this.constructor.modelBaseURL() + '/productHistory?id=' + this.objectID()
    return this.constructor.requestList(Request.get(url), ProductHistory)
  }

  paymentMethods () {
    const url = this.constructor.modelBaseURL() + '/paymentMethods?id=' + this.objectID()
    return this.constructor.requestList(Request.get(url), CustomerPaymentMethods)
  }

  uploads ({ page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/uploads?id=' + this.objectID() + '&page=' + page
    return this.constructor.requestList(Request.get(url), Upload)
  }

  getPhotos () {
    const url = this.constructor.modelBaseURL() + '/photos?id=' + this.objectID()
    return this.constructor.requestList(Request.get(url), Photo)
  }

  addPhoto ({ photo, caption }, options) {
    const url = this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('photo', photo)
    formData.append('caption', caption)

    const request = Request.post(url, formData)
    request.options = options

    return this.constructor.requestItem(request, Photo)
  }

  static deletePhoto (photo) {
    const url = this.modelBaseURL() + '/deletePhoto?id=' + photo.objectID()
    return this.requestSuccess(Request.get(url))
  }

  static editPhoto (photo) {
    const url = this.modelBaseURL() + '/editPhoto?id=' + photo.objectID()
    return this.requestItem(Request.post(url, photo.requestJSON()), Photo)
  }

  setProfilePhoto (photo) {
    const url = this.constructor.modelBaseURL() + '/setProfilePhoto?id=' + this.objectID() + '&photo=' + photo.idPhoto
    return this.constructor.requestItem(Request.post(url), Customer)
  }

  removeProfilePhoto () {
    const url = this.constructor.modelBaseURL() + '/removeProfilePhoto?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url), Customer)
  }

  merge ({ customers }) {
    const url = this.constructor.modelBaseURL() + '/merge?id=' + this.objectID()
    const request = {
      customers: customers.map((customer) => {
        return customer.idCustomer
      })
    }
    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(request)))
  }

  deleteCreditCard ({ creditCard }) {
    const url = this.constructor.modelBaseURL() + '/deleteCreditCard?id=' + creditCard.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  resendWelcomeEmail () {
    const url = this.constructor.modelBaseURL() + '/resendWelcomeEmail?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  resetPassword ({ password, confirm, sendEmail }) {
    const url = this.constructor.modelBaseURL() + '/resetPassword?id=' + this.objectID() + '&password=' + password + '&confirm=' + confirm + '&sendEmail=' + (sendEmail ? 1 : 0)
    return this.constructor.requestSuccess(Request.get(url))
  }

  exportData () {
    const url = this.constructor.modelBaseURL() + '/exportData?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  archive () {
    const url = this.constructor.modelBaseURL() + '/archive?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  static checkDuplicate ({ page = 1, filters = {}, limit = 20 } = {}) {
    const url = this.modelBaseURL() + '/list?page=' + page + '&limit=' + limit
    const request = {
      ...filters
    }

    return this.requestList(Request.post(url, JSON.stringify(request)), this)
  }

  static bulkArchive (customers, { restore = false } = {}) {
    const url = this.modelBaseURL() + '/bulkArchive?restore=' + (restore ? 1 : 0)

    const data = {
      customers: customers.map(({ idCustomer }) => idCustomer)
    }
    return this.requestSuccess(Request.post(url, JSON.stringify(data)), this)
  }

  get addressLabel () {
    const { address, address2, city, country, postcode, region, suburb } = this
    const items = [address, address2, city, country, postcode, region, suburb]
    let label = ''

    for (const item of items) {
      if (item && item.length) {
        label += `${item} `
      }
    }

    if (label.length) {
      return label
    }

    return null
  }

  get image () {
    if (this.profilePhoto) {
      return this.profilePhoto.imageUrls.square
    }

    return null
  }

  get initials () {
    return ((this.firstName ? this.firstName.charAt(0) : '') + (this.lastName ? this.lastName.charAt(0) : '')).toUpperCase()
  }

  get isCustomerPrivacyConsentAccepted () {
    return this.consentAccepted === CustomerPrivacyConsent.accepted
  }

  get isArchived () {
    return this.deleted === CustomerDeleted.archived
  }

  get isDeleted () {
    return this.deleted === CustomerDeleted.deleted
  }

  get isBirthdayToday () {
    const today = new Date()
    const dateOfBirth = this.dateOfBirth?.value

    if (!dateOfBirth) { return false }

    const date = $date.parse(dateOfBirth)

    return (
      today.getMonth() === date.getMonth() &&
      today.getDate() === date.getDate()
    )
  }
}

export class CustomerCombinedNote extends APIObject {
  relations () {
    return {
      // reservation: { type: Reservation },
      customerNote: { type: CustomerNote },
      type: { type: CombinedNoteType }
    }
  }

  get isTypeCustomerNote () {
    return this.type === CombinedNoteType.customerNote
  }

  get isTypeReservation () {
    return this.type === CombinedNoteType.reservation
  }

  get name () {
    return this.type + ': ' + this.content
  }
}

export class CustomerPaymentMethods extends APIObject {
  relations () {
    return {
      giftCards: { type: GiftCard },
      creditCards: { type: CustomerCreditCard }
    }
  }
}

export class CustomerCardsPasses extends APIObject {
  relations () {
    return {
      passes: { type: CustomerPass },
      giftCards: { type: GiftCard },
      creditCards: { type: CustomerCreditCard }
    }
  }
}

export class CustomerCreditTransaction extends APIObject {
  relations () {
    return {
      customer: { type: Customer },
      order: { type: Order },
      type: { type: CustomerCreditTransactionType }
    }
  }
}

export class LoyaltyTransaction extends APIObject {
  relations () {
    return {
      customer: { type: Customer },
      type: { type: LoyaltyTransactionType }
    }
  }
}

export class ProductHistory extends APIObject {
  relations () {
    return {
      order: { type: Order },
      product: { type: Product }
    }
  }
}

export class CustomerStats extends APIObject {
}

export const CustomerGender = new Enum({
  none: { value: '', description: 'Unspecified' },
  female: { value: 'F', description: 'Female' },
  male: { value: 'M', description: 'Male' },
  nonBinary: { value: 'NB', description: 'Non-binary' }
})

export const CustomerNotifications = new Enum({
  none: { value: 0, description: 'None' },
  all: { value: 1, description: 'All' },
  email: { value: 2, description: 'Email' },
  sms: { value: 3, description: 'SMS' },
  emailPreferred: { value: 4, description: 'Email Preferred' },
  smsPreferred: { value: 5, description: 'SMS Preferred' }
})

export const CustomerMassEmail = new Enum({
  subscribed: { value: 0, description: 'Subscribed' },
  unsubscribed: { value: 1, description: 'Unsubscribed' }
})

export const CustomerMassSMS = new Enum({
  subscribed: { value: 0, description: 'Subscribed' },
  unsubscribed: { value: 1, description: 'Unsubscribed' }
})

export const CustomerPrivacyConsent = new Enum({
  notObtained: { value: null, description: 'Not obtained' },
  accepted: { value: true, description: 'Accepted' },
  declined: { value: false, description: 'Declined' }
})

export const CustomerMarketingConsent = new Enum({
  notObtained: { value: null, description: 'Not obtained' },
  accepted: { value: true, description: 'Accepted' },
  declined: { value: false, description: 'Declined' }
})

export const CombinedNoteType = new Enum({
  customerNote: { value: 'customerNote', description: 'Customer' },
  reservation: { value: 'reservation', description: 'Appointment' }
})

export const CustomerDeleted = new Enum({
  notDeleted: { value: 0, description: 'Not deleted' },
  archived: { value: 1, description: 'Archived' },
  deleted: { value: 1, description: 'Deleted' }
})

export const CustomerCreditTransactionType = new Enum({
  manual: { value: 0, description: 'Manual' },
  purchase: { value: 1, description: 'Purchase' },
  payment: { value: 2, description: 'Payment' },
  refund: { value: 3, description: 'Refund' }
})

export const LoyaltyTransactionType = new Enum({
  manual: { value: 0, description: 'Manual' },
  purchase: { value: 1, description: 'Purchase' },
  payment: { value: 2, description: 'Payment' },
  refund: { value: 3, description: 'Refund' }
})

export class CustomerFilterResponse extends APIObject {

}

export class CustomerFilters extends LocalFilterController {
  constructor (context) {
    super(Customer, { context, custom: true })

    const that = this

    const { $store } = context

    this.hasEmail = new Filter({
      component: 'SegmentField',
      label: 'Email',
      options: [
        { label: 'Has email', value: 'true' },
        { label: 'Has no email', value: 'false' }
      ],
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      matches (customers) {
        if (this.value === 'true') {
          return customers.filter((customer) => {
            return (customer.email && customer.email.length > 0)
          })
        } else if (this.value === 'false') {
          return customers.filter((customer) => {
            return !customer.email
          })
        }
      },
      valueLabel () {
        return (this.value === 'true') ? 'Yes' : 'No'
      }
    })

    this.hasPhone = new Filter({
      component: 'SegmentField',
      label: 'Phone #',
      options: [
        { label: 'Has phone', value: 'true' },
        { label: 'Has no phone', value: 'false' }
      ],
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      matches (customers) {
        if (this.value === 'true') {
          return customers.filter((customer) => {
            return (customer.phone && customer.phone.length > 0)
          })
        } else if (this.value === 'false') {
          return customers.filter((customer) => {
            return !customer.phone
          })
        }
      },
      valueLabel () {
        return (this.value === 'true') ? 'Yes' : 'No'
      }
    })

    this.gender = new Filter({
      component: 'SegmentField',
      label: 'Gender',
      options: [
        { label: 'Unspecified', value: '-' },
        { label: 'Female', value: 'F' },
        { label: 'Male', value: 'M' },
        { label: 'Non-binary', value: 'NB' }
      ],
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      matches (customers) {
        return customers.filter((customer) => {
          if (this.value === '-') {
            return (!customer.gender || !customer.gender.value)
          }
          return (customer.gender && customer.gender.value === this.value)
        })
      },
      valueLabel () {
        if (this.value === '-') {
          return 'None'
        } else if (this.value === 'F') {
          return 'Female'
        } else if (this.value === 'M') {
          return 'Male'
        }
      }
    })

    this.dateOfBirth = new Filter({
      component: 'DateOfBirth',
      label: 'Date of Birth',
      value: {
        day: null,
        month: null,
        year: null,
        hasDob: null
      },
      isEmpty () {
        return (
          this.value.day == null &&
          this.value.month == null &&
          this.value.year == null &&
          this.value.hasDob == null
        )
      },
      validation () {
        const { year, month, day } = this.value
        const valid = isValid(new Date(year || 0, month || 0, day || 1))

        return valid
          ? null
          : {
              status: false,
              message: 'The date you have entered is invalid. Please try again...'
            }
      },
      matches (customers) {
        if (this.value.hasDob === 'false') {
          return customers.filter(customer => !customer.dateOfBirth?.value)
        } else {
          const { year, day } = this.value
          let { month } = this.value

          month = month !== null ? month + 1 : null

          return customers.filter(({ dateOfBirth }) => {
            if (!dateOfBirth?.value) { return false }

            const date = dateOfBirth.value.toString()
            const customer = {
              year: Number(date.substr(0, 4)),
              month: Number(date.substr(4, 2)),
              day: Number(date.substr(6, 2))
            }

            return (
              (day ? day === customer.day : true) &&
              (month ? month === customer.month : true) &&
              (year ? year === customer.year : true)
            )
          })
        }
      },
      valueLabel () {
        if (this.value.day || this.value.month || this.value.year) {
          const format = []
          const { year, month, day } = this.value

          if (day != null) { format.push('do') }
          if (month != null) { format.push('MMM') }
          if (year != null) { format.push('yyyy') }

          return $date.format(new Date(year || 0, month || 0, day || 1), format.join(' '))
        }
      },
      clear () {
        this.value = {
          day: null,
          month: null,
          year: null,
          hasDob: null
        }
      }
    })

    this.hasPhoto = new Filter({
      component: 'SegmentField',
      label: 'Photo',
      options: [
        { label: 'Has photo', value: 'true' },
        { label: 'Has no photo', value: 'false' }
      ],
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      // component: 'CheckboxField',
      // label: 'Has photo',
      info: 'Show customers with photos',
      matches (customers) {
        const value = this.value === 'true'

        return customers.filter((customer) => {
          if (value) {
            return (customer.profilePhoto != null)
          }

          return (customer.profilePhoto == null)
        })
      },
      valueLabel () {
        return (this.value === 'true') ? 'Yes' : 'No'
      }
    })

    this.created = new RemoteFilter('created', {
      label: 'Created',
      info: 'When the customer record was created',
      value: {
        start: null,
        finish: null
      },
      options: [
        DateRangePresets.today,
        DateRangePresets.oneWeek,
        DateRangePresets.twoWeeks,
        DateRangePresets.thisMonth,
        DateRangePresets.lastMonth
      ],
      component: 'DateRangeOptionalField',
      valueLabel () {
        const start = (this.value.start) ? new Date(this.value.start * 1000) : null
        const finish = (this.value.finish) ? new Date(this.value.finish * 1000) : null

        return ((start) ? $date.format(start, $date.presets.short) : '') + ' » ' + ((finish) ? $date.format(finish, $date.presets.short) : '')
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value.start = null
        this.value.finish = null
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.inactive = new RemoteFilter('inactive', {
      label: 'Inactive',
      info: 'Show customers without appointments in this range',
      value: {
        start: null,
        finish: null
      },
      options: [
        DateRangePresets.today,
        DateRangePresets.oneWeek,
        DateRangePresets.twoWeeks,
        DateRangePresets.thisMonth,
        DateRangePresets.lastMonth,
        { ...DateRangePresets.future, name: 'No future appointments' },
        { ...DateRangePresets.past, name: 'No past appointments' }
      ],
      component: 'DateRangeOptionalField',
      valueLabel () {
        const start = (this.value.start) ? new Date(this.value.start * 1000) : null
        const finish = (this.value.finish) ? new Date(this.value.finish * 1000) : null

        return ((start) ? $date.format(start, $date.presets.short) : '') + ' » ' + ((finish) ? $date.format(finish, $date.presets.short) : '')
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value.start = null
        this.value.finish = null
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.active = new RemoteFilter('active', {
      label: 'Active',
      info: 'Show customers with appointments in this range',
      value: {
        start: null,
        finish: null
      },
      options: [
        DateRangePresets.today,
        DateRangePresets.oneWeek,
        DateRangePresets.twoWeeks,
        DateRangePresets.thisMonth,
        DateRangePresets.lastMonth,
        { ...DateRangePresets.future, name: 'Has future appointments' },
        { ...DateRangePresets.past, name: 'Has past appointments' }
      ],
      component: 'DateRangeOptionalField',
      valueLabel () {
        const start = (this.value.start) ? new Date(this.value.start * 1000) : null
        const finish = (this.value.finish) ? new Date(this.value.finish * 1000) : null

        return ((start) ? $date.format(start, $date.presets.short) : '') + ' » ' + ((finish) ? $date.format(finish, $date.presets.short) : '')
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value = {
          start: null,
          finish: null
        }
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.noShows = new RemoteFilter('no-shows', {
      label: 'No shows',
      info: 'Show customers with no show reservations in this range',
      value: {
        start: null,
        finish: null
      },
      options: [
        DateRangePresets.today,
        DateRangePresets.oneWeek,
        DateRangePresets.twoWeeks,
        DateRangePresets.thisMonth,
        DateRangePresets.lastMonth
      ],
      component: 'DateRangeOptionalField',
      valueLabel () {
        const start = (this.value.start) ? new Date(this.value.start * 1000) : null
        const finish = (this.value.finish) ? new Date(this.value.finish * 1000) : null

        return ((start) ? $date.format(start, $date.presets.short) : '') + ' » ' + ((finish) ? $date.format(finish, $date.presets.short) : '')
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value = {
          start: null,
          finish: null
        }
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.cancellations = new RemoteFilter('cancellations', {
      label: 'Cancellations',
      info: 'Show customers with cancellations in this range',
      value: {
        start: null,
        finish: null
      },
      options: [
        DateRangePresets.today,
        DateRangePresets.oneWeek,
        DateRangePresets.twoWeeks,
        DateRangePresets.thisMonth,
        DateRangePresets.lastMonth
      ],
      component: 'DateRangeOptionalField',
      valueLabel () {
        const start = (this.value.start) ? new Date(this.value.start * 1000) : null
        const finish = (this.value.finish) ? new Date(this.value.finish * 1000) : null

        return ((start) ? $date.format(start, $date.presets.short) : '') + ' » ' + ((finish) ? $date.format(finish, $date.presets.short) : '')
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value = {
          start: null,
          finish: null
        }
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.employees = new RemoteFilter('reservations', {
      label: 'Employees',
      info: 'Customers who have booked with the selected employees',
      value: {
        employees: [],
        date: {
          start: null,
          finish: null
        }
      },
      watch: ['date'],
      component: 'FieldsWithDateRange',
      options: {
        employees: {
          key: 'employees',
          component: 'CheckboxGroupField',
          label: 'With',
          visible: true,
          search: true,
          valueLabel: () => {
            const value = this.employees.value.employees
            return value.length ? value.map(({ displayName }) => displayName).join(', ') : 'All Employees'
          },
          items: []
        }
      },
      valueLabel () {
        const start = (this.value.date.start) ? new Date(this.value.date.start * 1000) : null
        const finish = (this.value.date.finish) ? new Date(this.value.date.finish * 1000) : null

        const date = start || finish ? `(${(start) ? $date.format(start, $date.presets.short) : ''} » ${(finish) ? $date.format(finish, $date.presets.short) : ''})` : ''

        return `${this.options.employees.valueLabel()} ${date}`
      },
      getParams () {
        return {
          employees: this.value.employees.map(({ idEmployee }) => { return { id: idEmployee } }),
          from: this.value.date.start,
          to: this.value.date.finish
        }
      },
      isEmpty () {
        return !this.value.date.start && !this.value.date.finish && !this.value.employees.length
      },
      clear () {
        this.value = {
          employees: [],
          date: {
            start: null,
            finish: null
          }
        }
      }
    })

    this.services = new RemoteFilter('reservations', {
      label: 'Services',
      info: 'Customers who have booked the selected services',
      value: {
        services: [],
        employees: [],
        date: {
          start: null,
          finish: null
        }
      },
      watch: ['date'],
      component: 'FieldsWithDateRange',
      options: {
        services: {
          key: 'services',
          component: 'CheckboxCategoriesField',
          label: 'Services',
          visible: false,
          search: true,
          selectCategory: true,
          valueLabel: () => {
            const value = this.services.value.services

            if (value.length) {
              const labels = value.slice(0, 3).map(({ title }) => title).join(', ')
              return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
            }

            return 'All Services'
          },
          items: []
        },
        employees: {
          key: 'employees',
          component: 'CheckboxGroupField',
          label: 'With',
          visible: false,
          search: true,
          valueLabel: () => {
            const value = this.services.value.employees

            if (value.length) {
              const labels = value.slice(0, 3).map(({ displayName }) => displayName).join(', ')
              return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
            }

            return 'All Employees'
          },
          items: []
        }
      },
      valueLabel () {
        const start = (this.value.date.start) ? new Date(this.value.date.start * 1000) : null
        const finish = (this.value.date.finish) ? new Date(this.value.date.finish * 1000) : null

        const date = start || finish ? `(${(start) ? $date.format(start, $date.presets.short) : ''} » ${(finish) ? $date.format(finish, $date.presets.short) : ''})` : ''

        return `${this.options.services.valueLabel()} with ${this.options.employees.valueLabel()} ${date}`
      },
      getParams () {
        return {
          services: this.value.services.map(({ idService }) => { return { id: idService } }),
          employees: this.value.employees.map(({ idEmployee }) => { return { id: idEmployee } }),
          from: this.value.date.start,
          to: this.value.date.finish
        }
      },
      isEmpty () {
        return !this.value.date.start && !this.value.date.finish && !this.value.services.length && !this.value.employees.length
      },
      clear () {
        this.value = {
          services: [],
          employees: [],
          date: {
            start: null,
            finish: null
          }
        }
      }
    })

    this.segments = new Filter({
      label: 'Segments',
      component: 'CheckboxGroupField',
      options: [],
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-100 dark:bg-gray-900 h-80 dark:border-gray-800 px-1'
      },
      clear () {
        this.value = []
      },
      valueLabel () {
        const value = this.value
        const labels = value.slice(0, 3).map(({ name }) => name).join(', ')
        return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
      },
      matches (customers) {
        const selected = this.value.map(({ idSegment }) =>  idSegment)
        return customers.filter((customer) => {
          customer = customer.segments.map(({ idSegment }) =>  idSegment)
          return customer.some(item => selected.includes(item))
        })
      }
    })

    this.emailBounced = new Filter({
      label: 'Email bounced',
      info: 'Customer has unsuccessful email attempts',
      component: 'ToggleField',
      valueLabel () {
        return 'Yes'
      },
      matches (customers) {
        return customers.filter((customer) => {
          return customer.emailBounced
        })
      }
    })

    /** todo **/
    this.duplicate = new RemoteFilter('duplicates', {
      label: 'Duplicate',
      info: 'Show customers that have more than one record',
      component: 'ToggleField',
      getParams () {
        return []
      }
    })

    this.archived = new HybridFilter('archived', {
      label: 'Archived',
      info: 'Show archived customers',
      component: 'SegmentField',
      value: 'notArchived',
      // force: true,
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      options: [
        // { label: 'All', value: 'all' },
        { label: 'Archived', value: 'archived' },
        { label: 'Not archived', value: 'notArchived' }
      ],
      clear () {
        this.value = 'notArchived'
      },
      valueLabel () {
        if (this.value === 'all') {
          return 'All'
        }

        if (this.value === 'archived') {
          return 'Yes'
        }

        if (this.value === 'notArchived') {
          return null // 'No'
        }

        return null
      },
      matches (customers) {
        return customers.filter((customer) => {
          return true
        })
      },
      isEmpty () {
        return false // this.value === 'notArchived'
      },
      filterRemotely () {
        let remotes = 0

        for (const key in that.remote) {
          const filter = that.remote[key]
          if (key !== 'archived' && !filter.isEmpty() && filter instanceof RemoteFilter) {
            remotes++
          }
        }

        return remotes > 0 || this.value === 'all' || this.value === 'archived'
      },
      filterLocally () {
        let remotes = 0

        for (const key in that.remote) {
          const filter = that.remote[key]
          if (key !== 'archived' && !filter.isEmpty() && filter instanceof RemoteFilter) {
            remotes++
          }
        }

        return remotes === 0 && this.value === 'notArchived'
      },
      getParams () {
        if (this.value === 'all') {
          return { archived: null }
        }

        if (this.value === 'archived') {
          return { archived: true }
        }
        
        if (this.value === 'notArchived') {
          return { archived: false }
        }
      }
    })

    this.allowOnlineBooking = new Filter({
      label: 'Online booking',
      component: 'SegmentField',
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      options: [
        { label: 'Allowed', value: 'true' },
        { label: 'Not allowed', value: 'false' }
      ],
      matches (customers) {
        if (this.value === 'true') {
          return customers.filter((customer) => {
            return customer.allowOnlineBooking
          })
        } else if (this.value === 'false') {
          return customers.filter((customer) => {
            return !customer.allowOnlineBooking
          })
        }
      },
      valueLabel () {
        return (this.value === 'true') ? 'Yes' : 'No'
      }
    })

    this.loyaltyPoints = new Filter({
      label: 'Loyalty points',
      component: 'SegmentField',
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      options: [
        { label: 'Has loyalty points', value: 'true' },
        { label: 'No loyalty points', value: 'false' }
      ],
      matches (customers) {
        if (this.value === 'true') {
          return customers.filter((customer) => {
            return customer.loyaltyPointBalance > 0
          })
        } else if (this.value === 'false') {
          return customers.filter((customer) => {
            return customer.loyaltyPointBalance <= 0
          })
        }
      },
      valueLabel () {
        return (this.value === 'true') ? 'Yes' : 'No'
      }
    })

    this.giftCards = new RemoteFilter('giftCards', {
      label: 'Gift cards',
      info: 'Show customers that have a gift card',
      component: 'SegmentField',
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      options: [
        { label: 'Valid', value: 'valid' },
        { label: 'Any', value: 'any' }
      ],
      getParams () {
        return (this.value === 'valid') ? { valid: true } : []
      },
      valueLabel () {
        return (this.value === 'valid') ? 'Valid' : 'Any'
      }
    })

    this.privacyConsent = new Filter({
      label: 'Privacy consent',
      component: 'RadioGroupField',
      options: [
        { label: 'Accepted', value: 'accepted' },
        { label: 'Declined', value: 'declined' },
        { label: 'Not obtained', value: 'notObtained' }
      ],
      valueLabel () {
        return CustomerPrivacyConsent[this.value].description
      },
      matches (customers) {
        return customers.filter((customer) => {
          return customer.consentAccepted === CustomerPrivacyConsent[this.value]
        })
      }
    })

    this.marketingConsent = new Filter({
      label: 'Marketing consent',
      component: 'RadioGroupField',
      options: [
        { label: 'Accepted', value: 'accepted' },
        { label: 'Declined', value: 'declined' },
        { label: 'Not obtained', value: 'notObtained' }
      ],
      valueLabel () {
        return CustomerMarketingConsent[this.value].description
      },
      matches (customers) {
        return customers.filter((customer) => {
          return customer.marketingConsentAccepted === CustomerMarketingConsent[this.value]
        })
      }
    })

    this.massEmailSubscription = new Filter({
      label: 'Mass Email Subscription',
      component: 'SegmentField',
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      options: [
        { label: 'Subscribed', value: 'subscribed' },
        { label: 'Unubscribed', value: 'unsubscribed' }
      ],
      valueLabel () {
        return CustomerMassEmail[this.value].description
      },
      matches (customers) {
        return customers.filter((customer) => {
          return customer.massEmailUnsubscribed === CustomerMassEmail[this.value]
        })
      }
    })

    this.massSMSSubscription = new Filter({
      label: 'Mass SMS Subscription',
      component: 'SegmentField',
      props: {
        outline: true, baseClass: 'flex rounded-lg p-1 h-9 focus-within:ring-2'
      },
      options: [
        { label: 'Subscribed', value: 'subscribed' },
        { label: 'Unubscribed', value: 'unsubscribed' }
      ],
      valueLabel () {
        return CustomerMassSMS[this.value].description
      },
      matches (customers) {
        return customers.filter((customer) => {
          return customer.massSMSUnsubscribed === CustomerMassSMS[this.value]
        })
      }
    })

    this.formsFilled = new RemoteFilter('forms', {
      label: 'Forms filled',
      info: 'Show customers that have completed these forms',
      component: 'CheckboxGroupField',
      options: [],
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-100 dark:bg-gray-900 h-80 dark:border-gray-800 px-1'
      },
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ title }) => title).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All Forms'
      },
      getParams () {
        return {
          forms: this.value.map(({ idForm }) => idForm)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.formsRequested = new RemoteFilter('requested-forms', {
      label: 'Forms requested',
      info: 'Show customers that have had these forms requested',
      component: 'CheckboxGroupField',
      options: [],
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-100 dark:bg-gray-900 h-80 dark:border-gray-800 px-1'
      },
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ title }) => title).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All Forms'
      },
      getParams () {
        return {
          forms: this.value.map(({ idForm }) => idForm)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.formsNotRequested = new RemoteFilter('no-forms', {
      label: 'Forms not requested',
      info: 'Show customers that haven\'t completed these forms',
      component: 'CheckboxGroupField',
      options: [],
      props: {
        search: true,
        class: 'border rounded overflow-hidden bg-gray-100 dark:bg-gray-900 h-80 dark:border-gray-800 px-1'
      },
      valueLabel () {
        const value = this.value

        if (value.length) {
          const labels = value.slice(0, 3).map(({ title }) => title).join(', ')
          return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
        }

        return 'All Forms'
      },
      getParams () {
        return {
          forms: this.value.map(({ idForm }) => idForm)
        }
      },
      clear () {
        this.value = []
      }
    })

    this.products = new RemoteFilter('products', {
      label: 'Products',
      info: 'Has purchased the selected products',
      component: 'FieldsWithDateRange',
      watch: ['date'],
      options: {
        products: {
          key: 'products',
          component: 'CheckboxCategoriesField',
          label: 'Products',
          visible: false,
          search: true,
          selectCategory: true,
          valueLabel: () => {
            const value = this.products.value.products

            if (value.length) {
              const labels = value.slice(0, 3).map(({ name }) => name).join(', ')
              return labels + (value.length > 3 ? ` and ${value.length - 3} more` : '')
            }

            return 'All Products'
          },
          items: []
        }
      },
      value: {
        products: [],
        date: {
          start: null,
          finish: null
        }
      },
      valueLabel () {
        const start = (this.value.date.start) ? new Date(this.value.date.start * 1000) : null
        const finish = (this.value.date.finish) ? new Date(this.value.date.finish * 1000) : null

        const date = start || finish ? `(${(start) ? $date.format(start, $date.presets.short) : ''} » ${(finish) ? $date.format(finish, $date.presets.short) : ''})` : ''

        return `${this.options.products.valueLabel()} ${date}`
      },
      getParams () {
        return {
          products: this.value.products.map(({ idProduct }) => { return { id: idProduct } }),
          from: this.value.date.start,
          to: this.value.date.finish
        }
      },
      isEmpty () {
        return !this.value.date.start && !this.value.date.finish && !this.value.products.length
      },
      clear () {
        this.value = {
          products: [],
          date: {
            start: null,
            finish: null
          }
        }
      }
    })

    this.formOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['form/all']).map(form => ({
            value: form,
            text: form.title,
            info: '#' + form.idForm
          }))
          : []
      },
      set: (options) => {
        this.formsFilled.options = options
        this.formsRequested.options = options
        this.formsNotRequested.options = options
      }
    })

    this.employeeOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['employee/all'])
            .filter(({ type }) => type === EmployeeEmployeeType.employee)
            .map(employee => ({
              value: employee,
              text: employee.displayName,
              info: employee.type ? employee.type.description : 'Employee',
              image: employee.images ? employee.images.square : null
            }))
          : []
      },
      set: (options) => {
        this.employees.options.employees.items = options
        this.services.options.employees.items = options
      }
    })

    this.serviceOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['service/categorised']).map(category => ({
            label: category.name || '',
            options: category.services?.map(service => ({
              value: service,
              text: service.title
            }))
          }))
          : []
      },
      set: (options) => {
        this.services.options.services.items = options
      }
    })

    this.segmentOptions = new FilterOptions({
      map () {
        return $store
          ? Object.values($store.getters['segment/all'])
            .map(segment => ({
              value: segment,
              text: segment.name,
              colour: segment.hexColour
            }))
          : []
      },
      set: (options) => {
        this.segments.options = options
      }
    })

    this.productOptions = new FilterOptions({
      map () {
        const products = $store ? $store.getters['product/all'] : null
        return products
          ? Object.values(products).map(category => ({
            label: category.category ? category.category.name : 'Other',
            options: category.products.map(product => ({
              value: product,
              text: product.name
            }))
          }))
          : []
      },
      set: (options) => {
        this.products.options.products.items = options
      }
    })

    this.search = new Filter({
      label: 'Search',
      force: true,
      matches (customers) {
        const fuse = new Fuse(customers, {
          keys: [
            {
              name: 'displayName',
              weight: 2
            },
            'email',
            'phone'
          ],
          threshold: 0.3
        })

        const results = fuse.search(this.value)
        return results.map(({ item }) => item)
      }
    })

    this.categorised = [
      new FilterCategory({
        label: 'Personal',
        filters: [this.hasEmail, this.hasPhone, this.gender, this.dateOfBirth, this.hasPhoto]
      }),
      new FilterCategory({
        label: 'Appointments',
        filters: [this.services, this.inactive, this.active, this.noShows, this.cancellations]
      }),
      new FilterCategory({
        label: 'Segments',
        filters: [this.segments],
        features: ['segments']
      }),
      new FilterCategory({
        label: 'Marketing',
        filters: [this.loyaltyPoints, this.giftCards, this.allowOnlineBooking, this.privacyConsent, this.marketingConsent, this.massEmailSubscription, this.massSMSSubscription, this.emailBounced]
      }),
      new FilterCategory({
        label: 'Forms',
        filters: [this.formsFilled, this.formsRequested, this.formsNotRequested],
        features: ['forms']
      }),
      new FilterCategory({
        label: 'Products',
        filters: [this.products]
      }),
      new FilterCategory({
        label: 'Custom fields',
        filters: this.custom,
        features: ['custom-fields']
      }),
      new FilterCategory({
        label: 'Manage',
        filters: [this.duplicate, this.archived, this.created]
      })
    ]
  }
}
