<template>
  <li class="my-px">
    <a
      class="w-full flex flex-row flex-grow items-center h-8 rounded-lg  relative hover:bg-white/10 transition-colors"
      :class="[
        collapse ? 'px-2 text-white/90 dark:text-white/90' : 'px-3 text-white/70 dark:text-white/70',
      ]"
      @click="toggle"
    >
      <div class="w-full flex items-center">
        <span
          class="flex items-center justify-center text-lg "
          :class="[
            collapse ? '-ml-px' : ''
          ]"
        >
          <o-icon
            icon="message"
            :size="18"
          />
        </span>
        <span class="ml-4 text-base" :class="[ collapse? 'sm:hidden' : '']">Support</span>
      </div>
    </a>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      collapse: 'menu'
    })
  },
  methods: {
    toggle () {
      if (window.Beacon) {
        window.Beacon('toggle')
      }
    }
  }
}

</script>
