import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Service from './service'
import Tax from './tax'

export default class Pass extends Model {
  static modelName () {
    return 'pass'
  }

  objectID () {
    return this.idPass
  }

  relations () {
    return {
      cancelReservationBehaviour: { type: CancelReservationBehaviour },
      deleteReservationBehaviour: { type: DeleteReservationBehaviour },
      expiryMode: { type: ExpiryMode },
      options: { type: PassOptions }
    }
  }

  get expires () {
    return this.expiryDays !== 0
  }

  set expires (value) {
    this.expiryDays = value ? 1 : 0
  }

  get isLimitedUse () {
    return this.numberOfUses !== 0
  }

  set isLimitedUse (value) {
    this.numberOfUses = value ? 1 : 0
  }

  get image () {
    if (this.hasImage) {
      return this.images?.square || this.imageUrls?.square
    }

    return null
  }

  get initials () {
    return this.name.charAt(0).toUpperCase()
  }

  addPhoto (image, options) {
    const url = this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('image', image)

    const request = Request.post(url, formData)
    request.options = options

    // return this.constructor.requestItem(request, Photo)

    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deletePhoto () {
    const url = this.constructor.modelBaseURL() + '/deletePhoto?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }
}

export class PassOptions extends APIObject {
  relations () {
    return {
      services: { type: Service },
      taxes: { type: Tax }
    }
  }
}

export const ExpiryMode = new Enum({
  booking: { value: 1, description: 'Booking must be made within validity period' },
  appointment: { value: 2, description: 'Date of appointment must be within validity period' }
})

export const CancelReservationBehaviour = new Enum({
  return: { value: 0, description: 'Return pass usage' },
  leave: { value: 1, description: 'Leave pass usage' }
})

export const DeleteReservationBehaviour = new Enum({
  return: { value: 0, description: 'Return pass usage' },
  leave: { value: 1, description: 'Leave pass usage' }
})
