import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  sync: {
    running: false,
    date: null
  },
  hydrated: false,
  services: [],
  employees: [],
  count: null
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  },
  updateServices (state, services) {
    state.services = Object.freeze(API.ServiceCategorised.ensure(services))
  },
  updateEmployees (state, employees) {
    state.employees = Object.freeze(API.Employee.ensure(employees))
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncDate (state) {
    return state.sync.date
  },
  services (state) {
    return state.services
  },
  servicesServiceList (state) {
    return state.services
      .map(service => service.services)
      .flat()
  },
  servicesCategoryList (state) {
    return state.services
      .map(service => service.category)
  },
  getServiceByID: state => (idService) => {
    const services = state.services
      .map(service => service.services)
      .flat()

    return services
      .find(service => Number(idService) === service.idService)
  },
  employees (state) {
    return state.employees
  },
  employeesTypeEmployee (state) {
    return API.Employee.ensure(state.employees)
      .filter(employee => employee.isEmployeeType)
  },
  employeesTypeResource (state) {
    return API.Employee.ensure(state.employees)
      .filter(employee => employee.isResourceType)
  },
  employeesForService: state => (service) => {
    return API.Employee.ensure(state.employees)
      .filter(employee => service.employeeIDs.includes(employee.idEmployee))
  },
  employeesForResource: state => (serviceResource) => {
    return API.Employee.ensure(state.employees)
      .filter(employee => serviceResource.resourceIDs.includes(employee.idEmployee))
  },
  getEmployeeByID: state => (idEmployee) => {
    return API.Employee.ensure(state.employees)
      .find(employee => idEmployee === employee.idEmployee)
  }
}

export const actions = {
  async sync ({ commit, state, rootState }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active

    if (!state.hydrated || change) {
      const services = await localforage.getItem(guid + '.servicesEmployees.services')
      const employees = await localforage.getItem(guid + '.servicesEmployees.employees')
      const sync = await localforage.getItem(guid + '.servicesEmployees.sync')

      if (services) {
        commit('updateServices', services)
      }
      if (employees) {
        commit('updateEmployees', employees)
      }
      if (sync) {
        commit('setSync', sync)
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true })

    const sinceDate = (force) ? null : state.sync.date


    await API.Booking.servicesEmployees(sinceDate).catch((err) => {
      console.log(err)
    }).then((servicesEmployees) => {
      if (servicesEmployees) {
        commit('updateServices', servicesEmployees.services)
        commit('updateEmployees', servicesEmployees.employees)

        localforage.setItem(guid + '.servicesEmployees.services', state.services)
        localforage.setItem(guid + '.servicesEmployees.employees', state.employees)
      }

      commit('setSync', { running: false, date })
      localforage.setItem(guid + '.servicesEmployees.sync', state.sync)
    })
  }
}
