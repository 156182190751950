import Model, { Request } from '../model'
import APIObject from '../object'

export default class Report extends Model {
  static modelName () {
    return 'report'
  }

  relations () {
    return {
      filters: { type: ReportFilter },
      summaryItems: { type: ReportSummaryItem },
      summaryHeroItems: { type: ReportSummaryHeroItem },
      summaryLinks: { type: ReportSummaryLink },
      sections: { type: ReportSection },
      dates: { type: ReportDates },
      datesScope: { type: ReportDateScope }
    }
  }

  static all () {
    const url = this.modelBaseURL() + '/all'
    return this.requestList(Request.get(url), ReportInfo)
  }

  static report (type, params = {}) {
    const data = { type, ...params }

    if (params.scope === 'custom') {
      data.offsetTimeZone = 0
    }

    const query = new URLSearchParams(data).toString()
    const url = this.modelBaseURL() + '/build?' + query
    return this.requestItem(Request.get(url), this)
  }

  static export (params) {
    const url = this.modelBaseURL() + '/build' + params
    return this.requestData(Request.get(url))
  }

  link (link) {
    const url = this.constructor.modelBaseURL() + link
    return this.constructor.requestItem(Request.get(url), this.constructor).then(res => this.updateSelf(res))
  }
}

export class ReportInfo extends Model {
  static modelName () {
    return 'reportInfo'
  }
}

export class ReportSection extends APIObject {
  relations () {
    return {
      lines: { type: ReportSectionLine }
    }
  }
}

export class ReportSectionLine extends APIObject {
  relations () {
    return {
      links: { type: ReportSectionLineLink }
    }
  }
}

export class ReportSectionLineLink extends APIObject {
}

export class ReportFilter extends APIObject {
  relations () {
    return {
      values: { type: ReportFilterValue }
    }
  }

  get value () {
    return this.values.find(option => option.active)
  }
}

export class ReportFilterValue extends APIObject {
}

export class ReportSummaryItem extends APIObject {
}

export class ReportSummaryHeroItem extends APIObject {
}


export class ReportSummaryLink extends APIObject {
}

export class ReportDates extends APIObject {
}

export class ReportDateScope extends APIObject {
}
