<template>
  <o-tooltip v-if="allow" :text="label">
    <o-panel-link
      :tag="component"
      :path="path"
      :slim="slim"
      v-bind="$attrs"
    >
      <template v-if="!slim">
        {{ label }}
      </template>
    </o-panel-link>
  </o-tooltip>
</template>

<script>
export default {
  name: 'OHistoryLink',
  props: {
    item: {
      type: Object,
      required: true
    },
    model: {
      type: String,
      required: true
    },
    component: {
      type: String,
      default: 'OButton'
    },
    slim: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'History'
    }
  },
  computed: {
    allow () {
      return this.$grants.all({ history: ['view'] }) // this.item.allow.history
    },
    id () {
      return this.item.objectID()
    },
    path () {
      const { model, id } = this
      return `/history?modelType=${model}&primaryKey=${id}`
    }
  }
}
</script>
