<template>
  <tippy
    :class="baseClass"
    :interactive="interactive"
    :placement="placement"
    :trigger="trigger"
    :offset="offset"
  >
    <template #trigger>
      <slot />
    </template>

    <slot name="content">
      <div class="text-sm" v-html="text" />
    </slot>
  </tippy>
</template>

<script>
import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy, {
  zIndex: 85000
})

Vue.component('Tippy', TippyComponent)

export default {
  name: 'OTooltip',
  props: {
    text: {
      type: String,
      default: null
    },
    trigger: {
      type: String,
      default: 'mouseover, mouseleave, mouseenter'
    },
    placement: {
      type: String,
      default: 'top'
    },
    interactive: {
      type: Boolean,
      default: false
    },
    baseClass: {
      type: String,
      default: 'o-tooltip'
    },
    offset: {
      type: [String, Number],
      default: '0'
    }
  }
}
</script>

<style lang="scss">
.o-tooltip {
  > div {
    height: 100%;
    display: flex;

  }
}

.tippy-tooltip {
  box-shadow: none !important
}
</style>
