import Model, { Request } from '../model'
import APIObject from '../object'

export default class ReservationStatusType extends Model {
  static modelName () {
    return 'ReservationStatusType'
  }

  objectID () {
    return this.idReservationStatusType
  }

  relations () {
    return {
      options: { type: ReservationStatusTypeOptions }
    }
  }
}

export class ReservationStatusTypeOptions extends APIObject {
  relations () {
    return {
      colors: { type: ReservationStatusTypeOptionsColor }
    }
  }
}

export class ReservationStatusTypeOptionsColor extends APIObject {
}
