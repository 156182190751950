import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class Update extends Model {
  static modelName () {
    return 'Updates'
  }

  relations () {
    return {
      status: { type: UpdateStatus },
      type: { type: UpdateType }
    }
  }

  static counts () {
    const url = this.modelBaseURL() + '/newsCount'
    return this.requestItem(Request.get(url), UpdateNewsCount)
  }

  static unread () {
    const url = this.modelBaseURL() + '/recentNews'
    return this.requestList(Request.get(url), this)
  }

  static markAsRead () {
    const url = this.modelBaseURL() + '/markNewsRead'
    return this.requestSuccess(Request.get(url))
  }

  static markUnread (time) {
    const url = this.modelBaseURL() + '/markNewsRead?time=' + time
    return this.requestSuccess(Request.get(url))
  }

  get isUpdateTypeNews () {
    return this.type === UpdateType.news
  }

  get isUpdateTypeAppUpdate () {
    return this.type === UpdateType.appUpdate
  }

  get isUpdateTypeNewFeature () {
    return this.type === UpdateType.newFeature
  }

  get isUpdateTypeNewIntegration () {
    return this.type === UpdateType.newIntegration
  }

  get isUpdateTypeArticle () {
    return this.type === UpdateType.article
  }

  get isUpdateStatusDraft () {
    return this.status === UpdateStatus.draft
  }

  get isUpdateStatusPublished () {
    return this.status === UpdateStatus.published
  }
}

export class UpdateNewsCount extends APIObject {
}

export const UpdateType = new Enum({
  news: { value: 0, description: 'News' },
  appUpdate: { value: 1, description: 'Update' },
  newFeature: { value: 2, description: 'Feature' },
  newIntegration: { value: 3, description: 'Integration' },
  article: { value: 4, description: 'Article' }
})

export const UpdateStatus = new Enum({
  draft: { value: 0, description: 'Draft' },
  published: { value: 1, description: 'Published' }
})
