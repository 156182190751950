<template>
    <div class="relative bg-white overflow-visible w-screen">
        <router-view />
    </div>
</template>

<script>
export default {
  head: {
    htmlAttrs: {
      class: ['fullscreen']
    }
  }
}
</script>