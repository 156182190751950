<template>
  <div v-if="error" class="w-full flex flex-col items-center justify-center my-10 max-w-md mx-auto">
    <p class="text-red-500 uppercase tracking-widest font-medium">
      Error
    </p>
    <h2 class="text-4xl font-bold text-secondary mb-3 mt-1">
      Sorry, something went wrong
    </h2>

    <p class="text-gray-600 dark:text-gray-300 text-md">
      {{ error }}
    </p>

    <div class="w-full border rounded-md divide-y mt-8">
      <div class="p-4 flex items-center">
        <div class="w-12 h-12 bg-blue-200 dark:bg-blue-800/30 text-blue-600 dark:text-blue-500 flex items-center justify-center rounded-lg mr-3">
          <o-icon icon="menuHelp" :size="22" />
        </div>

        <div>
          <p class="text-gray-800 dark:text-gray-100 font-medium">
            Help Guides
          </p>
          <p class="text-sm text-gray-600 dark:text-gray-300">
            Use our range of help guides to learn everything you need
          </p>
        </div>
      </div>

      <div class="p-4 flex items-center">
        <div class="w-12 h-12 bg-blue-200 dark:bg-blue-800/30 text-blue-600 dark:text-blue-500 flex items-center justify-center rounded-lg mr-3">
          <o-icon icon="menuHelp" :size="22" />
        </div>

        <div>
          <p class="text-gray-800 dark:text-gray-100 font-medium">
            Tutorials
          </p>
          <p class="text-sm text-gray-600 dark:text-gray-300">
            Use our catalogue of video tutorials
          </p>
        </div>
      </div>

      <div class="p-4 flex items-center">
        <div class="w-12 h-12 bg-blue-200 dark:bg-blue-800/30 text-blue-600 dark:text-blue-500 flex items-center justify-center rounded-lg mr-3">
          <o-icon icon="menuSupport" :size="22" />
        </div>

        <div>
          <p class="text-gray-800 dark:text-gray-100 font-medium">
            Send us a message
          </p>
          <p class="text-sm text-gray-600 dark:text-gray-300">
            Start a live chat with our support team
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OError',
  props: {
    error: {
      type: [Object, Array, String, Error, Boolean],
      default: null
    }
  }
}
</script>
