import { extend } from 'vee-validate'
import { required, email, min_value, between, numeric, min, max, length, digits, integer, alpha_num } from 'vee-validate/dist/rules'

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} must only contain alpha-numeric characters'
})

extend('email', {
  ...email,
  message: '{_field_} is not a valid email address'
})

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('min_value', {
  ...min_value,
  message: '{_field_} must be greater than {min}'
})

extend('between', {
  ...between,
  message: '{_field_} must be between {min} and {max}'
})

extend('numeric', {
  ...numeric,
  message: '{_field_} must be a number'
})

extend('min', {
  ...min,
  message: '{_field_} must be a longer than {min} characters'
})

extend('max', {
  ...max,
  message: '{_field_} must be a shorter than {length} characters'
})

extend('length', {
  ...length,
  message: '{_field_} must be a exactly {length} characters'
})

extend('digits', {
  ...digits,
  message: 'The {_field_} must be numeric and exactly contain {digits} digits'
})

extend('integer', {
  ...integer,
  message: 'The {_field_} must only include numbers'
})

extend('greater', {
  params: ['target'],
  validate (value, { target }) {
    return value > target
  },
  message: '{_field_} must be greater than {target}'
})

extend('timeStartFinish', {
  params: ['target'],
  validate (value, { target }) {
    return value > target
  },
  message: '{_field_} must be later than {target}'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match'
})

extend('pin', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Pin confirmation does not match'
})

extend('currency', {
  validate (value) {
    const regex = /\d{0,2}(\.\d{1,2})?/
    return regex.test(value)
  },
  message: '{_field_} is not a valid amount'
})

extend('facebook', {
  validate (value) {
    const regex = /^[a-zA-Z0-9.-]+$/
    return regex.test(value)
  },
  message: 'Invalid Facebook ID'
})

extend('instagram', {
  validate (value) {
    const regex = /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/
    return regex.test(value)
  },
  message: 'Invalid Instagram username'
})

extend('twitter', {
  validate (value) {
    const regex = /^[a-zA-Z0-9]([a-zA-Z0-9_]{0,14})$/
    return regex.test(value)
  },
  message: 'Invalid Twitter handle'
})

extend('pinterest', {
  validate (value) {
    const regex = /^[a-zA-Z0-9.-]{3,30}$/
    return regex.test(value)
  },
  message: 'Invalid Pinterest username'
})

extend('tiktok', {
  validate (value) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9._]{1,23}$/
    return regex.test(value)
  },
  message: 'Invalid TikTok username'
})

extend('whatsapp', {
  validate (value) {
    const regex = /^[a-zA-Z0-9+/]+={0,2}$/
    return regex.test(value)
  },
  message: 'Invalid WhatsApp channel ID'
})

extend('youtube', {
  validate (value) {
    const regex = /^[a-zA-Z0-9_.-]{3,30}$/
    return regex.test(value)
  },
  message: 'Invalid YouTube handle'
})

extend('linkedin', {
  validate (value) {
    const regex = /^[a-zA-Z0-9_-]{5,30}$$/
    return regex.test(value)
  },
  message: 'Invalid LinkedIn name'
})

extend('reddit', {
  validate (value) {
    const regex = /^[a-zA-Z0-9_]{3,20}$/
    return regex.test(value)
  },
  message: 'Invalid Reddit username'
})

extend('threads', {
  validate (value) {
    const regex = /^[a-zA-Z][a-zA-Z0-9_.]{0,29}$/
    return regex.test(value)
  },
  message: 'Invalid Threads username'
})

extend('linktree', {
  validate (value) {
    const regex = /^[a-zA-Z0-9_.]{1,30}$/
    return regex.test(value)
  },
  message: 'Invalid Linktree username'
})
