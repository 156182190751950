export default class FilterCategory {
  constructor (config) {
    this.label = config.label
    this.filters = config.filters
    this.features = config.features
  }

  isActive () {
    let active = 0
    for (const key in this.filters) {
      const filter = this.filters[key]
      if (!filter.isEmpty()) {
        active++
      }
    }

    return active
  }
}
