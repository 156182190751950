import Model, { Request } from '../model'
import Enum from '../enums'

import Employee from './employee'

export default class StockTake extends Model {
  static modelName () {
    return 'stockTake'
  }

  objectID () {
    return this.idStockTake
  }

  relations () {
    return {
      status: { type: StockTakeStatus },
      employee: { type: Employee }
    }
  }

  apply () {
    const url = this.constructor.modelBaseURL() + '/apply?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  get isStatusOpen () {
    return this.status === StockTakeStatus.open
  }

  get isStatusApplied () {
    return this.status === StockTakeStatus.applied
  }
}

export const StockTakeStatus = new Enum({
  open: { value: 0, description: 'Open' },
  applied: { value: 1, description: 'Applied' }
})
