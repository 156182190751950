import Model from '../model'

export default class ProductCategory extends Model {
  static modelName () {
    return 'productCategory'
  }

  objectID () {
    return this.idProductCategory
  }

  get description () {
    return this.name
  }
}
