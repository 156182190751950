<template>
  <div class="bg-gray-50 dark:bg-gray-900 border dark:border-gray-800 rounded-md p-3 mb-6">
    <div class="flex items-center justify-between class border-b dark:border-gray-800 pb-2 mb-6">
      <div class="mr-10">
        <h5 class="font-medium">
          {{ title }}
        </h5>
        <p v-if="info" class="text-sm opacity-80">
          {{ info }}
        </p>
      </div>

      <div>
        <slot name="control" />
      </div>
    </div>

    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OGroup',
  props: {
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      default: null
    }
  }
}
</script>