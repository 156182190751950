export default {
  employee: {
    allowInternet: 'Allow internet',
    bio: 'Bio',
    deleted: 'Deleted',
    displayName: 'Display name',
    email: 'Email',
    enabled: 'Enabled',
    firstName: 'First name',
    hasImage: 'Has image',
    hasPIN: 'Has PIN',
    id: 'ID',
    idEmployee: 'Employee ID',
    images: 'Images',
    lastName: 'Last Name',
    listOrder: 'List order',
    minimiseGapsSettings: 'Minimise gap settings',
    overrides: 'Overrides',
    permissions: 'Permissions',
    phone: 'Phone',
    resourceRostered: 'Resource rostered',
    resourceTypes: 'Resources types',
    role: 'Role',
    type: 'Type'
  },
  service: {
    idService: 'Service ID',
    idServiceCategory: 'Service Category ID',
    title: 'Title',
    information: 'Information',
    duration: 'Duration',
    processing: 'Processing',
    finishing: 'Finishing',
    price: 'Price',
    customerType: 'Customer type',
    customerMax: 'Customer max',
    hexColour: 'Colour',
    borderHexColour: 'Border colour',
    images: 'Images',
    hasImage: 'Has image',
    priceLabel: 'Price Label',
    allowInternet: 'Allow internet',
    deleted: 'Deleted'
  },
  form: {
    idForm: 'Form ID',
    title: 'Title',
    prefillWithPreviousFormDefault: 'Prefill',
    permission: 'Permission',
    consentEnabled: 'Consent enabled',
    consentTitle: 'Consent title',
    consentContent: 'Consent content',
    consentAcceptLabel: 'Consent accept label',
    automationEnabled: 'Automation enabled',
    deleted: 'Deleted',
    listOrder: 'List order',
    items: 'Items',
    overrides: 'Overrides'
  },
  giftCard: {
    idGiftCard: 'Gift Card ID',
    id: 'ID',
    customer: 'Customer',
    order: 'Sale',
    transactions: 'Transactions',
    balance: 'Balance',
    number: 'Number',
    customNumber: 'Custom number',
    displayNumber: 'Display Number',
    balanceLabel: 'Balance label',
    expires: 'Expires',
    expiryDate: 'Expiry label',
    expiryDateLabel: 'Expiry date label',
    isValid: 'Valid',
    isExpired: 'Expired',
    isExpended: 'Expended',
    isPaid: 'Paid',
    images: 'Images',
    gift: 'Gift',
    claimable: 'Claimable'
  },
  pass: {
    idPass: 'Pass ID',
    name: 'Name',
    information: 'Information',
    expiryDays: 'Expiry dats',
    numberOfUses: 'Number of uses',
    price: 'Price',
    priceLabel: 'Price label',
    infoLabel: 'Info label',
    preselectAtConfirmationDefault: 'Preselect at confirmation default',
    cancelReservationBehaviour: 'Cancel appointment behaviour',
    deleteReservationBehaviour: 'Delete appoiontment behaviour',
    allowInternet: 'Allow internet',
    enabled: 'Enabled'
  },
  product: {
    idProduct: 'Product ID',
    id: 'ID',
    idProductCategory: 'Product Category ID',
    category: 'Category',
    name: 'Name',
    sku: 'SKU',
    barcode: 'Barcode',
    information: 'Information',
    price: 'Price',
    priceLabel: 'Price Label',
    wholesalePrice: 'Wholesale price',
    wholesalePriceLabel: 'Wholesale price label',
    listOrder: 'List order',
    imageUrls: 'Image URLs',
    hasImage: 'Has image',
    brand: 'Brand',
    supplier: 'Supplier',
    inventory: 'Inventory',
    inventoryMinimum: 'Inventory minimum',
    inventoryReorder: 'Inventory reorder'
  },
  reservation: {
    idReservation: 'Appointment ID',
    date: 'Date',
    dateLabel: 'Date label',
    startTimeUTC: 'Start time UTC',
    finishTimeUTC: 'Finish time UTC',
    status: 'Status',
    checkedIn: 'Checked in',
    noShow: 'No show',
    statusType: 'Status type',
    idCustomer: 'Customer ID',
    customer: 'Customer',
    wasBookedOnline: 'Was booked online',
    customerConfirmed: 'Customer confirmed',
    deleted: 'Deleted',
    price: 'Price',
    priceLabel: 'Price label',
    isPaid: 'Paid',
    customerRequestType: 'Customer request type',
    overrideTime: 'Override time',
    customerType: 'Customer type',
    serviceType: 'Service type',
    scheduleType: 'Schedule type',
    recurrenceGuid: 'Recurrence GUID',
    notes: 'Notes',
    infoLabel: 'Info label',
    cancellationType: 'Cancellation type',
    cancellationNote: 'Cancellation note',
    services: 'Services'
  },
  order: {
    id: 'ID',
    idOrder: 'Order ID',
    date: 'Date',
    price: 'Price',
    paid: 'Paid',
    tax: 'Tax',
    tip: 'Tip',
    tipType: 'Tip type',
    loyaltyPoints:'Loyalty points',
    notes: 'Notes',
    orderNumber: 'Order Number',
    closed: 'Closed',
    customer: 'Customer',
    employee: 'Employee',
    dateLabel: 'Date label',
    priceLabel: 'Price label',
    isPaid: 'Paid',
    statusLabel: 'Status label',
    remainingBalanceLabel: 'Remaining balance label'
  },
  customer: {
    id: 'ID',
    idCustomer: 'Customer ID',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    displayName: 'Display name',
    company: 'Company',
    occupation: 'Occupation',
    phone: 'Phone',
    homePhone: 'Home phone',
    workPhone: 'Work phone',
    gender: 'Gender',
    massEmailUnsubscribed: 'Mass email unsubscribed',
    massSMSUnsubscribed: 'Mass SMS unsubscribed',
    consentAccepted: 'Consent accepted',
    marketingConsentAccepted: 'Marketing consent accepted',
    emailBounced: 'Email bounced',
    dateOfBirth: 'Date of birth',
    notes: 'Notes',
    alerts: 'Alerts',
    segments: 'Segments',
    stats: 'Stats',
    profilePhoto: 'Profile photo',
    loyaltyPointBalance: 'Loyalty points balance',
    allowOnlineBooking: 'Allow online booking'
  }
}
