import Vue from 'vue'
import ODialog from './Dialog'

export default {
  create (message, { title, confirm, cancel, icon, variant, container } = {}) {
    const DialogComponent = Vue.extend(ODialog)
    const Dialog = new DialogComponent({
      propsData: {
        message,
        title,
        confirm,
        cancel,
        icon,
        variant,
        container
      }
    }).$mount()

    Dialog.$on('close', () => {
      this.destroy(Dialog)
    })

    document.body.appendChild(Dialog.$el)
    return Dialog.open()
  },
  destroy (dialog) {
    dialog.$destroy()
    dialog.$el.remove()
  },
  alert (message, options = { }) {
    const { title, variant, icon, confirm, container } = options

    return this.create(message, { title, confirm, variant, icon, container })
  },
  confirm (message, options = { }) {
    const { title, variant, icon, confirm, container } = options
    const cancel = Object.assign({ cancel: true }, options.cancel)

    return this.create(message, { title, cancel, confirm, variant, icon, container })
  }
}
