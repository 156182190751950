<template>
  <div>
    <transition name="fade">
      <div v-if="active" class="fixed w-screen h-screen inset-0 z-80000 bg-black bg-opacity-25 backdrop-blur" @click="cancel">
        <div v-if="active" class="w-11/12 md:w-3/4 mx-auto max-w-lg mt-24 bg-white shadow-xl rounded-lg overflow-hidden dark:bg-gray-900  dark:border dark:border-gray-800" @click.prevent.stop>
          <div class="w-full relative flex items-center px-5 space-x-6 py-4">
            <div class="flex items-center pointer-events-none">
              <o-icon icon="search" :size="16" class="text-gray-800 dark:text-gray-100" />
            </div>
            <input
              ref="search"
              :value="query"
              type="text"
              class="flex-grow text-gray-800 text-lg dark:text-gray-100 dark:bg-gray-900"
              @input="evt=>query=evt.target.value"
              @keyup.esc="cancel"
              @keyup.up="moveIndex(-1)"
              @keyup.down="moveIndex(1)"
              @keyup.enter="select"
            >
            <div class="flex items-center space-x-2">
              <o-loader v-if="state.filtering" size="16" />
              <!--<o-chip v-else class="mb-0 opacity-75" flat outline>
                Esc
              </o-chip>-->
              <o-button size="slim" flat icon="x" @click="cancel" />
            </div>
          </div>
          <div :class="{'hidden': !query.length}" class="w-full bg-white border-t border-gray-200 overflow-x-hidden dark:bg-gray-900 dark:border-gray-800" style="max-height: calc(100vh - 400px)">
            <o-scroll>
              <div v-for="(model, name, index) in models" :key="index">
                <template v-if="model.results && model.results.length">
                  <div class="py-3 px-4 flex items-center justify-between">
                    <p class="text-base font-medium text-gray-700 dark:text-gray-200">
                      {{ model.name }}
                    </p>

                    <o-chip v-if="model.loading || model.results.length" flat class="mb-0">
                      <o-loader v-if="model.loading" size="16px" />
                      <template v-if="model.results.length">
                        {{ model.results.length }}
                      </template>
                    </o-chip>
                  </div>

                  <div v-if="model.results.length" class="divide-y border-t border-b border-gray-100 dark:border-gray-800">
                    <div
                      v-for="(result, key) in model.results"
                      :key="key"
                      class="px-4 py-3 cursor-pointer transition duration-100 east-in-out border-gray-100 dark:border-gray-800"
                      :class="state.selected.model === index && state.selected.result === key ? 'bg-gray-50 text-gray-800 dark:bg-gray-900 dark:text-gray-100' : 'text-gray-800 dark:text-gray-100'"
                      @mouseenter="setIndex(index, key)"
                    >
                      <component
                        :is="model.component"
                        :result="result"
                        @click.native="active = false"
                        @close="active = false"
                      />
                    </div>
                  </div>

                  <div v-if="!model.results.length && query" class="text-gray-700 py-3 px-4 no-results dark:text-gray-200">
                    <div class="flex items-center">
                      <o-icon icon="warning" :size="16" class="mr-4 text-red-600" />
                      <span>No results found.</span>
                    </div>
                  </div>
                </template>
              </div>
            </o-scroll>
          </div>
          <div class="hidden md:flex bg-gray-50 px-4 py-2 items-center space-x-3 text-gray-600 text-sm border-t dark:bg-gray-900 dark:text-gray-300">
            <div class="flex items-center">
              <o-chip flat outline size="xs" class="mb-0 opacity-75 mr-2">
                <o-icon icon="searchEnter" :size="10" />
              </o-chip>
              <p>to select</p>
            </div>
            <div class="flex items-center">
              <o-chip flat outline size="xs" class="mb-0 opacity-75 mr-px">
                <o-icon icon="searchArrowUp" :size="10" />
              </o-chip>
              <o-chip flat outline size="xs" class="mb-0 opacity-75 mr-2">
                <o-icon icon="searchArrowDown" :size="10" />
              </o-chip>
              <p>to navigate</p>
            </div>
            <div class="flex items-center">
              <o-chip flat outline size="xs" class="mb-0 opacity-75 mr-2">
                Esc
              </o-chip>
              <p>to close</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

import Account from './provider/models/account'
import Customer from './provider/models/customer'
import Manage from './provider/models/manage'
import Minisite from './provider/models/minisite'

export default {
  data () {
    return {
      models: {
        Account,
        Customer,
        Manage,
        Minisite
      },
      query: '',
      state: {
        // Current highlighted search result
        selected: {
          model: 0,
          result: 0
        },
        filtering: false
      }
    }
  },
  computed: {
    // Search state is stored in vuex. Can be triggered from anywhere in app
    active: {
      get () {
        return this.$store.getters.search
      },
      set (value) {
        this.$store.commit('setSearch', value)
      }
    },

    // COmpile all individual model results into single array
    results () {
      const models = Object.values(this.models)
      const arrays = models.map(model => model.results)
      const results = [].concat(...arrays)

      return results
    }
  },
  watch: {
    // Watch active and init search box on open
    active (active) {
      if (active) {
        this.init()

        this.$nextTick(() => {
          this.query = ''
          this.$refs.search?.focus()
        })
      }
    },
    // Watch search query and trigger search
    query (val) {
      this.$emit('input', val)
      this.state.filtering = this.query !== ''
      this.search()
    },
    models: {
      // Watch for changes to models. If any model results has changed, reset the selected index
      handler () {
        const models = Object.values(this.models)

        for (const index in models) {
          const model = models[index]

          if (model.results) {
            this.state.selected.model = Number(index)
            this.state.selected.result = 0

            return
          }
        }
      },
      deep: true
    }
  },
  methods: {
    // Initialise the search models
    // Used to sync customers etc
    init () {
      const models = Object.values(this.models)

      for (const model of models) {
        if (model.init) {
          model.init(this)
        }
      }
    },

    // Iterate through models and trigger each individual search process
    search: debounce(function () {
      const query = this.query

      if (query) {
        for (const [key, model] of Object.entries(this.models)) {
          model.search(query)
        }
      }

      this.state.filtering = false
    }, 500),

    // Close search box
    cancel () {
      this.active = false
      this.query = ''
    },

    // Change selected result using arrow keys by passing in the direction/increment
    moveIndex (increment = 1) {
      const models = Object.values(this.models)
      const results = this.results

      if (results) {
        const selected = this.state.selected
        const modelIndex = selected.model
        const resultIndex = selected.result

        const nextResult = models[modelIndex]?.results?.[resultIndex + increment]

        if (nextResult) {
          this.state.selected.result = resultIndex + increment
          return
        }

        for (let nextModelIndex = modelIndex + increment; nextModelIndex < models.length; nextModelIndex + increment) {
          const nextModel = models[nextModelIndex]

          if (nextModel && nextModel.results) {
            this.state.selected.model = nextModelIndex
            this.state.selected.result = Math.sign(increment) === 1 ? 0 : nextModel.results.length - 1
            return
          }
        }
      }
    },

    // Manually set the selected result index
    setIndex (modelIndex, resultIndex) {
      this.state.selected.model = Number(modelIndex)
      this.state.selected.result = Number(resultIndex)
    },

    // Select the current result - trigger router
    select () {
      const selected = this.state.selected
      const modelIndex = selected.model
      const resultIndex = selected.result

      const model = Object.values(this.models)[modelIndex]
      const item = model?.results?.[resultIndex]

      if (item) {
        this.$router.push(item.path)

        this.query = ''
        this.active = false
      }
    }
  }
}
</script>
