export class TablePager {
  constructor (runner, initialItems = 3) {
    this.visible = true
    this.loading = false
    this.initial = true
    this.initialItems = initialItems
    this.list = null
    this.items = []
    this.total = null
    this.page = 1
    this.pages = null
    this.hasMore = false
    this.stored = {}
    this.runner = runner
  }

  async load (page = 1) {
    if (this.runner) {
      this.loading = true

      const list = await this.fetch(page)

      if (list) {
        this.list = list
        this.setItems(list)

        if (list.paging) {
          this.total = list.paging.itemCount
          this.page = list.paging.page
          this.pages = list.paging.pageCount

          this.hasMore = (list.paging.pageCount > list.paging.page) || (this.initial && this.visibleItems < this.items)
        }
        this.loading = false
      }
    }
  }

  async fetch (page = 1) {
    const list = this.stored[page] || await this.runner(page)

    if (list) {
      this.stored[page] = list
    }

    return list
  }

  setItems (list) {
    this.items = list.items
  }

  get visibleItems () {
    if (this.initial) {
      return this.items.slice(0, this.initialItems)
    } else {
      return this.items
    }
  }

  reload () {
    this.items = []
    this.stored = {}
    this.load(this.page)
  }
}

export class EndlessListPager extends TablePager {
  constructor (runner, initialItems = 3) {
    super(runner, initialItems)
  }

  async fetch (page = 1) {
    return await this.runner(page)
  }

  setItems (list) {
    this.items.push(...list.items)
  }

  loadMore () {
    if (this.initial) {
      this.initial = false
      this.hasMore = (this.pages > this.page)
    } else {
      this.load(this.page + 1)
    }
  }
}
