<template>
  <component
    :is="component"
    :to="path"
    :href="path"
    :path="path"
    v-bind="$attrs"
    @click.native="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    }
  },
  computed: {
    match () {
      return this.$redirect?.match(this.link)
    },
    path () {
      return this.match?.path
    },
    component () {
      return this.match?.component
    }
  }
}
</script>
