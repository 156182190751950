import Vue from 'vue'

import Modal from '../components/elements/modal/Modal.vue'
import ModalPlugin from '../components/elements/modal/index.js'

export default (context, inject) => {
  inject('modal', ModalPlugin)
}

Vue.component(Modal.name, Modal)
