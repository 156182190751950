<template>
  <div>
    <div class="flex -mx-1">
      <template v-for="index in 5">
        <div :key="index" class="w-1/5 px-1">
          <div
            class="h-1 rounded-lg transition-colors"
            :class="barClass(index)"
          />
        </div>
      </template>
    </div>
    <div v-if="suggestions || warning || pwned" class="text-sm mt-3 text-gray-600">
      <span v-if="pwned">
        This password has been been identified in a data breach. Please try another unique password <a
          href="https://haveibeenpwned.com/Passwords"
          target="_blank"
          class="text-blue-600 underline"
        >
          (more info)
        </a>
      </span>

      <template v-else>
        <template v-if="suggestions && !warning">
          <span>
            {{ suggestions.join('. ') }}
          </span>
        </template>

        <template v-if="warning">
          <span>
            {{ warning }}
          </span>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'
import debounce from 'lodash/debounce'
import axios from 'axios'
import sha1 from 'sha1'

export default {
  name: 'OPasswordStrength',
  props: {
    password: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      pwned: false
    }
  },
  computed: {
    suggestions () {
      return this.password ? zxcvbn(this.password).feedback?.suggestions : null
    },
    warning () {
      return this.password ? zxcvbn(this.password).feedback?.warning : null
    },
    strength () {
      if (this.pwned) {
        return 1
      }

      return this.password ? zxcvbn(this.password).score + 1 : null
    },
    secure () {
      return this.password ? this.password.length >= 7 : null
    },
    active () {
      return this.password && this.password.length > 0
    },
    count () {
      return this.password && (this.password.length > 7 ? '7+' : this.password.length)
    }
  },
  watch: {
    strength () {
      this.$emit('update:strength', this.strength)
    },
    password () {
      this.checkPwned()
    }
  },
  methods: {
    barClass (index) {
      if (index <= this.strength) {
        if (this.strength <= 2) {
          return 'bg-red-400'
        } else if (this.strength <= 4) {
          return 'bg-yellow-400'
        } else {
          return 'bg-green-500'
        }
      } else {
        return 'bg-gray-300'
      }
    },
    checkPwned: debounce(async function() {
      const password = this.password
      const hash = sha1(password).toUpperCase();

      const hashPrefix = hash.substring(0, 5);

      try {
        const url = `https://api.pwnedpasswords.com/range/${hashPrefix}`;
        const response = await axios.get(url, {headers: {'Accept': 'application/json'}});

        if (response.status === 200) {
          const hashSuffixes = response.data.split("\n");

          for (const suffix of hashSuffixes) {
            const [suffixHash, count] = suffix.split(':');

            if (hashPrefix + suffixHash === hash) {

              this.pwned = true;
              return;
            }
          }
        }
      } catch (error) {
        console.error('Error making the API call:', error);
      }

      this.pwned = false;
    }, 1000)
  }
}
</script>
