import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  services: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false
  },
  hydrated: false,
  count: null
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  updateServices (state, services) {
    state.services = Object.freeze(API.Service.ensure(services))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.services
  },
  count (state) {
    return state.services.length
  },
  categorised (state) {
    const categories = {}

    for (const service of state.services) {
      const category = service.category
      const idServiceCategory = category?.idServiceCategory || 0

      if (!categories[idServiceCategory]) {
        categories[idServiceCategory] = {
          ...category,
          services: []
        }
      }

      categories[idServiceCategory].services.push(service)
    }

    return Object.values(categories)
  },
  categories (state) {
    const categories = {}

    for (const service of state.services) {
      const category = service.category
      const idServiceCategory = category?.idServiceCategory || 0

      if (!categories[idServiceCategory]) {
        categories[idServiceCategory] = category
      }
    }

    return Object.values(categories)
  },
  getServiceByID: state => (idService) => {
    return state.services
      .find(service => Number(idService) === service?.idService)
  }
}

export const actions = {
  async sync ({ commit, state, rootState }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active

    // Check permission
    if (this.$grants && !this.$grants.all({ service: ['view'] })) {
      console.log('no service permissions')
      return
    }

    if (!state.hydrated || change) {
      const services = await localforage.getItem(guid + '.services.services')
      const sync = await localforage.getItem(guid + '.services.sync')

      if (services) {
        commit('updateServices', services)
      } else {
        commit('updateServices', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true })

    const sinceDate = (force) ? null : state.sync.date

    let services
    const errors = []

    try {
      services = await API.Booking.services(sinceDate)
    } catch (e) {
      if (e?.status === 304) {
        console.log('Service data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      if (services?.items) {
        commit('updateServices', services.items)
      }

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.services.services', state.services)
      localforage.setItem(guid + '.services.sync', state.sync)
    }
  }
}
