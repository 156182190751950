import { plugin as $date } from '../plugins/date'

export class DateYMD {
  static fromInteger (value) {
    if (value !== null && typeof value === 'object') {
      value = value.value
    }
    return new this(value)
  }

  static fromDate (date) {
    const zeroPad = (num) => {
      if (num < 10) {
        return '0' + num.toString()
      }
      return num.toString()
    }

    const integer = parseInt(date.getUTCFullYear().toString() + zeroPad(date.getUTCMonth() + 1) + zeroPad(date.getUTCDate()))
    return new this(integer)
  }

  constructor (value) {
    this.value = value
  }

  get date () {
    // const str = this.value.toString()
    // const year = str.substring(0, 4)
    // const month = str.substring(4, 6)
    // const day = str.substring(6, 8)

    return $date.parse(this.value) // new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0))
  }

  get integer () {
    return this.value
  }

  valueOf () {
    return this.value
  }

  [Symbol.toPrimitive] (hint) {
    return this.value
  }
}

export class TimeMinute {
  static fromInteger (value) {
    return new this(value)
  }

  static fromDate (date) {
    const integer = (date.getHours() * 60) + date.getMinutes()
    return new this(integer)
  }

  constructor (value) {
    this.value = value
  }

  get date () {
    const hours = parseInt(this.value / 60)
    const minutes = this.value - (hours * 60)

    return new Date(2000, 0, 1, hours, minutes)
  }

  get integer () {
    return this.value
  }

  get ymd () {
    return this.value
  }

  valueOf () {
    return this.value
  }

  [Symbol.toPrimitive] (hint) {
    return this.value
  }
}
