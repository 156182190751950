import Model from '../model'

export default class ProductBrand extends Model {
  static modelName () {
    return 'productBrand'
  }

  objectID () {
    return this.idProductBrand
  }

  relations () {
    return {
    }
  }
}
