<template>
  <div>
    <transition name="fade">
      <div v-if="!locked && pinRequired && pinTimeout">
        <div :class="prompt ? 'block' : 'hidden'" class="fixed top-0 left-1/2 transform -translate-x-1/2 mt-3 px-3 py-2 rounded-lg text-sm bg-white bg-opacity-25 shadow text-gray-800 leading-none flex items-center border">
          <o-icon icon="locked" class="mr-2" />
          <p class="leading-none mt-1">Session will lock in <span class="font-semibold mx-1"><v-idle
            :reminders="[15, pinTimeout]"
            :duration="pinTimeout"
            class="inline"
            @remind="onRemind"
            @idle="onIdle"
          />s</span> due to inactivity.</p>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="locked"
        id="lock"
        class="fixed inset-0 h-screen w-screen z-80000 bg-gray-900 bg-opacity-15 backdrop-blur-md"
        tabindex="0"
        @contextmenu="handleContextMenu"
        @keydown="handleKeyDown"
      >
        <div class="h-screen flex-col items-center justify-center">
          <div class="h-full flex items-center justify-center flex-grow z-10 sm:px-6 lg:px-8">
            <div class="max-w-md w-full h-full sm:h-auto bg-white dark:bg-gray-900 dark:border dark:border-gray-800 py-12 sm:py-16 px-12 sm:px-24 sm:rounded-2xl sm:mb-32 bg-opacity-75 backdrop-blur shadow-lg">
              <div>
                <div v-if="active" class="mb-12 mt-2">
                  <div v-if="active.info.employee.images" class="w-36 h-36 mx-auto rounded-full p-1 mb-6">
                    <img
                      v-if="active.info.employee.images"
                      :src="active.info.employee.images.large"
                      class="h-32 w-32 object-cover rounded-full bg-gray-100 border border-white"
                    >
                    <div v-else class="pointer-events-none w-full h-full rounded-full bg-gray-200 border border-white">
                      <p>{{ active.info.employee.displayName }}</p>
                    </div>
                  </div>
                  <h2 class="text-center text-3xl sm:text-3xl leading-9 font-extrabold opacity-90">
                    {{ active.info.employee.displayName }}
                  </h2>
                  <p v-if="userHasPin" class="mt-3 opacity-70 text-center leading-5 text-sm">
                    Enter your employee user pin to unlock or <o-button
                      tag="nuxt-link"
                      to="/auth/switch"
                      size="xs"
                      variant="info"
                      flat
                      slim
                    >
                      switch user
                    </o-button>
                  </p>

                  <p v-if="!userHasPin" class="mt-3 text-gray-600 text-center leading-5 text-sm">
                    Unlock to access user account or <o-button
                      tag="nuxt-link"
                      to="/auth/switch"
                      size="xs"
                      variant="info"
                      flat
                      outline
                      slim
                    >
                      switch user
                    </o-button>
                  </p>
                </div>
                <div v-if="active">
                  <div  v-if="userHasPin" class="mx-12 relative">
                    <input
                      ref="field"
                      v-model="pin"
                      aria-label="Pin"
                      name="password"
                      type="password"
                      required
                      class="field appearance-none font-light relative block w-full px-3 py-2 "
                      :class="[
                        'tracking-widest rounded-xl shadow placeholder-gray-500 focus:outline-none focus:z-10 sm:leading-5 bg-white dark:bg-gray-950/50 bg-opacity-75 text-3xl',
                        error ? 'bg-red-50 border-red-400 dark:border-red-950 text-red-700 focus:border-red-300 focus:shadow-outline-red ring-4 ring-offset-0 ring-red-200 dark:ring-red-950' : 'border-gray-300 text-gray-800 dark:text-white/90 focus:border-primary-300 focus:shadow-outline-blue',
                        { 'animate-shake' : animate }
                      ]"
                      @keydown.enter="handleUnlock"
                    >

                    <o-button icon="arrowRight" flat class="absolute top-1/2 right-0 transform -translate-y-1/2 mx-3 z-10" @click="handleUnlock" />
                  </div>

                  <div v-if="!userHasPin" class="mx-12 relative">
                    <o-button icon="locked" size="lg" variant="info" class="w-full" @click="handleUnlock">Unlock</o-button>
                  </div>

                  <div v-if="attempts" class="text-sm text-red-700 text-center mt-2">
                    {{ 5 - attempts }} attempts remaining.
                  </div>

                  <div v-if="userHasPin" class="mt-14 flex items-center justify-center space-x-2">
                    <o-button size="xs" variant="success" outline @click="handleChangePin">
                      Change/disable PIN
                    </o-button>

                    <o-button v-if="admin" size="xs" variant="success" outline @click="handleAdminUnlock">
                      Admin unlock
                    </o-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <change-pin-dialog
      ref="changePinDialog"
      @success="handleChangePinSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChangePinDialog from '@/components/auth/actions/ChangePin'

export default {
  components: {
    ChangePinDialog
  },
  data () {
    return {
      pin: '',
      error: false,
      animate: false,
      prompt: false,
      observer: null
    }
  },
  computed: {
    ...mapGetters({
      active: 'auth/active',
      locked: 'auth/locked',
      attempts: 'auth/attempts'
    }),
    pinTimeout: {
      get () {
        return this.active?.info?.location?.webPINTimeout
      }
    },
    pinRequired: {
      get () {
        return this.active?.info?.location?.webPINRequired
      }
    },
    userHasPin () {
      return !!this.active?.info?.employee?.pinHash
    },
    admin () {
      return this.active?.info?.adminUser
    }
  },
  watch: {
    locked: {
      handler (value) {
        this.pin = ''
        this.error = false
        this.animate = false

        if (value) {
          this.$nextTick(() => {
            this.$refs.field?.focus()
            this.createObserver()
          })
        } else {
          this.destroyObserver()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleChangePin () {
      this.$refs.changePinDialog.open()
    },
    handleChangePinSuccess () {
      this.$auth.unlock({ override: true, sync: true })
    },
    handleAdminUnlock () {
      this.$auth.unlock({ override: true })
    },
    async handleUnlock () {
      if (this.userHasPin) {
        if (this.pin) {
          try {
            await this.$auth.unlock({pin: this.pin, sync: false})
          } catch (error) {
            const self = this

            self.animate = true
            self.error = true

            this.$auth.setAttempts(this.attempts + 1)

            if (this.attempts === 5) {
              this.$auth.logout()
            }
          }

          setTimeout(() => {
            self.animate = false
          }, 1000)
        }
      } else {
        this.$auth.unlock({ override: true })
      }
    },
    handleKeyPress (key) {
      this.pin += key
    },
    handleBackspace () {
      this.pin = this.pin.slice(0, -1)
    },
    handleContextMenu (e) {
      e.preventDefault()
    },
    handleKeyDown (e) {
      const F12 = 123
      const F5 = 116
      const F7 = 118
      const U = 85

      const keys = [73, 67, 74, 75, 83, 69, 77]

      const { keyCode, shiftKey, ctrlKey } = e

      if (keyCode === F12 ||
        (shiftKey && ctrlKey && keys.includes(keyCode)) ||
        (shiftKey && [F5, F7].includes(keyCode)) ||
        (ctrlKey && keyCode === U)) {
        e.preventDefault()
      }
    },
    onRemind (t) {
      if (t === 15) {
        this.prompt = true
      } else {
        this.prompt = false
      }
    },
    onIdle (t) {
      this.$auth.lock()
    },
    createObserver () {
      const config = {
        attributes: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterData: true
      }

      const self = this

      const callback = (records) => {
        self.$nextTick(() => {
          let reload = false

          records.forEach((record) => {
            if (record.type === 'attributes' && (record.target.matches && record.target.matches('body, #content, #lock')) && record.attributeName === 'style') {
              reload = true
            }
            if (record.type === 'characterData') {
              if (record.target.parentNode.tagName.toLowerCase() === 'style') {
                reload = true
              }
            }
            record.removedNodes.forEach((node) => {
              if (node.id === 'lock') {
                reload = true
              }
            })

            if (reload) {
              window.location.reload(true)
            }
          })
        })
      }

      const observer = new MutationObserver(callback)
      observer.observe(document.documentElement, config)

      this.observer = observer
    },
    destroyObserver () {
      if (this.observer) {
        this.observer.disconnect()
      }
    }
  }
}
</script>
