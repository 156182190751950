export default function ({ app, redirect, route }) {
  // Don't redirect on auth route
  if (route.path.includes('/auth') || route.path.includes('/maintenance')) {
    return
  }

  if (!route.path.includes('/error') && !route.path.includes('/unauthorised')) {
    app.$auth.setHistory(route.path)
  }

  const active = app.store.state.auth.active

  // Redirect if no active user
  if (!active) {
    // If stored accoutns exist redirect to switch
    if (app.$auth.accountIDs.length) {
      return redirect(`/auth/switch?redirect=${route.path}`)
    }

    if (app.$config.instance === 'demo') {
      if (route.path.includes('/demo')) {
        return
      }
      // Otherwise redirect to login
      return redirect('/demo')
    }

    // Otherwise redirect to login
    return redirect(`/auth/login/email?redirect=${route.path}`)
  }

  const info = app.$auth.info

  if (!route.path.includes('/onboarding') && info?.onboarding) {
    return redirect('/onboarding')
  }

  // Check if user has required grants
  if (route?.meta?.[0]?.grants) {
    const grants = route.meta[0].grants

    const allowed = true // app.$grants.all(grants)

    // Redirect to unauthorised page if not allowed
    if (!allowed) {
      return redirect('/unauthorised')
    }
  }
}
