import Vue from 'vue'

import Collapse from '../components/elements/Collapse.vue'
import Button from '../components/elements/Button.vue'
import ButtonGroup from '../components/elements/ButtonGroup.vue'
import Card from '../components/elements/Card.vue'
import Chip from '../components/elements/Chip.vue'
import Dropdown from '../components/elements/dropdown/Dropdown.vue'
import Error from '../components/elements/Error.vue'
import HistoryLink from '../components/elements/HistoryLink.vue'
import Tooltip from '../components/elements/Tooltip.vue'
import FieldGroup from '../components/elements/FieldGroup.vue'
import Form from '../components/elements/Form.vue'
import Loader from '../components/elements/Loader.vue'
import Heading from '../components/elements/Heading.vue'
import Icon from '../components/elements/Icon.vue'
import Scroll from '../components/elements/Scroll.vue'

import Notification from '../components/elements/Notification.vue'

Notification.install = (Vue, options) => {
  Vue.prototype.$notification = new (Vue.extend(Notification))({ propsData: options })
  Vue.notification = Vue.prototype.$notification
}

Vue.use(Notification)

Vue.component(Collapse.name, Collapse)
Vue.component(Button.name, Button)
Vue.component(ButtonGroup.name, ButtonGroup)
Vue.component(Card.name, Card)
Vue.component(Chip.name, Chip)
Vue.component(Dropdown.name, Dropdown)
Vue.component(Error.name, Error)
Vue.component(HistoryLink.name, HistoryLink)
Vue.component(Tooltip.name, Tooltip)
Vue.component(FieldGroup.name, FieldGroup)
Vue.component(Form.name, Form)
Vue.component(Loader.name, Loader)
Vue.component(Heading.name, Heading)
Vue.component(Icon.name, Icon)
Vue.component(Scroll.name, Scroll)
