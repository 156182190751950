<template>
  <o-button
    :variant="_model ? 'success' : null"
    :outline="!_model"
    :icon="_model ? 'check' : null"
    :disabled="disabled"
    :name="name"
    @click="_model = !_model"
  >
    {{ label || name || _model }}
  </o-button>
</template>

<script>
export default {
  model: {
    prop: 'model',
    event: 'input'
  },
  props: {
    model: {
      type: [String, Object, Number, Boolean, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      id: this._uid
    }
  },
  computed: {
    _model: {
      get () {
        return this.model
      },
      set (model) {
        this.$emit('input', model)
      }
    }
  }
}
</script>
