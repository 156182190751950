import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  products: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false
  },
  hydrated: false,
  count: null
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  updateProducts (state, products) {
    state.products = Object.freeze(API.ProductCategorised.ensure(products))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.products
  },
  count (state) {
    return state.products?.length
  }
}

export const actions = {
  async sync ({ commit, state, rootState }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active

    if (this.$grants && !this.$grants.all({ product: ['view'] })) {
      return
    }

    if (!state.hydrated || change) {
      const products = await localforage.getItem(guid + '.products.products')
      const sync = await localforage.getItem(guid + '.products.sync')

      if (products) {
        commit('updateProducts', products)
      } else {
        commit('updateProducts', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true })

    const sinceDate = (force) ? null : state.sync.date

    let products
    const errors = []

    try {
      products = await API.Product.categorised(sinceDate)
    } catch (e) {
      if (e?.status === 304) {
        console.log('Product data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      if (products?.items) {
        commit('updateProducts', products.items)
      }

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.products.products', state.products)
      localforage.setItem(guid + '.products.sync', state.sync)
    }
  }
}
