import Model from '../model'

export default class Supplier extends Model {
  static modelName () {
    return 'supplier'
  }

  objectID () {
    return this.idSupplier
  }

  relations () {
    return {
    }
  }
}
