import Model from '../model'
import { DateYMD } from '../dates'

export default class EmployeeBreak extends Model {
  static modelName () {
    return 'roster'
  }

  relations () {
    return {
      date: { type: DateYMD }
    }
  }

  get startTimeLabel () {
    return this.time
  }

  get finishTimeLabel () {
    return this.time + this.duration
  }
}
