export default class FilterOptions {
  constructor (config) {
    this._options = []
    this.map = config.map
    this.set = config.set

    this.update()
  }

  async update () {
    this.options = await this.map()
  }

  get options () {
    return this._options
  }

  set options (value) {
    this._options = value
    this.set(value)
  }
}
