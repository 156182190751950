import cloneDeep from 'lodash/cloneDeep'
import Boxer from './boxer'

export default class APIObject {
  constructor (object) {
    if (object) {
      this.fromJSON(object)
    }
  }

  objectID () {
    return null
  }

  get isNewRecord () {
    return (!this.objectID())
  }

  get _display () {
    return this.displayName || this.name || this.value || this.title
  }

  fromJSON (object) {
    const relations = this.relations()
   
    for (const key in object) {
      const val = object[key]
      const relation = (relations && relations[key]) ? relations[key] : null

      try {
        this[key] = Boxer.unbox(val, (relation) ? relation.type : null)
      } catch (e) {
        console.log(e)
      }
    }

    if (this.permissions) {
      this.permissions = new Permissions(this.permissions)
    }
  }

  toJSON () {
    const relations = this.relations()
    const object = {}

    for (const key in this) {
      const val = this[key]
      const relation = (relations && relations[key]) ? relations[key] : null

      object[key] = Boxer.box(val, (relation) ? relation.type : null, false)
    }

    return object
  }

  keysForRequestJSON () {
    const keys = []

    for (const key in this) {
      keys.push(key)
    }

    return keys
  }

  requestJSON () {
    const relations = this.relations()
    const object = {}

    for (const key of this.keysForRequestJSON()) {
      const val = this[key]
      const relation = (relations && relations[key]) ? relations[key] : null

      object[key] = Boxer.box(val, (relation) ? relation.type : null, true)
    }

    return object
  }

  static ensure (object) {
    if (Array.isArray(object)) {
      for (let i = 0; i < object.length; i++) {
        if (object[i] && !(object[i] instanceof this)) {
          object[i] = new this(object[i])
        }
      }

      return object
    } else {
      if (object && !(object instanceof this)) {
        object = new this(object)
      }
      return object
    }
  }

  copy () {
    const copy = Object.assign(
      Object.create(
        Object.getPrototypeOf(this)
      ),
      this
    )

    return copy
  }

  clone () {
    const clone = cloneDeep(this)
    const object = Object.assign(
      Object.create(
        Object.getPrototypeOf(this)
      ),
      clone
    )

    return object
  }

  relations () {
    return null
  }

  additionalPropertiesForReorder () {
    return null
  }

  get allow () {
    const actions = this.permissions?.actions

    if (actions) {
      return actions.reduce((object, action) => {
        return {
          ...object,
          [action]: true
        }
      }, {})
    }

    return {}
  }
}

class Permissions extends APIObject {
  constructor (permissions) {
    super()

    for (const key in permissions) {
      this[key] = permissions[key]
    }
  }
}
