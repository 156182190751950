const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')

module.exports = {
  darkMode: 'class',
  important: true,
  content: [
    './components/**/*.vue',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.js',
    './mixins/**/*.js',
    './nuxt.config.js',
    './mixins/*.js',
    './nuxt.config.js',
    './node_modules/components-nuxt/**/*.{vue,js,ts,jsx,tsx}'
  ],
  safelist: [
    'bg-primary-*',
    'bg-green-500',
    'bg-teal-900',
    'bg-amber-700',
    'dark:bg-blue-800/20',
    'dark:bg-green-800/20',
    'dark:bg-red-800/20',
    'dark:bg-yellow-800/20',
    'dark:bg-white/20',
    'dark:border-gray-800',
    'dark:border-blue-800',
    'dark:border-green-800',
    'dark:border-red-800',
    'dark:border-yellow-800',
    'dark:text-white/80',
    'dark:bg-zinc-950/50',
    'dark:bg-white/10',
    'w-200',
    'outline-blue-500',
    'bg-gradient-to-tr',
    'from-candy-600',
    'to-crush-800',
    'from-sun-800',
    'to-sun-500',
    'from-ovatublue-900',
    'to-ovatublue-900',
    'from-orange-400',
    'to-rose-400',
    'from-fuchsia-600',
    'to-pink-600',
    {
      pattern: /bg-(ovatublue|ovatugreen|red|green|blue|sky|primary|ovatu|blush|horizon|candy|rose|crush|sky|pink|fuchsia|emerald|yellow|mist|sun|hot|surf|zinc|orange)-(100|200|300|400|500|600|700|800|900|950)/,
      variants: ['lg', 'hover', 'focus', 'lg:hover', 'dark']
    },
    {
      pattern: /border-(ovatublue|ovatugreen|red|green|blue|sky|primary|ovatu|blush|horizon|candy|rose|crush|sky|pink|fuchsia|emerald|yellow|mist|sun|hot|surf|zinc|orange)-(100|200|300|400|500|600|700|800|900|950)/,
      // variants: ['lg', 'hover', 'focus', 'lg:hover', 'dark']
    },
    'grid-cols-1',
    'grid-cols-2',
    'grid-cols-3',
    'grid-cols-4',
    'grid-cols-5',
    'grid-cols-6',
    'col-span-1',
    'col-span-2',
    'col-span-3',
    'col-span-4',
    'col-span-5',
    'col-span-6',
    'no-underline'
  ],
  plugins: [
    require('@tailwindcss/line-clamp'),
    plugin(
      ({ addUtilities, theme, e }) => {
        const values = theme('colCount')

        const utilities = Object.entries(values).map(([key, value]) => {
          return {
            [`.${e(`col-count-${key}`)}`]: { columnCount: `${value}` }
          }
        })

        addUtilities(utilities)
      },
      {
        theme: {
          colCount: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6'
          }
        }
      }
    )
  ],
  theme: {
    customForms: theme => ({
      default: {
        checkbox: {
          '&:indeterminate': {
            background: 'url("data:image/svg+xml,%3Csvg viewBox=\'0 0 16 16\' fill=\'white\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'8\' height=\'2\' x=\'4\' y=\'7\' rx=\'1\'/%3E%3C/svg%3E");',
            borderColor: 'transparent',
            backgroundColor: 'currentColor',
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }
        }
      }
    }),

    fontFamily: {
      display: ['DM Sans', 'Inter'],
      sans: ['DM Sans', 'Inter', 'Helvetica', 'Arial', 'sans-serif'],
      serif: [
        'Constantia',
        'Lucida Bright',
        'Lucidabright',
        'Lucida Serif',
        'Lucida',
        'DejaVu Serif',
        'Bitstream Vera Serif',
        'Liberation Serif',
        'Georgia',
        'serif'
      ],
      mono: [
        'Menlo',
        'Monaco',
        'Consolas',
        'Liberation Mono',
        'Courier New',
        'monospace'
      ]
    },

    textColor: theme => ({
      DEFAULT: theme('colors.gray.600', 'currentColor'),
      inherit: 'inherit',
      ...theme('colors')
    }),

    zIndex: {
      auto: 'auto',
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
      60: 60,
      70: 70,
      80: 80,
      80000: 80000,
      90000: 90000,
      '-10': '-10',
      '-20': '-20',
      '-30': '-30',
      '-40': '-40'
    },



    /*
    |-----------------------------------------------------------------------------
    | Border colors                     https://tailwindcss.com/docs/border-color
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your border colors. By default these use the
    | color palette we defined above, however you're welcome to set these
    | independently if that makes sense for your project.
    |
    | Take note that border colors require a special "default" value set
    | as well. This is the color that will be used when you do not
    | specify a border color.
    |
    | Class name: .border-{color}
    |
    */

    /* borderColor: theme => ({
      ...theme('colors'),
      DEFAULT: 'rgb(var(--color-border-DEFAULT) / <alpha-value>)'
    }), */

    /*
    |-----------------------------------------------------------------------------
    | Width                                    https://tailwindcss.com/docs/width
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your width utility sizes. These can be
    | percentage based, pixels, rems, or any other units. By default
    | we provide a sensible rem based numeric scale, a percentage
    | based fraction scale, plus some other common use-cases. You
    | can, of course, modify these values as needed.
    |
    |
    | It's also worth mentioning that Tailwind automatically escapes
    | invalid CSS class name characters, which allows you to have
    | awesome classes like .w-2/3.
    |
    | Class name: .w-{size}
    |
    */

    width: {
      auto: 'auto',
      px: '1px',
      0: 0,
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem',
      13: '3.25rem',
      14: '3.5rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      50: '12.5',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      90: '22.5rem',
      100: '25rem',
      120: '30rem',
      130: '32.5rem',
      140: '35rem',
      200: '50rem',
      '1/2': '50%',
      '1/3': '33.33333%',
      '2/3': '66.66667%',
      '1/4': '25%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.66667%',
      '5/6': '83.33333%',
      '1/12': '8.33333%',
      '2/12': '16.66667%',
      '3/12': '25%',
      '4/12': '33.33333%',
      '5/12': '41.66667%',
      '6/12': '50%',
      '7/12': '58.33333%',
      '8/12': '66.66667%',
      '9/12': '75%',
      '10/12': '83.33333%',
      '11/12': '91.66667%',
      '2/1': '200%',
      '3/1': '300%',
      '4/1': '400%',
      '5/1': '500%',
      102: 'calc(100% + 2px)',
      extra: 'calc(100% + 1px)',
      full: '100%',
      screen: '100vw',
      max: 'max-content'
    },


    /*
    |-----------------------------------------------------------------------------
    | Height                                  https://tailwindcss.com/docs/height
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your height utility sizes. These can be
    | percentage based, pixels, rems, or any other units. By default
    | we provide a sensible rem based numeric scale plus some other
    | common use-cases. You can, of course, modify these values as
    | needed.
    |
    | Class name: .h-{size}
    |
    */

    height: {
      auto: 'auto',
      px: '1px',
      '2px' : '2px',
      '4px' : '4px',
      '5px' : '5px',
      '1/4' : '25%',
      '1/2': '50%',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem',
      13: '3.25rem',
      14: '3.5rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      90: '22.5rem',
      100: '25rem',
      200: '50rem',
      300: '75rem',
      '95%': '95%',
      102: 'calc(100% + 2px)',
      full: '100%',
      screen: '100vh',
      max: 'max-content',
      '75vh': '75vh',
      '90vh': '90vh',
      '95vh': '95vh'
    },


    /*
    |-----------------------------------------------------------------------------
    | Minimum width                        https://tailwindcss.com/docs/min-width
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your minimum width utility sizes. These can
    | be percentage based, pixels, rems, or any other units. We provide a
    | couple common use-cases by default. You can, of course, modify
    | these values as needed.
    |
    | Class name: .min-w-{size}
    |
    */

    minWidth: {
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      50: '12.5',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      90: '22.5rem',
      100: '25rem',
      120: '30rem',
      130: '32.5rem',
      140: '35rem',
      160: '40rem',
      180: '45rem',
      210: '52.5rem',
      280: '70rem',
      full: '100%',
      '1/4': '25%'
    },


    /*
    |-----------------------------------------------------------------------------
    | Minimum height                      https://tailwindcss.com/docs/min-height
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your minimum height utility sizes. These can
    | be percentage based, pixels, rems, or any other units. We provide a
    | few common use-cases by default. You can, of course, modify these
    | values as needed.
    |
    | Class name: .min-h-{size}
    |
    */

    minHeight: {
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      20: '5rem',
      40: '10rem',
      100: '25rem',
      xs: '20rem',
      sm: '30rem',
      md: '40rem',
      lg: '50rem',
      xl: '60rem',
      full: '100%',
      screen: '100vh'
    },


    /*
    |-----------------------------------------------------------------------------
    | Maximum width                        https://tailwindcss.com/docs/max-width
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your maximum width utility sizes. These can
    | be percentage based, pixels, rems, or any other units. By default
    | we provide a sensible rem based scale and a "full width" size,
    | which is basically a reset utility. You can, of course,
    | modify these values as needed.
    |
    | Class name: .max-w-{size}
    |
    */

    maxWidth: {
      xs: '25rem',
      sm: '30rem',
      md: '40rem',
      lg: '50rem',
      xl: '60rem',
      '2xl': '70rem',
      '3xl': '80rem',
      '4xl': '90rem',
      '5xl': '100rem',
      210: '52.5rem',
      full: '100%',
      40: '10rem',
      50: '12.5rem',
      75: '75vh',
      90: '90vh',
      95: '95vh',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      '1/2': '50%'
    },


    /*
    |-----------------------------------------------------------------------------
    | Maximum height                      https://tailwindcss.com/docs/max-height
    |-----------------------------------------------------------------------------
    |
    | Here is where you define your maximum height utility sizes. These can
    | be percentage based, pixels, rems, or any other units. We provide a
    | couple common use-cases by default. You can, of course, modify
    | these values as needed.
    |
    | Class name: .max-h-{size}
    |
    */

    maxHeight: {
      100: '100px',
      200: '200px',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      18: '4.5rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      '2xs': '14rem',
      xs: '20rem',
      sm: '30rem',
      md: '40rem',
      lg: '50rem',
      xl: '60rem',
      '2xl': '70rem',
      '3xl': '80rem',
      '4xl': '90rem',
      '5xl': '100rem',
      full: '100%',
      screen: '100vh',
      75: '75vh',
      90: '90vh',
      95: '95vh'
    },


    /*
    |-----------------------------------------------------------------------------
    | Padding                                https://tailwindcss.com/docs/padding
    |-----------------------------------------------------------------------------
    */

    padding: {
      px: '1px',
      '2px': '2px',
      '3px': '3px',
      '4px' : '4px',
      '5px' : '5px',
      base: '2.2rem',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      full: '100%'
    },


    extend: {
      colors: {
        /* secondary: {
          DEFAULT: '#2c384c',
          50: '#f4f6f7',
          100: '#e3e6ea',
          200: '#cacfd7',
          300: '#a6aeba',
          400: '#7a8496',
          500: '#5f697b',
          600: '#4b5261',
          700: '#464b58',
          800: '#3e414c',
          900: '#373a42'
        },

        dark      : '#1E1E1E',

        green: {
          DEFAULT: '#8DBA60',
          50: '#E9F2E0',
          100: '#DFEBD2',
          200: '#CADFB5',
          300: '#B6D399',
          400: '#A1C67C',
          500: '#8DBA60',
          600: '#719E44',
          700: '#557633',
          800: '#394F22',
          900: '#1D2811'
        },
        yellow: colors.amber, */

        primary: {
          50: 'rgb(var(--color-primary-50) / <alpha-value>)',
          100: 'rgb(var(--color-primary-100) / <alpha-value>)',
          200: 'rgb(var(--color-primary-200) / <alpha-value>)',
          300: 'rgb(var(--color-primary-300) / <alpha-value>)',
          400: 'rgb(var(--color-primary-400) / <alpha-value>)',
          500: 'rgb(var(--color-primary-500) / <alpha-value>)',
          600: 'rgb(var(--color-primary-600) / <alpha-value>)',
          700: 'rgb(var(--color-primary-700) / <alpha-value>)',
          800: 'rgb(var(--color-primary-800) / <alpha-value>)',
          900: 'rgb(var(--color-primary-900) / <alpha-value>)',
          950: 'rgb(var(--color-primary-950) / <alpha-value>)',
          DEFAULT: 'rgb(var(--color-primary-DEFAULT) / <alpha-value>)'
        },

        gray: {
          50: 'rgb(var(--color-gray-50) / <alpha-value>)',
          100: 'rgb(var(--color-gray-100) / <alpha-value>)',
          200: 'rgb(var(--color-gray-200) / <alpha-value>)',
          300: 'rgb(var(--color-gray-300) / <alpha-value>)',
          400: 'rgb(var(--color-gray-400) / <alpha-value>)',
          500: 'rgb(var(--color-gray-500) / <alpha-value>)',
          600: 'rgb(var(--color-gray-600) / <alpha-value>)',
          700: 'rgb(var(--color-gray-700) / <alpha-value>)',
          800: 'rgb(var(--color-gray-800) / <alpha-value>)',
          900: 'rgb(var(--color-gray-900) / <alpha-value>)',
          950: 'rgb(var(--color-gray-950) / <alpha-value>)',
          DEFAULT: 'rgb(var(--color-primary-DEFAULT) / <alpha-value>)'
        },

        'ovatugreen': {
          50: '#E7F0DE',
          100: '#DDEAD0',
          200: '#C9DDB4',
          300: '#B5D098',
          400: '#A1C47C',
          500: '#8CB760',
          600: '#78A44A',
          700: '#5C7D39',
          800: '#3F5727',
          900: '#233016',
          950: '#151D0D'
        },

        'ovatublue': {
          '50': '#f5f7fa',
          '100': '#eaedf4',
          '200': '#d0d9e7',
          '300': '#a7b9d2',
          '400': '#7894b8',
          '500': '#5776a0',
          '600': '#445e85',
          '700': '#384d6c',
          '800': '#31415b',
          '900': '#2c384c',
          '950': '#1e2633',
        },

        blush: {
          50: '#fdf3f4',
          100: '#fbe8e8',
          200: '#f7d4d6',
          300: '#f1b0b5',
          400: '#e8848d',
          500: '#da5162',
          600: '#c63850',
          700: '#a72942',
          800: '#8c253d',
          900: '#782339',
          950: '#420f1b'
        },

        horizon: {
          50: '#fff9eb',
          100: '#fff3d1',
          200: '#ffe1a1',
          300: '#ffc966',
          400: '#ffa628',
          500: '#ff8900',
          600: '#ff6b00',
          700: '#d54e00',
          800: '#b54204',
          900: '#873507',
          950: '#491701'
        },

        candy: {
          50: '#fef1f5',
          100: '#fee5ee',
          200: '#ffcbdf',
          300: '#ffa1c2',
          400: '#ff81aa',
          500: '#fa3a73',
          600: '#ea184b',
          700: '#cc0a35',
          800: '#a80c2c',
          900: '#8c0f28',
          950: '#560112'
        },

        crush: {
          DEFAULT: '#FFFBE2',
          50: '#FFFFFF',
          100: '#FFFFFF',
          200: '#FFFFFF',
          300: '#FFFFFF',
          400: '#FFFFFF',
          500: '#FFFBE2',
          600: '#FFF3AA',
          700: '#FFEC72',
          800: '#FFE43A',
          900: '#FFDC02',
          950: '#E5C500'
        },

        mist: {
          50: '#f7f8f8',
          100: '#eeeef0',
          200: '#d8dadf',
          300: '#b7bbc2',
          400: '#8f95a1',
          500: '#686e7a',
          600: '#5c616d',
          700: '#4b4f59',
          800: '#40444c',
          900: '#393b41',
          950: '#26272b'
        },

        hot: {
          50: '#fff1f2',
          100: '#ffe4e6',
          200: '#fecdd3',
          300: '#fca5af',
          400: '#fa7285',
          500: '#f3405e',
          600: '#e01e48',
          700: '#bd133c',
          800: '#9f1339',
          900: '#871436',
          950: '#4c0519'
        },

        sun: {
          50: '#fef6ee',
          100: '#fcebd8',
          200: '#f8d2b0',
          300: '#f3b27e',
          400: '#ee8849',
          500: '#e96825',
          600: '#da501c',
          700: '#b53b19',
          800: '#90311c',
          900: '#752b19',
          950: '#3f120b'
        },

        surf: {
          50: '#eefdfd',
          100: '#d4f9f9',
          200: '#aef1f3',
          300: '#5de0e6',
          400: '#37cfd9',
          500: '#1bb3bf',
          600: '#1a90a0',
          700: '#1b7483',
          800: '#1f5f6b',
          900: '#1e4e5b',
          950: '#0e333e'
        },

        contrastPrimaryLight: {
          50: '#f2f5ff',
          100: '#d7e3ff',
          200: '#bbc1ff',
          300: '#9f9fff',
          400: '#828dff',
          500: '#666bff',
          600: '#4d52e0',
          700: '#3a3f99',
          800: '#282c66',
          900: '#161833',
          950: '#0d101a'
        },

        contrastGrayLight: {
          50: '#fafafa',
          100: '#f5f5f5',
          200: '#eeeeee',
          300: '#e0e0e0',
          400: '#bbbbbb',
          500: '#999999',
          600: '#777777',
          700: '#555555',
          800: '#333333',
          900: '#222222',
          950: '#111111'
        },

        contrastPrimaryDark: {
          50: '#e2e2ff',
          100: '#c4c4ff',
          200: '#a6a6ff',
          300: '#8888ff',
          400: '#6a6aff',
          500: '#4c4cff',
          600: '#3a3ae0',
          700: '#292999',
          800: '#191966',
          900: '#090933',
          950: '#04041a'
        },

        contrastGrayDark: {
          50: '#f0f0f0',
          100: '#d9d9d9',
          200: '#c2c2c2',
          300: '#ababab',
          400: '#949494',
          500: '#7d7d7d',
          600: '#666666',
          700: '#4f4f4f',
          800: '#383838',
          900: '#212121',
          950: '#0a0a0a'
        }
      },
      fontSize: {
        /* '2xs': '.6rem',
        xs: '.7rem',
        sm: '.8rem',
        base: '.9rem',
        md: '1rem',
        lg: '1.1rem',
        xl: '1.2rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem',
        '7xl': '5rem',
        9: '.9rem' */
        '2xs': '.55rem',
        xs: '.65rem',
        sm: '.75rem',
        base: '.85rem',
        md: '.95rem',
        lg: '1.05rem',
        xl: '1.25rem',
        '2xl': '1.5rem',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '4rem',
        '7xl': '5rem',
        9: '.9rem'

        /* '2xs': '.575rem',
        xs: '.675rem',
        sm: '.775rem',
        base: '.875rem',
        md: '.975rem',
        lg: '1.075rem',
        xl: '1.275rem',
        '2xl': '1.525rem',
        '3xl': '1.925rem',
        '4xl': '2.275rem',
        '5xl': '3.025rem',
        '6xl': '4.025rem',
        '7xl': '5.025rem',
        9: '.925rem' */

      },
      translate: {
        '2/1': '200%',
        '-2/1': '-200%'
      },
      scale: {
        90: '0.9',
        101: '1.01',
        102: '1.02',
        103: '1.03',
        104: '1.04'
      },
      inset: {
        px: '1px',
        '1/2': '50%',
        full: '100%'
      },
      gridColumn: {
        1: '1'
      },
      gridRow: {
        1: '1'
      },
      margin: {
        base: '2.2rem'
      },
      dropShadow: {
        md: '0 0px 4px rgba(0, 0, 0, 0.1)'
      },
      keyframes: {
        wiggle: {
          '0%, 100%': { transform: 'translateX(-10px)' },
          '50%': { transform: 'translateX(10px)' }
        },
        shake: {
          '10%, 90%': { transform: 'translate3d(-1px, 0, 0)' },
          '20%, 80%': { transform: 'translate3d(2px, 0, 0)' },
          '30%, 50%, 70%': {  transform: 'translate3d(-4px, 0, 0)' },
          '40%, 60%': { transform: 'translate3d(4px, 0, 0);' }
        },
        ring: {
          '0%': { transform: 'rotate(0)' },
          '1%': { transform: 'rotate(30deg)' },
          '3%': { transform: 'rotate(-28deg)' },
          '5%': { transform: 'rotate(34deg)' },
          '7%': { transform: 'rotate(-32deg)' },
          '9%': { transform: 'rotate(30deg)' },
          '11%': { transform: 'rotate(-28deg)' },
          '13%': { transform: 'rotate(26deg)' },
          '15%': { transform: 'rotate(-24deg)' },
          '17%': { transform: 'rotate(22deg)' },
          '19%': { transform: 'rotate(-20deg)' },
          '21%': { transform: 'rotate(18deg)' },
          '23%': { transform: 'rotate(-16deg)' },
          '25%': { transform: 'rotate(14deg)' },
          '27%': { transform: 'rotate(-12deg)' },
          '29%': { transform: 'rotate(10deg)' },
          '31%': { transform: 'rotate(-8deg)' },
          '33%': { transform: 'rotate(6deg)' },
          '35%': { transform: 'rotate(-4deg)' },
          '37%': { transform: 'rotate(2deg)' },
          '39%': { transform: 'rotate(-1deg)' },
          '41%': { transform: 'rotate(1deg)' },
          '43%': { transform: 'rotate(0)' },
          '100%': { transform: 'rotate(0)' }
        }
      },
      animation: {
        wiggle: 'wiggle 100ms ease-in-out 500ms',
        shake: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        ring: 'ring 4s .7s ease-in-out infinite'
      },
      borderColor: {
        DEFAULT: 'rgb(var(--color-border-DEFAULT) / <alpha-value>)'
      }
    }
  },
  /*
  |-----------------------------------------------------------------------------
  | variants                  https://tailwindcss.com/docs/configuration#modules
  |-----------------------------------------------------------------------------
  |
  | Here is where you control which modules are generated and what variants are
  | generated for each of those modules.
  |
  | Currently supported variants:
  |   - responsive
  |   - hover
  |   - focus
  |   - active
  |   - group-hover
  |
  | To disable a module completely, use `false` instead of an array.
  |
  */

  variants: {
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColor: ['dark', 'responsive', 'hover', 'focus', 'group-hover', 'odd', 'even'],
    // backgroundPosition: ['responsive'],
    // backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    backgroundOpacity: ['responsive', 'hover', 'focus', 'checked'],
    borderCollapse: [],
    borderColor: ['dark', 'responsive', 'hover', 'focus-within', 'focus', 'checked'],
    borderRadius: ['responsive', 'last', 'first'],
    borderStyle: ['responsive', 'hover', 'focus', 'active', 'first', 'last'],
    borderWidth: ['responsive', 'hover', 'focus', 'active', 'first', 'last'],
    cursor: [],
    display: ['responsive', 'group-hover'],
    flexDirection: ['responsive'],
    flexWrap: ['responsive'],
    alignItems: ['responsive'],
    alignSelf: ['responsive'],
    justifyContent: ['responsive'],
    alignContent: ['responsive'],
    flex: ['responsive'],
    flexGrow: ['responsive'],
    flexShrink: ['responsive'],
    float: ['responsive'],
    fontWeight: ['responsive', 'hover'],
    gradientColorStops: ['hover'],
    height: ['responsive', 'hover'],
    lineHeight: ['responsive'],
    listStylePosition: ['responsive'],
    listStyleType: ['responsive'],
    margin: ['hover', 'responsive', 'last', 'first'],
    maxHeight: ['responsive', 'hover'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    negativeMargin: ['hover', 'responsive'],
    opacity: ['responsive', 'hover', 'group-hover'],
    outline: ['focus', 'hover'],
    outlineColor: ['focus', 'hover'],
    overflow: ['responsive'],
    order: ['responsive', 'hover', 'focus'],
    padding: ['responsive', 'first', 'last'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    inset: ['responsive'],
    resize: ['responsive', 'hover', 'focus'],
    boxShadow: ['responsive', 'hover', 'focus', 'focus-within'],
    fill: [],
    ring: ['focus', 'focus-within'],
    stroke: [],
    tableLayout: ['responsive'],
    textAlign: ['responsive'],
    textColor: ['dark', 'responsive', 'hover', 'focus', 'checked'],
    fontSize: ['responsive'],
    fontStyle: ['responsive', 'hover', 'focus'],
    fontSmoothing: ['responsive', 'hover', 'focus'],
    textDecoration: ['responsive', 'hover', 'focus'],
    textTransform: ['responsive', 'hover', 'focus'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive'],
    whitespace: ['responsive'],
    wordBreak: ['responsive'],
    width: ['responsive', 'focus', 'focus-within'],
    zIndex: ['responsive', 'hover'],
    scale: ['hover'],
    colCount: ['responsive']
  },

  corePlugins: {
    // fontFamily: false,
    // backgroundPosition: false,
    // backgroundRepeat: false
  }
}
