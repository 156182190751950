import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import { plugin as $date } from '../../plugins/date'

import Service from './service'
import Reservation from './reservation'
import Customer from './customer'
import { ConfirmationRequestService } from './booking'

export default class Recurrence extends APIObject {
  relations () {
    return {
      recurrence: { type: Recurrence },
      repeat: { type: RecurrenceRepeat },
      ending: { type: RecurrenceEnding },
      selectedDates: { type: RecurrenceSelectedDate },
      datesWithConflicts: { type: RecurrenceDateWithConflict },
      services: { type: ConfirmationRequestService }
    }
  }

  get label () {
    const frequency = this.repeat.frequency

    let interval
    let on
    let ending

    if (frequency > 1) {
      interval = `${frequency} ${this.repeat.interval.description}s`
    } else {
      interval = this.repeat.interval.description
    }

    if (this.isRecurrenceIntervalWeek) {
      const day = Number(this.repeat.on.values[0])
      on = ` on ${RecurrenceDays.symbolForValue(day)?.description}`
    }

    if (this.isRecurrenceIntervalMonth) {
      if (this.isRecurrenceMonthTypeDay) {
        const week = this.repeat.on.options.weeksOfMonth[0]
        const day = this.repeat.on.values[0]
        on = ` on the ${week.description} ${RecurrenceDays.symbolForValue(day)?.description}`
      }

      if (this.isRecurrenceMonthTypeDate) {
        const day = this.repeat.on.values[0]
        const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' })
        const suffixes = { one: 'st', two: 'nd', few: 'rd', other: 'th' }
        const category = english_ordinal_rules.select(day)
        const suffix = suffixes[category]
        on = ` on the ${day + suffix} day of the month`
      }

      if (this.isRecurrenceMonthTypeLast) {
        on = ' on the last day of the month'
      }
    }

    if (this.isRecurrenceIntervalYear) {
      const date = this.repeat.on.values[0].date
      const month = this.repeat.on.values[0].month

      on = ` on ${RecurrenceMonths.symbolForValue(month).description} ${date}`
    }

    if (this.isEndingOnDate) {
      const date = $date.convert(this.ending.date, undefined, $date.presets.long)
      ending = `ending on ${date}`
    }

    if (this.isEndingAfterRepeats) {
      ending = `ending after ${this.ending.numberOfRepeats} repeats`
    }

    return `Repeats every ${interval}${on}, ${ending}.`
  }

  get isEndingOnDate () {
    return !!this.ending.date?.value
  }

  get isEndingAfterRepeats () {
    return !!this.ending.numberOfRepeats
  }

  get isRecurrenceIntervalWeek () {
    return this.repeat.interval === RecurrenceIntervalType.week
  }

  get isRecurrenceIntervalMonth () {
    return this.repeat.interval === RecurrenceIntervalType.month
  }

  get isRecurrenceIntervalYear () {
    return this.repeat.interval === RecurrenceIntervalType.year
  }

  get isRecurrenceMonthTypeDate () {
    return !this.isRecurrenceMonthTypeDay && !this.isRecurrenceMonthTypeLast
  }

  get isRecurrenceMonthTypeDay () {
    return !!this.repeat.on.options?.weeksOfMonth
  }

  get isRecurrenceMonthTypeLast () {
    return !!this.repeat.on.options?.lastDayOfMonth
  }
}

export class RecurrenceRequest extends APIObject {
  relations () {
    return {
      reservation: { type: Reservation },
      services: { type: Service },
      recurrence: { type: Recurrence }
    }
  }

  toJSON () {
    const object = {
      recurrence: this.recurrence.toJSON(), // .toJSON(),
      services: (this.services) ? this.services.map(service => service.toJSON()) : null,
      idReservation: (this.reservation) ? this.reservation.idReservation : null
    }

    return object
  }

  static create (recurrence, { reservation, services, datesWithConflicts } = {}) {
    const request = new RecurrenceRequest()

    request.reservation = reservation
    request.services = services

    const object = {
      repeat: {},
      ending: {}
    }

    object.repeat.frequency = recurrence.repeat.interval === RecurrenceIntervalType.year ? 1 : recurrence.repeat.frequency

    if (recurrence.repeat.interval === RecurrenceIntervalType.week) {
      object.repeat.interval = RecurrenceIntervalType.week
      object.repeat.on = {
        values: recurrence.repeat.on.week.days
      }
    }

    if (recurrence.repeat.interval === RecurrenceIntervalType.month) {
      object.repeat.interval = RecurrenceIntervalType.month

      if (recurrence.repeat.on.month.type === RecurrenceIntervalMonthType.day) {
        object.repeat.on = {
          options: {
            weeksOfMonth: recurrence.repeat.on.month.weeks
              .map(week => week.value)
          },
          values: recurrence.repeat.on.month.days
        }
      }

      if (recurrence.repeat.on.month.type === RecurrenceIntervalMonthType.date) {
        object.repeat.on = {
          values: recurrence.repeat.on.month.dates
        }
      }

      if (recurrence.repeat.on.month.type === RecurrenceIntervalMonthType.last) {
        object.repeat.on = {
          options: { lastDayOfMonth: true }
        }
      }
    }

    if (recurrence.repeat.interval === RecurrenceIntervalType.year) {
      object.repeat.interval = RecurrenceIntervalType.year
      const date = recurrence.repeat.on.year.date
      object.repeat.on = {
        values: [
          { date: date.date, month: date.month.value }
        ]
      }
    }

    if (recurrence.ending.type === RecurrenceEndingType.date) {
      object.ending.date = recurrence.ending.date
    }

    if (recurrence.ending.type === RecurrenceEndingType.repeats) {
      object.ending.numberOfRepeats = recurrence.ending.repeats
    }

    if (recurrence.datesWithConflicts) {
      object.datesWithConflicts = recurrence.datesWithConflicts
    }

    request.recurrence = new Recurrence(object)

    return request
  }
}

export class RecurrenceRepeat extends APIObject {
  relations () {
    return {
      interval: { type: RecurrenceIntervalType },
      on: { type: RecurrenceRepeatOn }
    }
  }
}

export class RecurrenceRepeatOn extends APIObject {
  relations () {
    return {
      // days: { type: RecurrenceDays },
      options: { type: RecurrenceRepeatOptions }
    }
  }
}

export class RecurrenceRepeatOptions extends APIObject {
  relations () {
    return {
      // days: { type: RecurrenceDays },
      weeksOfMonth: { type: RecurrenceWeeks }
    }
  }
}

export class RecurrenceEnding extends APIObject {
  relations () {
    return {
      date: { type: DateYMD }
    }
  }
}

export class RecurrenceSelectedDate extends APIObject {
  relations () {
    return {
      options: { type: RecurrenceConflict },
      option: { type: RecurrenceConflict }
    }
  }

  get hasConflicts () {
    return !!this.options
  }

  get conflictOptions () {
    return this.options
      ?.map((option) => {
        return {
          value: option,
          text: option.description
        }
      })
  }
}

export class RecurrenceDateWithConflict extends APIObject {
  relations () {
    return {
      option: { type: RecurrenceConflict }
    }
  }
}

export const RecurrenceIntervalType = new Enum({
  week: { value: 'week', description: 'Week' },
  month: { value: 'month', description: 'Month'  },
  year: { value: 'year', description: 'Year'  }
})

export const RecurrenceDays = new Enum({
  monday: { value: 1, description: 'Monday' },
  tuesday: { value: 2, description: 'Tuesday' },
  wednesday: { value: 3, description: 'Wednesday' },
  thursday: { value: 4, description: 'Thursday' },
  friday: { value: 5, description: 'Friday' },
  saturday: { value: 6, description: 'Saturday' },
  sunday: { value: 7, description: 'Sunday' }
})

export const RecurrenceMonths = new Enum({
  january: { value: 1, description: 'January' },
  february: { value: 2, description: 'February' },
  march: { value: 3, description: 'March' },
  april: { value: 4, description: 'April' },
  may: { value: 5, description: 'May' },
  june: { value: 6, description: 'June' },
  july: { value: 7, description: 'July' },
  august: { value: 8, description: 'August' },
  september: { value: 9, description: 'September' },
  october: { value: 10, description: 'October' },
  november: { value: 11, description: 'November' },
  december: { value: 12, description: 'December' }
})

export const RecurrenceIntervalMonthType = new Enum({
  day: { value: 'day', description: 'On day of week' },
  date: { value: 'date', description: 'On day of month' },
  last: { value: 'last', description: 'On last day of month' }
})

export const RecurrenceEndingType = new Enum({
  date: { value: 'date', description: 'On date' },
  repeats: { value: 'repeats', description: 'After number of repeats' }
})

export const RecurrenceWeeks = new Enum({
  1: { value: 1, description: '1st' },
  2: { value: 2, description: '2nd' },
  3: { value: 3, description: '3rd' },
  4: { value: 4, description: '4th' }
})

export const RecurrenceConflict = new Enum({
  skip: { value: 'skip', description: 'Skip' },
  doubleBook: { value: 'doubleBook', description: 'Double book' },
  delete: { value: 'delete', description: 'Delete' }
})

export const RecurrenceCustomerAction = new Enum({
  book: { value: 'book', description: 'Book' },
  cancel: { value: 'cancel', description: 'Cancel' },
  delete: { value: 'delete', description: 'Delete' }
})
