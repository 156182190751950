import Model, { Request } from '../model'
import Enum from '../enums'

export default class CustomerCreditCard extends Model {
  static modelName () {
    return 'customerCreditCard'
  }

  objectID () {
    return this.idCustomerCreditCard
  }

  relations () {
    return {
      processor: { type: CustomerCreditCardProcessor },
      status: { type: CustomerCreditCardStatus }
    }
  }

  get isProcessorStripe () {
    return this.processor === CustomerCreditCardProcessor.stripe
  }

  get isProcessorSquare () {
    return this.processor === CustomerCreditCardProcessor.square
  }

  get isStatusActive () {
    return this.status === CustomerCreditCardStatus.active
  }

  get isStatusExpiringSoon () {
    return this.status === CustomerCreditCardStatus.expiringSoon
  }

  get isStatusExpired () {
    return this.status === CustomerCreditCardStatus.expired
  }
}

export const CustomerCreditCardProcessor = new Enum({
  stripe: { value: 0, description: 'Stripe' },
  square: { value: 1, description: 'Square' }
})


export const CustomerCreditCardStatus = new Enum({
  active: { value: 0, description: 'Active' },
  expiringSoon: { value: 1, description: 'Expiring soon' },
  expired: { value: 2, description: 'Expired' }
})
