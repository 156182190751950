export default (context, inject) => {
  const plugin = {
    /**
     * Check if the location has the required gate
     * @param {string} model - Name of the gate
     */
    get (gate) {
      const active = context.$auth.active
      const gates = this.list

      // Check if there is an active user and gates are available
      if (!active || !gates) {
        return false
      }

      // Check if the user has the required model
      return gates.includes(gate)
    },

    /**
     * Check if the location has all the required gates in array
     * @param {array} gates - A list of gates
     * @returns boolean
     */
    all (gates) {
      return gates
        .every((gate) => {
          return this.get(gate)
        })
    },

    /**
     * Check if the location has any of the required gates in array
     * @param {array} gates - A list of gates
     * @returns boolean
     */
    any (gates) {
      return gates
        .some((gate) => {
          return this.get(gate)
        })
    },

    /**
     * Return the required gates for a given path
     * @param {string} path - A path to check
     * @returns boolean
     */
    route (path) {
      if (!path) {
        return false
      }

      const resolved = context.app.router.resolve(path)
      const gates = resolved && resolved.route ? resolved.route.meta.gates : null

      return gates
    },

    /**
     * Check if location has the required gates for given path
     * @param {string} path - A path to check
     * @returns boolean
     */
    access (path) {
      if (!path) {
        return false
      }

      const resolved = context.app.router.resolve(path)
      const gates = resolved && resolved.route ? resolved.route.meta.gates : null

      if (!gates) {
        return true
      }

      return this.all(gates)
    },

    /**
     * Get a list of the locations gates
     */
    get list () {
      const info = context.store.getters['auth/info']
      return info.gates
    }
  }

  inject('gates', plugin)
}
