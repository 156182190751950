<template>
  <div
    :class="[
      'border border-dashed rounded-md border-gray-300 dark:border-gray-700 transition',
      {'border-green-500 bg-green-200 border-2 text-green-700 dark:bg-primary-600/10 dark:border-primary-800 dark:text-white' : active}
    ]"
  >
    <div v-if="!_value" :class="uploadClass" @dragover="handleDragover" @dragleave="handleDragleave" @drop="handleDrop">
      <input
        id="assetsFieldHandle"
        ref="input"
        type="file"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        accept=".pdf,.jpg,.jpeg,.png"
        @change="handleInputChange"
      >

      <div class="pointer-events-none">
        <slot :upload="handleUploadClick">
          <p class="text-sm ">
            {{ label }}
          </p>
          <p v-if="info" class="text-xs text-gray-500 dark:text-gray-400">
            {{ info }}
          </p>

          <div class="mt-4 w-full">
            <div v-if="active" class="w-full flex items-center justify-center">
              <o-icon icon="upload" :size="32" />
            </div>

            <o-button v-else class="pointer-events-auto" size="sm" @click="handleUploadClick">
              Upload a file
            </o-button>
          </div>
        </slot>
      </div>
    </div>

    <slot v-if="_value" name="image-preview" v-bind="{ preview, fileTypeLabel, fileLabel, sizeLabel, isImage, handleSelect }">
      <div class="m-4">
        <div class="mx-auto w-auto flex flex-col items-center">
          <img v-if="isImage" :src="preview" class="h-48 rounded-xl overflow-hidden mb-2 bg-white">
          <span v-else class="text-blue-700 uppercase text-sm h-32 w-32 mb-2 flex-shrink-0 bg-blue-200 rounded-xl flex items-center justify-center">{{ fileTypeLabel }}</span>

          <div class="flex items-center space-x-8">
            <p class="text-gray-700 text-sm dark:text-gray-200">
              {{ fileLabel }} <span class="text-gray-600 dark:text-gray-300">- {{ sizeLabel }}</span>
            </p>

            <o-icon class="opacity-75 hover:opacity-100 transition m-1 cursor-pointer" icon="delete" @click="handleClearInput" />
          </div>
        </div>
      </div>
    </slot>

    <!--<div
      v-if="_value"
      class="relative h-32 w-32 my-4 mx-auto rounded-xl bg-center bg-contain bg-no-repeat group overflow-hidden"
      :class="isImage ? 'bg-gray-50 border' : 'bg-gray-50 border'"
      :style="{ backgroundImage: `url(${preview})` }"
    >
      <div v-if="!isImage" class="absolute inset-0 h-full flex items-center justify-center">
        <o-icon icon="file" :size="26" />
      </div>

      <div
        class="w-full h-full absolute inset-0 transition flex flex-col justify-between p-2 text-left"
        :class=" isImage ? 'bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 text-white' : 'text-gray-600'"
      >
        <div>
          <p class="text-sm break-all">
            {{ fileLabel }}
          </p>
        </div>

        <div class="flex items-end justify-between w-full leading-none">
          <p class="text-sm break-all">
            {{ sizeLabel }}
          </p>

          <o-icon class="opacity-75 hover:opacity-100 transition m-1 cursor-pointer" icon="delete" @click="handleClearInput" />
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null
    },
    caption: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Drag your file here or click to browse'
    },
    info: {
      type: String,
      default: null
    },
    uploadClass: {
      type: String,
      default: 'py-10 px-3 text-center'
    }
  },
  data () {
    return {
      preview: '',
      active: false
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    _caption: {
      get () {
        return this.caption
      },
      set (value) {
        this.$emit('update:caption', value)
      }
    },
    fileLabel () {
      const file = this._value

      return file.name
    },
    fileTypeLabel () {
      const file = this._value
      const name = file.name
      const parts = name.split('.')

      return parts[parts.length - 1]
    },
    sizeLabel () {
      const file = this._value

      return file.size > 1024
        ? file.size > 1048576
          ? Math.round(file.size / 1048576) + 'mb'
          : Math.round(file.size / 1024) + 'kb'
        : file.size + 'b'
    },
    isImage () {
      return ['image/jpeg', 'image/png', 'image/gif'].includes(this._value?.type)
    }
  },
  mounted () {
    if (this._value) {
      this.preview = URL.createObjectURL(this._value)
    }
  },
  methods: {
    handleInputChange () {
      const file = this.$refs.input.files[0]

      this._value = file

      if (!this._caption?.length) {
        this._caption = file.name
      }

      this.preview = URL.createObjectURL(file)
    },
    handleClearInput () {
      this._value = null
    },
    handleUploadClick () {
      this.$refs.input.click()
    },
    handleDragover ($event) {
      $event.preventDefault()

      this.active = true
    },
    handleDragleave ($event) {
      this.active = false
    },
    handleDrop ($event) {
      $event.preventDefault()
      this.$refs.input.files = $event.dataTransfer.files
      this.handleInputChange()

      this.active = false
    },
    handleSelect () {
      this.$emit('input', this._value)
    }
  }
}
</script>
