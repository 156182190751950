<template>
  <div
    :class="[
      baseClass,
      { 'bg-gray-50 cursor-not-allowed opacity-75 dark:bg-gray-900' : disabled },
      { 'ring-2 ring-primary-600' : isActive }
    ]"
  >
    <slot>
      <template v-if="value">
        <div class="flex items-center mr-2">
          {{ value }}
        </div>
      </template>
      <template v-else>
        <p class="opacity-80 mr-3 leading-none">
          {{ placeholder }}
        </p>
      </template>
    </slot>
    <o-icon icon="chevronDown" :size="10" />
  </div>
</template>

<script>
export default {
  inject: ['dropdown'],
  props: {
    placeholder: {
      type: String,
      default: 'Select an option'
    },
    value: {
      type: [String, Number, Object, Array],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    baseClass: {
      type: String,
      default: 'w-full py-2 px-2 border dark:border-gray-800 rounded-md h-9 flex items-center justify-between cursor-pointer text-sm'
    }
  },
  computed: {
    _dropdown () {
      return this.dropdown()
    },
    isActive () {
      return this._dropdown.show
    }
  }
}
</script>
