<template>
  <section
    :id="computedId"
    class="section-component-panel"
    role="sectionpanel"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: [String, Array],
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _isSection () {
      return true
    },
    header () {
      return this.prefix + this.name + this.suffix
    },
    computedId () {
      return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-')
    },
    hash () {
      if (this.isDisabled) {
        return '#'
      }
      return '#' + this.computedId
    }
  }
}
</script>
