import Model, { Request } from '../model'
import Enum from '../enums'

import { plugin as $date } from '../../plugins/date'

import Customer from './customer'
import ReservationService from './reservationService'

export default class Waitlist extends Model {
  static modelName () {
    return 'waitlist'
  }

  static modelApiVersion () {
    return 1
  }

  objectID () {
    return this.idWaitlist
  }

  relations () {
    return {
      customer: { type: Customer },
      services: { type: ReservationService },
      status: { type: WaitlistStatus }
    }
  }

  static create ({ customer, services, dates } = {}) {
    const url = this.modelBaseURL()

    const data = {
      dates,
      customer: { id: customer.idCustomer },
      services: services
        .map((service) => {
          return {
            service:  service.service?.idService || null,
            employee: service.employee?.idEmployee || null
          }
        })
    }

    return this.requestItem(Request.jsonPost(url, JSON.stringify(data)), this)
  }

  static filtered ({ page = 1, filter = null } = {}) {
    let url = this.modelBaseURL() + '/?page=' + page

    if (filter) {
      url += `&filter=${filter}`
    }

    return this.requestList(Request.get(url), Waitlist)
  }

  cancel () {
    const url = this.constructor.modelBaseURL() + '/cancel?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  email () {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  notify () {
    const url = this.constructor.modelBaseURL() + '/notify?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  get servicesLabel () {
    const services = this.services

    const labels = services
      .map((service) => {
        return service.service.title
      })
      .filter((value, index, array) => {
        return array.indexOf(value) === index
      })

    return labels.join(', ')
  }

  get employeesLabel () {
    const services = this.services

    const labels = services
      .map((service) => {
        return service.employee ? service.employee.displayName : 'First available'
      })
      .filter((value, index, array) => {
        return array.indexOf(value) === index
      })

    return labels.join(', ')
  }

  get datesLabel () {
    const dates = this.dates

    const labels = dates
      .map((date) => {
        let label = ''

        if (date.type === 'day') {
          label += date.day
        }

        if (date.type === 'date') {
          const utc = $date.utc(new Date(date.date * 1000))
          label += $date.format(utc, $date.presets.long)
        }

        if (date.startTime || date.endTime) {
          label += ' ('

          if (date.startTime) {
            const startTime = $date.minutes(date.startTime)
            label += 'After ' + startTime
          }

          if (date.startTime && date.endTime) {
            label += ' & '
          }

          if (date.endTime) {
            const endTime = $date.minutes(date.endTime)
            label += 'Before ' + endTime
          }
          
          label += ')'
        }

        return label
      })

    return labels
  }

  get statusColour () {
    switch (this.status) {
      case WaitlistStatus.waiting:
        return 'warning'
      case WaitlistStatus.booked:
        return 'success'
      case WaitlistStatus.offered:
      case WaitlistStatus.notified:
        return 'success'
      case WaitlistStatus.alerted:
        return 'warning'
      case WaitlistStatus.expired:
      case WaitlistStatus.cancelled:
        return 'danger'
      default:
        return 'info'
    }
  }

  get hasAvailability () {
    return !!this.availability && !!this.availability.firstSlot
  }

  get isCancelled () {
    return this.status === WaitlistStatus.cancelled
  }

  get isBooked () {
    return this.status === WaitlistStatus.booked
  }

  get isWaiting () {
    return this.status === WaitlistStatus.waiting
  }
}

export const WaitlistStatus = new Enum({
  waiting: { value: 0, description: 'Waiting' },
  alerted: { value: 1, description: 'Alerted' },
  notified: { value: 2, description: 'Notified' },
  offered: { value: 3, description: 'Offered' },
  booked: { value: 4, description: 'Booked' },
  expired: { value: 5, description: 'Expired' },
  cancelled: { value: 6, description: 'Cancelled' },
  available: { value: 7, description: 'Available' }
})

