export default class Model {
  constructor ({ name, component }) {
    this.name = name
    this.component = component

    this.items = []
    this.results = []
    this.syncing = false
  }

  search (query) {
    return true
  }
}
