import Model, { Request } from '../model'
import Enum from '../enums'

import Customer from './customer'
import Form from './form'
import FormItem from './formItem'

export default class CustomerForm extends Model {
  static modelName () {
    return 'customerForm'
  }

  objectID () {
    return this.idCustomerForm
  }

  relations () {
    return {
      customer: { type: Customer },
      form: { type: Form },
      formItems: { type: FormItem },
      requestState: { type: CustomerFormRequestState },
      state: { type: CustomerFormState }
    }
  }

  keysForRequestJSON () {
    return [
      'consentAccepted',
      'formItems',
      'idCustomer',
      'idForm',
      'idReservation',
      'idReservationCustomer'
    ]
  }

  static defaults ({ form, customer, reservation, prefill }) {
    const url = this.modelBaseURL() + '/new'

    const data = {
      form: form?.idForm,
      customer: customer?.idCustomer,
      reservation: reservation?.idReservation,
      reservationCustomer: reservation?.idReservationCustomer,
      prefill
    }

    return this.requestItem(Request.post(url, JSON.stringify(data)), this)
  }

  static requestForm ({ form, customer, reservation, prefill }) {
    const url = this.modelBaseURL() + '/request'

    const data = {
      form: form?.idForm,
      customer: customer?.idCustomer,
      reservation: reservation?.idReservation,
      reservationCustomer: reservation?.idReservationCustomer,
      prefill
    }

    return this.requestItem(Request.post(url, JSON.stringify(data)), this)
  }

  static scheduleForm ({ form, customer, reservation, prefill, offset }) {
    const url = this.modelBaseURL() + '/schedule'

    const data = {
      form: form?.idForm,
      customer: customer?.idCustomer,
      reservation: reservation?.idReservation,
      reservationCustomer: reservation?.idReservationCustomer,
      offset: offset || 0,
      prefill
    }

    return this.requestItem(Request.post(url, JSON.stringify(data)), this)
  }

  reopen () {
    const url = this.constructor.modelBaseURL() + '/reopen?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  requestUpdate () {
    const url = this.constructor.modelBaseURL() + '/requestUpdate?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  email () {
    const url = this.constructor.modelBaseURL() + '/email?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  // TODO: Post notifications

  requestStateVariant () {
    switch (this.requestState.value) {
      case 1:
        return 'warning'
      case 2:
        return 'success'
      case 3:
        return 'warning'
      case 4:
        return 'warning'
      default:
        return 'default'
    }
  }

  get isRequestStateNone () {
    return this.requestState === CustomerFormRequestState.none
  }

  get isRequestStateRequested () {
    return this.requestState === CustomerFormRequestState.requested
  }

  get isRequestStateCompleted () {
    return this.requestState === CustomerFormRequestState.completed
  }

  get isRequestStateUpdateRequested () {
    return this.requestState === CustomerFormRequestState.updateRequested
  }

  get isRequestStateScheduled () {
    return this.requestState === CustomerFormRequestState.scheduled
  }

  get isFormStateOpen () {
    return this.state === CustomerFormState.open
  }

  get isFormStateLocked () {
    return this.state === CustomerFormState.locked
  }

  get isFormStateReopened () {
    return this.state === CustomerFormState.reopened
  }

  get showContents () {
    if (this.isRequestStateRequested || this.isRequestStateScheduled) {
      return false
    }

    return true
  }

  get canRequest () {
    return (this.state === CustomerFormState.open || this.state === CustomerFormState.reopened)
  }

  get isRequested () {
    if (this.isRequestStateRequested || this.isRequestStateUpdateRequested) {
      return true
    }

    return false
  }
}

export const CustomerFormRequestState = new Enum({
  none: { value: 0, description: 'None' },
  requested: { value: 1, description: 'Requested' },
  completed: { value: 2, description: 'Completed' },
  updateRequested: { value: 3, description: 'Update Requested' },
  scheduled: { value: 4, description: 'Scheduled' }
})

export const CustomerFormState = new Enum({
  open: { value: 0, description: 'Open' },
  locked: { value: 1, description: 'Locked' },
  reopened: { value: 2, description: 'Re-opened' }
})
