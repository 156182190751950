import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Upload from './upload'

export default class Marketing extends Model {
  static modelName () {
    return 'marketing'
  }

  static sentMessages ({ page, channel, to, customer }) {
    const filter = {}

    if (channel) {
      filter.channel = channel
    }
    if (to) {
      filter.to = to
    }
    if (customer) {
      filter.customer = customer.idCustomer
    }

    const url = this.modelBaseURL() + '/sentMessages?page=' + (page || 1)

    const request = {
      filter
    }

    return this.requestList(Request.post(url, JSON.stringify(request)), MarketingMessage)
  }

  static sentMessage (channel, id) {
    const url = this.modelBaseURL() + '/sentMessage?channel=' + channel + '&id=' + id

    return this.requestItem(Request.post(url), MarketingMessage)
  }

  static sentSMSInfo (id) {
    const url = this.modelBaseURL() + '/sentSMSInfo?idLocationSmsSend=' + id

    return this.requestItem(Request.post(url), SentSMSInfo)
  }

  static template ({ template, models } = {}) {
    const url = this.modelBaseURL() + '/template'
    const params = {
      template,
      models
    }

    return this.requestItem(Request.post(url, JSON.stringify(params)), MarketingTemplateResponse)
  }

  static sendEmail ({ customers, subject, body } = {}) {
    const url = this.modelBaseURL() + '/sendEmail'

    const params = {
      customers,
      subject,
      body
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(params)))
  }

  static sendSMS ({ customers, body } = {}) {
    const url = this.modelBaseURL() + '/sendSms'

    const params = {
      customers,
      body
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(params)))
  }

  static uploads ({ page = 1, search = null, dataType = null } = {}) {
    let url = this.modelBaseURL() + '/uploads?page=' + page

    const data = {}

    if (search) {
      // url += '&search=' + search
      data.search = search
    }

    if (dataType !== null) {
      // url += '&dataType=' + dataType
      data.dataType = dataType
    }

    return this.requestList(Request.post(url, data), Upload)
  }
}

export class MarketingTemplateResponse extends APIObject {
  // placeholders: [String]
  // links: [{ name: String, text: String, placeholder: String }]

  relations () {
    return {
      template: { type: MarketingTemplate }
    }
  }
}

export class MarketingTemplate extends APIObject {
  // name: String
  // subject: String
  // body: String
  // placeholders: [String]
}

export class SentSMSInfo extends APIObject {

}

export class MarketingMessage extends APIObject {
  relations () {
    return {
      channel: { type: MarketingMessageChannel }
    }
  }

  get isChannelEmail () {
    return this.channel === MarketingMessageChannel.email
  }

  get isChannelSMS () {
    return this.channel === MarketingMessageChannel.sms
  }
}

export const MarketingMessageChannel = new Enum({
  email: { value: 'email', description: 'Email' },
  sms: { value: 'sms', description: 'SMS' }
})
