import RemoteFilter from './remoteFilter'

export default class HybridFilter extends RemoteFilter {
  constructor (type, config) {
    super(type, config)
  }

  filterLocally () {
    if (this.config && this.config.filterLocally) {
      return this.config.filterLocally.apply(this, [this.value])
    } else {
      return true
    }
  }

  filterRemotely () {
    if (this.config && this.config.filterRemotely) {
      return this.config.filterRemotely.apply(this, [this.value])
    } else {
      return true
    }
  }
}