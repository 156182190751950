import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class Minisite extends Model {
  static modelName () {
    return 'minisite'
  }

  objectID () {
    return null
  }

  relations () {
    return {
      preferences: { type: MinisitePreferences },
      paymentSettings: { type: MinisitePaymentSettings }
    }
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }

  save () {
    const url = this.constructor.modelBaseURL()
    const request = Request.jsonPost(url, this.requestJSON())

    const that = this
    return this.constructor.requestItem(request, this.constructor)
      .catch((err) => {
        throw err
      }).then((item) => {
        that.saturated = new Date()

        for (const key in item) {
          that[key] = item[key]
        }

        return that
      })
  }
}

export class MinisitePreferences extends APIObject {
  relations () {
    return {
      onlineBookingAllowCustomers: { type: MinisitePreferencesOnlineBookingAllowCustomers },
      allowInternetSelectEmployee: { type: MinisitePreferencesAllowInternetSelectEmployee },
      waitlistMode: { type: MinisitePreferencesWaitlistMode }
    }
  }
}

export const MinisitePreferencesOnlineBookingAllowCustomers = new Enum({
  all: { value: 0, description: 'All customers unless disabled in segment' },
  none: { value: 1, description: 'No customers unless enabled in segment' }
})

export const MinisitePreferencesAllowInternetSelectEmployee = new Enum({
  all: { value: 0, description: 'Not allowed (default First Available)' },
  none: { value: 1, description: 'Allow Employee Selection' },
  expanded: { value: 2, description: 'Expanded Employee Selection' }
})

export const MinisitePreferencesWaitlistMode = new Enum({
  all: { value: 0, description: 'None' },
  none: { value: 1, description: 'Manually notify customers (internal notification)' },
  expanded: { value: 2, description: 'Automatically notify customers' }
})

export class MinisitePaymentSettings extends APIObject {
  relations () {
    return {
      onlinePaymentDepositRequiredType: { type: MinisitePaymentSettingsOnlinePaymentDepositRequiredType }
    }
  }
}

export const MinisitePaymentSettingsOnlinePaymentDepositRequiredType = new Enum({
  fixed: { value: 1 },
  percentage: { value: 0 }
})

class MinisiteImage extends Model {
  static modelName () {
    return null
  }

  objectID () {
    return this.id
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/settings/' + this.modelName()
  }

  static get () {
    const url = this.modelBaseURL()

    return this.requestItem(Request.get(url), this).then((item) => {
      item.saturated = new Date()

      return item
    })
  }

  static uploadImage (image, type, options) {
    const url = this.modelBaseURL()

    const formData = new FormData()
    formData.append(type, image)

    const request = Request.post(url, formData)
    request.options = options

    return this.requestItem(request, this)
  }

  static deleteImage (type, image) {
    const url = this.modelBaseURL() + '?type=' + type + '&id=' + image?.id || 1
    return this.requestItem(Request.delete(url), this)
  }
}

export class MinisiteImageOnline extends MinisiteImage {
  static modelName () {
    return 'miniSiteOnlineImage'
  }
}

export class MinisiteImageGiftCard extends MinisiteImage {
  static modelName () {
    return 'miniSiteGiftCardImage'
  }
}

export class MinisiteImagePass extends MinisiteImage {
  static modelName () {
    return 'miniSitePassImage'
  }
}

export class MinisiteImageGallery extends MinisiteImage {
  static modelName () {
    return 'miniSiteGalleryImages'
  }
}
