import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import { plugin as $date } from '../../plugins/date'

import Employee from './employee'
import EmployeeHour, { EmployeeHourRosterWeek, EmployeeHourRosterFrequency } from './employeeHour'
import EmployeeBreak from './employeeBreak'

export default class Roster extends Model {
  static modelName () {
    return 'roster'
  }

  relations () {
    return {
      date: { type: DateYMD },
      dates: { type: RosterDate },
      rosters: { type: EmployeeRoster }
    }
  }

  static modelApiVersion () {
    return 2
  }

  static week (date) {
    const url = this.modelBaseURL() + '/week?date=' + date
    return this.requestItem(Request.get(url), Roster)
  }

  static month (date) {
    const url = this.modelBaseURL() + '/month?date=' + date
    return this.requestItem(Request.get(url), Roster)
  }

  static saveDate (idEmployee, date, day) {
    const url = this.modelBaseURL() + '/saveDate?employee=' + idEmployee + '&date=' + date.value
    return this.requestItem(Request.post(url, day.toJSON()), EmployeeRosterCurrentDay)
  }

  static revertDate (idEmployee, date) {
    const url = this.modelBaseURL() + '/revertDate?employee=' + idEmployee + '&date=' + date
    return this.requestItem(Request.get(url), EmployeeRosterCurrentDay)
  }

  static employeeDefault (employee) {
    const url = this.modelBaseURL() + '/employeeDefault?employee=' + employee.idEmployee
    return this.requestItem(Request.get(url), RosterEmployeeDefault)
  }

  static saveEmployeeDefault (employee, employeeDefault) {
    const url = this.modelBaseURL() + '/saveEmployeeDefault?employee=' + employee.idEmployee
    return this.requestSuccess(Request.post(url, employeeDefault.toJSON()))
  }

  static moveBreak (employeeBreak, fromDate, toDate, employee, time, duration, label) {
    const url = this.modelBaseURL() + '/moveBreak'

    const data = {
      break: employeeBreak.toJSON(),
      fromDate, // TODO: check how fromDate is formatted
      toDate, // TODO: check how toDate is formatted
      time,
      duration,
      label,
      employee: employee.toJSON()
    }
    return this.requestItem(Request.put(url, data), EmployeeBreak)
  }

  static newBreak (date, employee, time, duration, label) {
    const url = this.modelBaseURL() + '/newBreak'

    const data = {
      date,
      time,
      duration,
      label,
      employee: employee.toJSON()
    }
    return this.requestItem(Request.put(url, data), EmployeeBreak)
  }

  static deleteBreak (employeeBreak, date) {
    const url = this.modelBaseURL() + '/deleteBreak'

    try {
      const data = {
        date,
        break: employeeBreak.toJSON()
      }
      return this.requestSuccess(Request.put(url, data))
    } catch (error) {
      return error
    }
  }
}

export class RosterEmployeeDefault extends APIObject {
  relations () {
    return {
      employee: { type: Employee },
      frequency: { type: EmployeeHourRosterFrequency },
      weeks: { type: RosterEmployeeDefaultWeek },
      frequencies: { type:  EmployeeRosterFrequencies }
    }
  }

  getWeek (week) {
    for (const defaultWeek in this.weeks) {
      if (defaultWeek.week === week) {
        return defaultWeek
      }
    }
    return null
  }

  get isRosterFrequencyWeekly () {
    return this.frequency === EmployeeHourRosterFrequency.weekly
  }

  get isRosterFrequencyFortnightly () {
    return this.frequency === EmployeeHourRosterFrequency.fortnightly
  }

  get isRosterFrequencyMonthly () {
    return this.frequency === EmployeeHourRosterFrequency.monthly
  }
}

export class EmployeeRosterFrequencies extends APIObject {
  relations () {
    return {
      frequency: { type: EmployeeHourRosterFrequency }
    }
  }
}

export class RosterEmployeeDefaultWeek extends APIObject {
  relations () {
    return {
      week: { type: EmployeeHourRosterWeek },
      days: { type: RosterEmployeeDefaultDay }
    }
  }
}

export class RosterEmployeeDefaultDay extends APIObject {
  relations () {
    return {
      hour: { type: EmployeeHour },
      breaks: { type: EmployeeBreak }
    }
  }
}

export class EmployeeRoster extends APIObject {
  relations () {
    return {
      employee: { type: Employee },
      dates: { type: EmployeeRosterCurrentDay }
    }
  }
}

export class EmployeeRosterCurrentDay extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      hour: { type: EmployeeHour },
      type: { type: EmployeeRosterCurrentDayHourType }
    }
  }

  get isDefaultHours () {
    return this.type === EmployeeRosterCurrentDayHourType.defaultHour
  }

  get isOverrideHours () {
    return this.type === EmployeeRosterCurrentDayHourType.override
  }
}

export class RosterDate extends APIObject {
  relations () {
    return {
      date: { type: DateYMD }
    }
  }

  get weekDayLabel () {
    const date = $date.parse(this.date)
    const day = $date.format(date, 'EEEE')

    return day
  }

  get dayLabel () {
    const date = $date.parse(this.date)
    const day = $date.format(date, 'd')

    return day
  }
}

export const EmployeeRosterCurrentDayHourType = new Enum({
  none: { value: 'none', description: 'None' },
  default: { value: 'default', description: 'Default' },
  override: { value: 'override', description: 'Override' }
})
