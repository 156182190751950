/* eslint-disable no-undef */
import * as API from '~/api'

export default (context, inject) => {
  const events = ['updated', 'created', 'deleted']

  const notification = (model, type) => {
    const item = API[model]

    switch (type) {
      case 'deleted':
        return item.deletedNotificationName()
      case 'created':
        return item.createdNotificationName()
      default:
        return item.updatedNotificationName()
    }
  }

  const plugin = {
    listeners: {},

    emit: {
      ...Object.fromEntries(
        events.map((event) => {
          return [
            event,
            (model, data) => {
              const name = notification(model, event)
              $nuxt.$emit(name, data)
            }
          ]
        })
      )
    },

    on: {
      ...Object.fromEntries(
        events.map((event) => {
          return [
            event,
            (model, uuid, callback) => {
              const name = notification(model, event)
              $nuxt.$on(name, data => callback instanceof Function && callback(data))

              plugin.add(name, model, uuid, callback)
            }
          ]
        })
      ),
      all: (model, uuid, callback) => {
        events.forEach((event) => {
          const name = notification(model, event)
          $nuxt.$on(name, data => callback instanceof Function && callback(data))

          plugin.add(name, model, uuid, callback)
        })
      }
    },

    once: {
      ...Object.fromEntries(
        events.map((event) => {
          return [
            event,
            (model, callback) => {
              const name = notification(model, event)
              $nuxt.$once(name, data => callback(data))
            }
          ]
        })
      )
    },

    off: {
      ...Object.fromEntries(
        events.map((event) => {
          return [
            event,
            (uuid) => {
              const listener = plugin.listeners[uuid]

              if (listener) {
                listener.forEach((listener, index) => {
                  const name = notification(listener.model, event)

                  if (name === listener.name) {
                    const response = $nuxt.$off(name, callback)
                    plugin.listeners[uuid]?.splice(index, 1)
                  }
                })
              }

              if (plugin.listeners[uuid]?.length === 0) {
                delete plugin.listeners[uuid]
              }
            }
          ]
        })
      ),
      all: (uuid) => {
        const listener = plugin.listeners[uuid]

        if (listener) {
          listener.forEach((listener) => {
            const { name, callback } = listener
            const response = $nuxt.$off(name, callback)
          })
        }

        delete plugin.listeners[uuid]
      }
    },

    add (model, name, uuid, callback) {
      if (!plugin.listeners[uuid]) {
        plugin.listeners[uuid] = []
      }

      plugin.listeners[uuid].push({ model, name, uuid, callback })
    }
  }

  inject('bus', plugin)
}
