import Model, { Request } from '../model'
import Enum from '../enums'

export default class Referral extends Model {
  static modelName () {
    return 'referral'
  }

  objectID () {
    return this.idReferral
  }

  relations () {
    return {
      status: { type: ReferralStatus }
    }
  }

  static sendReferral (referrals) {
    const url = this.modelBaseURL() + '/send'
    const data = {
      referrals
    }

    return this.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  get isStatusRequested () {
    return this.status === ReferralStatus.requested
  }

  get isStatusTrial () {
    return this.status === ReferralStatus.trial
  }

  get isStatusSignedUp () {
    return this.status === ReferralStatus.signedUp
  }

  get isStatusValidated () {
    return this.status === ReferralStatus.validated
  }

  get isStatusCancelled () {
    return this.status === ReferralStatus.cancelled
  }

  get isStatusPaid () {
    return this.status === ReferralStatus.paid
  }
}

export const ReferralStatus = new Enum({
  requested: { value: 0, description: 'Invited' },
  trial: { value: 1, description: 'Trial' },
  signedUp: { value: 2, description: 'Signed up!' },
  validated: { value: 4, description: 'Validated!' },
  cancelled: { value: 5, description: 'Cancelled' },
  paid: { value: 3, description: 'Paid!' }
})
