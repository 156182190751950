import Vue from 'vue'
import { ArticleEditor } from '@/static/imperavi/article/js/article-editor.min.js'
import '@/static/imperavi/article/js/definedlinks.js'
import '@/static/imperavi/article/js/variable.min.js'
import '@/static/imperavi/article/js/counter.min.js'
import '@/static/imperavi/article/css/article-editor.css'

Vue.component('ArticleEditor', {
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      article: null
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.destroy()
  },
  methods: {
    destroy () {
      // Call destroy on article to cleanup event handlers
      this.article.disable()
      this.article.stop()

      // unset instance for garbage collection
      this.article = null
      this.$parent.article = null
    },
    handleInput (val) {
      this.$emit('input', val)
    },
    async init () {
      const me = this

      const config = {
        ...this.config,
        css: '/imperavi/article/css/',
        subscribe: {
          'editor.change' (event) {
            const html = event.get('html')
            me.handleInput(html)
            return html
          }
        }
      }

      const app = await ArticleEditor(this.$refs.article, config)

      // set instance
      this.article = app
      this.$parent.article = app
    }
  },
  template: '<textarea ref="article" :name="name" :placeholder="placeholder" :value="value" />'
})
