import localforage from 'localforage'
import debounce from 'lodash/debounce'

import * as API from '~/api'

export const state = () => ({
  employees: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false
  },
  hydrated: false,
  count: null
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  updateEmployees (state, employees) {
    state.employees = Object.freeze(API.Employee.ensure(employees))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.employees
  },
  count (state) {
    return state.employees.length
  },
  getEmployeeByID: state => (idEmployee) => {
    return API.Employee.ensure(state.employees)
      .find(employee => Number(idEmployee) === employee.idEmployee)
  },
  employeesTypeEmployee (state) {
    return API.Employee.ensure(state.employees)
      .filter(employee => employee.isEmployeeType)
  },
  employeesForService: state => (service) => {
    return API.Employee.ensure(state.employees)
      .filter(employee => service?.employeeIDs.includes(employee.idEmployee))
  },
  employeesTypeResource (state) {
    return API.Employee.ensure(state.employees)
      .filter(employee => employee.isResourceType)
  },
  employeesForResource: state => (serviceResource) => {
    return API.Employee.ensure(state.employees)
      .filter(employee => serviceResource.resourceIDs.includes(employee.idEmployee))
  }
}

export const actions = {
  sync: debounce(async function ({ commit, state, rootState }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active

    // Check permission
    if (this.$grants && !this.$grants.all({ employee: ['view'] })) {
      console.log('no employee permissions')
      return
    }

    if (!state.hydrated || change) {
      const employees = await localforage.getItem(guid + '.employees.employees')
      const sync = await localforage.getItem(guid + '.employees.sync')

      if (employees) {
        commit('updateEmployees', employees)
      } else {
        commit('updateEmployees', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true, progress: 0 })

    const sinceDate = (force) ? null : state.sync.date

    const existing = {}

    if (!force) {
      for (let i = 0; i < state.employees.length; ++i) {
        const employees = state.employees[i]
        existing[employees.idEmployee] = employees
      }
    }

    const errors = []

    try {
      await API.Employee.listAll(sinceDate, (page, pageCount, list) => {
        if (list.items) {
          for (let i = 0; i < list.items.length; ++i) {
            const employees = list.items[i]
            existing[employees.idEmployee] = employees
          }
        }

        if (list.deleted) {
          for (let i = 0; i < list.deleted.length; ++i) {
            const id = list.deleted[i]
            delete existing[id]
          }
        }

        commit('setSync', { running: true, progress: (page / pageCount) })
      })
    } catch (e) {
      if (e?.status === 304) {
        console.log('Employee data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      commit('updateEmployees', Object.values(existing))

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.employees.employees', state.employees)
      localforage.setItem(guid + '.employees.sync', state.sync)
    }
  }, 300)
}
