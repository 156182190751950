export class EnumSymbol {
  constructor (name, { value, description }) {
    if (!Object.is(value, undefined)) {
      this.value = value
    }
    if (description) {
      this.description = description
    }

    Object.freeze(this)
  }

  get display () {
    return this.description || this.value
  }
}

export default class Enum {
  constructor (enumLiterals) {
    for (const key in enumLiterals) {
      if (!enumLiterals[key]) {
        throw new TypeError('each enum should have been initialized with atleast empty {} value')
      }
      this[key] = new EnumSymbol(key, enumLiterals[key])
    }
    Object.freeze(this)
  }

  symbolForValue (value) {
    if (value !== null && typeof value === 'object' && value.value !== null) {
      value = value.value
    }

    for (const key of Object.keys(this)) {
      if (this[key].value === value || (value !== null && this[key].value === Number(value))) {
        return this[key]
      }
    }
    return null
  }
}
