<template>
  <div class="w-full h-full flex overflow-hidden relative">
    <div
      class="h-full max-h-full"
      :class="leftClasses"
    >
      <!-- Left -->
      <slot name="left" :state="state" :toggle="toggle" :small="small" />
    </div>
    <div
      :class="[
        'flex-grow flex-shrink w-10',
        rightClass
      ]"
    >
      <!-- Right -->
      <slot name="right" :state="state" :toggle="toggle" :small="small" />

      <transition name="fade">
        <div v-if="small && active" class="absolute inset-0 w-full h-full bg-black bg-opacity-50" @click="toggle" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OSidebar',
  props: {
    mainHeader: {
      type: String,
      default: null
    },
    collapseHeader: {
      type: String,
      default: null
    },
    toggleIcon: {
      type: String,
      default: 'FilterIcon'
    },
    leftClass: {
      type: String,
      default: 'bg-gray-50 dark:bg-gray-900 border-r dark:border-gray-800 z-80'
    },
    rightClass: {
      type: String,
      default: 'bg-white dark:bg-gray-900'
    },
    stored: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      collapse: false,
      show: false,
      width: 0
    }
  },
  computed: {
    _collapse: {
      get () {
        return this.stored ? this.$store.getters.sidebar : this.collapse
      },
      set (value) {
        if (this.stored) {
          this.$store.commit('setSidebar', value)
        } else {
          this.collapse = value
        }
      }
    },
    small () {
      return this.width < 1280
    },
    active () {
      return this.small && this.show
    },
    state () {
      return {
        collapse: this._collapse,
        show: this.show,
        width: this.width,
        small: this.small,
        active: this.active
      }
    },
    hasLeftSlot () {
      return this.$scopedSlots?.left || this.$slots?.left
    },
    leftClasses () {
      const classes = [this.leftClass]

      classes.push(this._collapse ? 'hidden' : 'w-72 xl:w-80')

      if (this.small) {
        classes.push('absolute transform z-50 transition-transform duration-200 shadow md:shadow-none bg-white')
        classes.push(this.active ? 'translate-x-0' : ' -translate-x-2/1')
      }

      if (!this.hasLeftSlot) {
        classes.push('hidden')
      }

      return classes
    }
  },
  watch: {
    small (small) {
      if (small) {
        this._collapse = false
      }
    }
  },
  mounted () {
    this.width = document.body.clientWidth

    window.addEventListener('resize', () => {
      this.width = document.body.clientWidth
    })
  },
  methods: {
    toggle () {
      if (this.small) {
        this.show = !this.show
      } else {
        this._collapse = !this._collapse
      }
    }
  }
}
</script>
