<template>
  <container v-bind="container">
    <div class="h-full flex items-center justify-between">
      <input
        ref="input"
        v-model="currentValue"
        :type="show ? 'text' : 'password'"
        :maxlength="maxlength"
        :minlength="minlength"
        class="h-full w-full bg-transparent"
        v-bind="attributes"
        v-on="handlers"
      >
      <o-button size="sm" transparent :icon="show ? 'passwordShow' : 'passwordHide'" @click="toggleShow" />
    </div>

    <template #leading>
      <slot name="leading" />
    </template>

    <template #trailing>
      <slot name="trailing" />
    </template>
  </container>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import input from '@/mixins/fields/input.js'
import Container from './Container'

export default {
  components: {
    Container
  },
  mixins: [attributes, classes, methods, input],
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
    maxlength: {
      type: [String, Number],
      default: null
    },
    minlength: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    toggleShow () {
      this.show = !this.show
    }
  }
}
</script>
