import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import Customer from './customer'
import Order from './order'
import Reservation from './reservation'

export default class Alert extends Model {
  static modelName () {
    return 'alert'
  }

  objectID () {
    return this.idAlert
  }

  relations () {
    return {
      alertType: { type: AlertType },
      primaryAction: { type: AlertAction },
      secondaryAction: { type: AlertAction }
    }
  }

  static filtered ({ page = 1, filter = null } = {}) {
    let url = this.modelBaseURL() + '/?page=' + page

    if (filter) {
      url += `&filter=${filter}`
    }

    return this.requestList(Request.get(url), Alert)
  }

  static counts () {
    const url = this.modelBaseURL() + '/counts'
    return this.requestItem(Request.get(url), AlertCounts)
  }

  markRead () {
    const url = this.constructor.modelBaseURL() + '/markRead?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  static markAllRead () {
    const url = this.modelBaseURL() + '/markAllRead'
    return this.requestSuccess(Request.get(url))
  }

  static bulkDelete () {
    const url = this.modelBaseURL() + '/bulkDelete'
    return this.requestSuccess(Request.get(url))
  }

  get category () {
    return 'secondary'
  }

  get icon () {
    return 'reservation'
  }
}

export class AlertCounts extends APIObject {
}

export class AlertInstantiatedModelData extends APIObject {
  relations () {
    return {
      customer: { type: Customer },
      reservation: { type: Reservation },
      order: { type: Order }
    }
  }
}

export class AlertAction extends APIObject {
  relations () {
    return {
      data: { type: AlertActionData }
    }
  }
}

export class AlertActionData extends APIObject {
  relations () {
    return {
      date: { type: DateYMD }
    }
  }
}

export const AlertType = new Enum({
  customerNewReservation: { value: 1 },
  customerCancelReservation: { value: 2 },
  customerCheckin: { value: 3 },
  customerOnlinePayment: { value: 4 },
  smsLow: { value: 6 },
  subscriptionPaymentSuccess: { value: 7 },
  subscriptionPaymentFailed: { value: 8 }
})
