<template>
  <div :class="baseClass">
    <o-loader v-if="loading" size="16px" class="opacity-75 ml-2 mr-2" />

    <slot v-if="!loading" name="leading">
      <div v-if="leading" class="pl-1 pr-2 text-gray-600 dark:text-gray-300 leading-none text-base border-r mr-1 border-gray-200 dark:border-gray-800">
        <template v-if="isLeadingIcon">
          <o-icon :icon="leading" :size="14" />
        </template>
        <template v-else>
          <p class="leading-none text-sm">
            {{ leading }}
          </p>
        </template>
      </div>
    </slot>

    <div class="field flex-grow h-full">
      <slot />
    </div>

    <slot name="trailing">
      <div v-if="trailing" class="pr-1 pl-2 text-gray-600 dark:text-gray-300 leading-none text-base border-l ml-2 border-gray-200 dark:border-gray-800">
        <template v-if="isTrailingIcon">
          <o-icon :icon="trailing" :size="14" />
        </template>
        <template v-else>
          <p class="leading-none text-sm">
            {{ trailing }}
          </p>
        </template>
      </div>
    </slot>

    <slot name="control" />
  </div>
</template>

<script>
import { icons } from '@/components/elements/Icon.vue'

export default {
  props: {
    baseClass: {
      type: [String, Array],
      default: 'w-full relative flex items-center h-9 text-gray-800 dark:text-white text-sm'
    },
    leading: {
      type: [String, Array],
      default: null
    },
    trailing: {
      type: [String, Array],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLeadingIcon () {
      return !!icons[this.leading]
    },
    isTrailingIcon () {
      return !!icons[this.trailing]
    }
  }
}
</script>

<style scoped>
  field > * {
    width: 100%;
    height: 100%;
    background: transparent
  }
</style>
