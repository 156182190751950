import Vue from 'vue'
import OModal from './Modal'

export default {
  create (message, { title, confirm, cancel, icon, variant, container } = {}) {
    const ModalComponent = Vue.extend(OModal)
    const Modal = new ModalComponent({
      propsData: {
        message,
        title,
        confirm,
        cancel,
        icon,
        variant,
        container
      }
    }).$mount()

    Modal.$on('close', () => {
      this.destroy(Modal)
    })

    document.body.appendChild(Modal.$el)
    return Modal.open()
  },
  destroy (modal) {
    modal.$destroy()
    modal.$el.remove()
  },
  alert (message, options = { }) {
    const { title, variant, icon, confirm, container } = options

    return this.create(message, { title, confirm, variant, icon, container })
  },
  confirm (message, options = { }) {
    const { title, variant, icon, confirm, container } = options
    const cancel = Object.assign({ cancel: true }, options.cancel)

    return this.create(message, { title, cancel, confirm, variant, icon, container })
  }
}
