import Model, { Request } from '../model'

export default class AdvancedSettings extends Model {
  static modelName () {
    return 'advancedSettings'
  }

  static inventoryReset () {
    const url = this.modelBaseURL() + '/inventoryReset'
    return this.requestSuccess(Request.get(url))
  }

  static loyaltyReset () {
    const url = this.modelBaseURL() + '/loyaltyReset'
    return this.requestSuccess(Request.get(url))
  }
}
