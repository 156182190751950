const Watcher = {
  isValid (value) {
    return (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      !value.isProxy &&
      typeof value === 'object' &&
      !Array.isArray(value)
    )
  },
  hasKey (watch, key) {
    return watch && watch.includes(key)
  },
  create (value, watch, callback, key) {
    if (this.isValid(value) && (!key || this.hasKey(watch, key))) {
      return new Proxy(value, this.handler(watch, callback))
    }

    return value
  },
  handler (watch, callback) {
    return {
      get (target, key) {
        return Watcher.create(target[key], watch, callback, key)
      },
      set (target, key, value) {
        target[key] = value
        callback()
        return true
      }
    }
  }
}

export default Watcher
