import Vue from 'vue'

import Dialog from '../components/elements/dialog/Dialog.vue'
import DialogPlugin from '../components/elements/dialog/index.js'

export default (context, inject) => {
  inject('dialog', DialogPlugin)
}

Vue.component(Dialog.name, Dialog)
