// import colors from 'tailwindcss/colors'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/tailwind.config.js'



export default {
  head () {
    const config = resolveConfig(tailwindConfig)
    const colors = config.theme.colors
    
    const theme = this.$auth.theme

    const lightPrimary = colors[theme.light.primary]
    const lightGray = colors[theme.light.gray]

    const darkPrimary = colors[theme.dark.primary]
    const darkGray = colors[theme.dark.gray]

    const styles = `:root {
      ${Object.entries(lightPrimary || colors.green).map(([key, value]) => `--color-primary-${key}: ${this.hexToRgb(value)};`).join('\n')}
      --color-primary-DEFAULT: var(--color-primary-500);

      ${Object.entries(lightGray || colors.cool).map(([key, value]) => `--color-gray-${key}: ${this.hexToRgb(value)};`).join('\n')}
      --color-gray-DEFAULT: var(--color-primary-200);
      --color-border-DEFAULT: var(--color-gray-200);
      }

      .dark {
        ${Object.entries(darkPrimary || colors.green).map(([key, value]) => `--color-primary-${key}: ${this.hexToRgb(value)};`).join('\n')}
        --color-primary-DEFAULT: var(--color-primary-400);

        ${Object.entries(darkGray || colors.cool).map(([key, value]) => `--color-gray-${key}: ${this.hexToRgb(value)};`).join('\n')}
        --color-gray-DEFAULT: var(--color-primary-800);
        --color-border-DEFAULT: var(--color-primary-800);
      }
      `

    return {
      title: this.$auth.info ? this.$auth.info.location.title + ' - Ovatu Manager' : 'Ovatu Manager',
      style: [
        {
          hid: 'root-styles',
          innerHTML: styles,
          type: 'text/css'
        }
      ]
    }
  },
  methods: {
    hexToRgb (hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      hex = hex.replace(shorthandRegex, function (_, r, g, b) {
        return r + r + g + g + b + b
      })

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}`
        : null
    }
  }
}