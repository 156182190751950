import Model from '../model'

import Employee from './employee'

export default class ResourceType extends Model {
  static modelName () {
    return 'resourceType'
  }

  objectID () {
    return this.idResourceType
  }

  relations () {
    return {
      resources: { type: Employee }
    }
  }
}
