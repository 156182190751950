import Model from '../model'
import Enum from '../enums'

export default class CustomerNote extends Model {
  static modelName () {
    return 'customerNote'
  }

  objectID () {
    return this.idCustomerNote
  }

  relations () {
    return {
      type: { type: CustomerNoteType }
    }
  }

  get isTypeNote () {
    return this.type === CustomerNoteType.note
  }

  get isTypeForumla () {
    return this.type === CustomerNoteType.forumla
  }

  get isTypeAlert () {
    return this.type === CustomerNoteType.alert
  }
}

export const CustomerNoteType = new Enum({
  note: { value: 0, description: 'Note' },
  formula: { value: 1, description: 'Formula' },
  alert: { value: 2, description: 'Alert' }
})
