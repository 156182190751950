<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      children: []
    }
  },
  computed: {
    count () {
      return this.steps.length
    }
  },
  watch: {
    progress: {
      handler (value) {
        this.setState(value)
      }
    }
  },
  created () {
    this.children = this.$children
  },
  mounted () {
    this.setState(this.progress)
  },
  methods: {
    setState (progress) {
      const children = this.$children

      for (let index in children) {
        const step = children[index]
        index = Number(index) + 1

        if (index === progress) {
          step.state = 'active'
        } else if (index < progress) {
          step.state = 'complete'
        } else {
          step.state = 'disabled'
        }
      }
    }
  }
}
</script>
