<template>
  <div :class="containerClass">
    <div class="text-gray-800 hidden md:block text-sm dark:text-gray-100">
      Showing <span class="font-medium">{{ counter.from }}</span> to <span class="font-medium">{{ counter.to }}</span> of <span class="font-medium">{{ counter.total }}</span>
    </div>

    <div class="flex space-x-2">
      <o-button
        :disabled="page === 1"
        class="px-1"
        size="sm"
        icon="chevronLeftDouble"
        variant="success"
        flat
        @click="page = 1"
      />
      <o-button
        :disabled="page === 1"
        class="px-1"
        size="sm"
        icon="chevronLeft"
        variant="success"
        flat
        @click="page--"
      />

      <template v-for="item in pages">
        <o-button
          v-if="page - 3 < item && item < page + 3"
          :key="item"
          class="w-7"
          size="sm"
          variant="success"
          :flat="page !== item"
          @click="page = item"
        >
          {{ item }}
        </o-button>
      </template>

      <o-button
        :disabled="page === pages"
        class="px-1"
        size="sm"
        icon="chevronRight"
        variant="success"
        flat
        @click="page++"
      />
      <o-button
        :disabled="page === pages"
        class="px-1"
        size="sm"
        icon="chevronRightDouble"
        variant="success"
        flat
        @click="page = pages"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pager: {
      type: Object,
      required: true
    },
    containerClass: {
      type: String,
      default: 'py-2 border-t flex justify-between items-center flex-shrink-0 px-4'
    }
  },
  data () {
    return {
      page: 1
    }
  },
  computed: {
    pages () {
      return this.pager.pages
    },
    counter () {
      const { page, initialItems, total } = this.pager

      const position = (page * initialItems) - initialItems

      const from = position + 1
      const to = position + initialItems > total ? total : position + initialItems

      return {
        from,
        to,
        total
      }
    }
  },
  watch: {
    async page (page) {
      await this.pager.load(page)
    }
  },
  mounted () {
    this.page = this.pager.page
  }
}
</script>
