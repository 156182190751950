import Model, { Request } from '../model'
import Enum from '../enums'

export default class CustomField extends Model {
  static modelName () {
    return 'customField'
  }

  objectID () {
    return this.idCustomField
  }

  relations () {
    return {
      dataType: { type: CustomFieldDataType },
      modelType: { type: CustomFieldModelType }
    }
  }

  editRow () {
    // TODO
    return false
  }

  populateValue (fromForm) {
    // TODO
    return false
  }

  hasChanged (fromForm) {
    // TODO
    return false
  }

  delete (showPastData) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '?showPastData=' + (showPastData ? 1 : 0)
    return this.constructor.requestSuccess(Request.delete(url))
  }

  get isDataTypeText () {
    return this.dataType === CustomFieldDataType.text
  }

  get isDataTypeLongText () {
    return this.dataType === CustomFieldDataType.longtext
  }

  get isDataTypeMultiple () {
    return this.dataType === CustomFieldDataType.multiple
  }

  get isDataTypeBool () {
    return this.dataType === CustomFieldDataType.bool
  }

  get isDataTypeNumber () {
    return this.dataType === CustomFieldDataType.number
  }

  get isDataTypeDate () {
    return this.dataType === CustomFieldDataType.date
  }
}

export const CustomFieldModelType = new Enum({
  customer: {
    value: 0,
    description: 'Customer'
  },
  reservation: {
    value: 1,
    description: 'Appointment'
  },
  order: {
    value: 2,
    description: 'Sale'
  },
  employee: {
    value: 3,
    description: 'Employee'
  },
  service: {
    value: 4,
    description: 'Service'
  },
  product: {
    value: 5,
    description: 'Product'
  },
  reservationCustomer: {
    value: 6,
    description: 'Group customer'
  }
})

export const CustomFieldDataType = new Enum({
  text: {
    value: 0,
    description: 'Text'
  },
  longtext: {
    value: 1,
    description: 'Long Text'
  },
  multiple: {
    value: 2,
    description: 'Multiple values'
  },
  bool: {
    value: 3,
    description: 'Yes / No'
  },
  number: {
    value: 4,
    description: 'Number'
  },
  date: {
    value: 5,
    description: 'Date'
  }
})
