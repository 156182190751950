<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        class="fixed flex items-end justify-center inset-0 h-full w-full z-80000 p-4  text-gray-900 dark:text-white"
        :class="[
          $auth.theme.light.gradient ? `${$auth.theme.light.gradient} dark:bg-gray-900` : `bg-primary-900 dark:bg-gray-900`,
        ]"
      >

        <div
          :class="[
            'inset-0 h-full w-full bg-cover bg-center absolute bg-no-repeat',
            $auth.theme.light.gradient ? `bg-black/5 dark:bg-gray-950/10` : `bg-primary-950/20 dark:bg-gray-950/10`,
          ]"
          :style="mask"
        />

        <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <svg aria-hidden="true" class="mx-auto h-10 w-10 animate-spin fill-primary-600 text-gray-200 dark:text-gray-600 mb-6" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>

          <div v-if="message" class="text-center">
            <h4 class="text-white/90 font-semibold uppercase tracking-wide mb-1">
              {{ message.title }}
            </h4>
            <p class="text-white/70 text-base" v-html="message.info" />
          </div>
        </div>
        <div>
          <h4 class="text-white/80 uppercase tracking-wide mb-6 text-sm text-center">
            <template v-for="(model, key) in syncing">
              <span v-if="model.state" :key="key">
                Syncing {{ model.label }}
              </span>
            </template>
          </h4>

          <img src="~/assets/images/logo/logo-white-full.png" class="h-10 opacity-75 mx-auto mb-2">
          <p class="text-white/60 text-xs text-center" @click="$auth.syncActive()">
            © 2023 Ovatu Pty Ltd ABN 92 159 394 484 ATF Ovatu Trading Trust ABN 74 504 757 106 | OVATU is a registered trademark of Ovatu Pty Ltd.
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      message: null,
      interval: null,
      messages: [
        { title: 'Did you know?', info: 'You can press CTRL + S from anywhere in the app to quickly access search' },
        { title: 'Did you know?', info: 'You can create custom permission roles in your account settings' },
        { title: 'Did you know?', info: 'It\'s impossible for most people to lick their elbows.' },
        { title: 'Did you know?', info: 'Ovatu is proud to be 100% carbon neutral' },
        { title: 'Did you know?', info: 'For every new customer Ovatu plants a tree' },
        { title: 'Did you know?', info: 'You can use our widgets to add book.app to your own website' },
        { title: 'Just a sec...', info: 'We are getting your data ready' },
        { title: 'Loading...', info: 'Reticulating splines...' },
        { title: 'Loading...', info: 'You are number 2843684714 in the queue' },
        { title: 'Loading...', info: 'Despite the constant negative press covfefe' },
        { title: 'Loading...', info: 'Does anyone actually read these?' },
        { title: 'Loading...', info: 'Some people are like clouds. When they go away, it\'s a brighter day.' },
        { title: 'Have a question?', info: 'Our support team are always ready to help!' },
        { title: 'Gettings things ready...', info: 'Thanks for using Ovatu! we appreciate it' }
      ]
    }
  },
  computed: {
    loading () {
      return !this.$auth.ready
    },
    ...mapGetters({
      customer: 'customer/isSyncing',
      service: 'service/isSyncing',
      employee: 'employee/isSyncing',
      segment: 'segment/isSyncing',
      product: 'product/isSyncing',
      integration: 'integration/isSyncing',
      form: 'form/isSyncing',
      customFields: 'customFields/isSyncing',
      discountModel: 'discountModel/isSyncing',
      chargeModel: 'chargeModel/isSyncing',
      paymentModel: 'paymentModel/isSyncing',
      tax: 'tax/isSyncing'
    }),
    syncing () {
      return {
        customer: { label: 'Customers', state: this.customer },
        service: { label: 'Services', state: this.service },
        employee: { label: 'Employees', state: this.employee },
        segment: { label: 'Segments', state: this.segment },
        product: { label: 'Products', state: this.product },
        integration: { label: 'Integrations', state: this.integration },
        form: { label: 'Forms', state: this.form },
        customFields: { label: 'Custom fields', state: this.customFields },
        discountModel: { label: 'Custom fields', state: this.discountModel },
        chargeModel: { label: 'Custom fields', state: this.chargeModel },
        paymentModel: { label: 'Custom fields', state: this.paymentModel },
        tax: { label: 'Custom fields', state: this.tax }
      }
    },
    mask () {
      const imgUrl = '/images/illustrations/illustration-green.webp'

      return {
        '-webkit-mask-image': `url('${imgUrl}')`,
        'mask-image': `url('${imgUrl}')`,
        // '-webkit-mask-size': '130%',
        // 'mask-size': '130%',
        'webkit-mask-position': 'center',
        'mask-position': 'center',
        '-webkit-mask-size': 'cover',
        'mask-size': 'cover',

      }
    }
  },
  watch: {
    loading: {
      handler () {
        if (this.loading) {
          this.setInterval()
        } else {
          clearInterval(this.interval)
        }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    setInterval () {
      if (!this.interval) {
        const interval = setInterval(() => {
          this.setMessage()

          if (!this.loading) {
            clearInterval(this.interval)
          }
        }, 8000)

        this.interval = interval
      }
    },
    setMessage () {
      const messages = this.messages
      const message = messages[Math.floor(Math.random() * messages.length)]

      this.message = message
    }
  }
}
</script>
