import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

import EmployeeBreak from './employeeBreak'
import Reservation from './reservation'
import ReservationService from './reservationService'
import ServiceResource from './serviceResource'

export default class OverviewBlock extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      type: { type: OverviewBlockType },
      employeeFunction: { type: OverviewBlockEmployeeFunction },
      employeeBreak: { type: EmployeeBreak },
      reservation: { type: Reservation },
      reservationService: { type: ReservationService },
      serviceResources: { type: ServiceResource }
    }
  }
}

export const OverviewBlockEmployeeFunction = new Enum({
  0: { value: 0, description: 'Not sure' },
  primary: { value: 1, description: 'Primary' },
  resource: { value: 2, description: 'Resource' }
})

export const OverviewBlockType = new Enum({
  single: { value: 0, description: 'Single' },
  singleFinishing: { value: 1, description: 'SingleFinishing' },
  multi: { value: 2, description: 'Multi' },
  multiFinishing: { value: 3, description: 'MultiFinishing' }
  // I thought I saw a 5 too but haven't been able to find it again - ?
})

