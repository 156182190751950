var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'inline-flex h-full': _vm.$slots.default },on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_c('div',{staticClass:"contents",attrs:{"id":_vm.uuid + '-contents'},on:{"click":_vm.handleClick,"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenu.apply(null, arguments)}}},[_vm._t("default",function(){return [(!_vm.anchor)?[_c('div',{staticClass:"flex items-center"},[(_vm.split)?_c('o-button',{attrs:{"flat":_vm.flat,"outline":_vm.outline,"variant":_vm.variant,"icon":_vm.icon,"icon-size":12,"rounded":"rounded-l-lg mr-px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('click')}}},[_vm._v("\n            "+_vm._s(_vm.label)+"\n          ")]):_vm._e(),_vm._v(" "),_c('o-button',{ref:"button",attrs:{"flat":_vm.flat,"outline":_vm.outline,"variant":_vm.variant,"rounded":_vm.split ? 'rounded-r-lg' : 'rounded-lg',"icon":"chevronDown","icon-after":"","icon-size":12}},[(!_vm.split)?[_vm._v("\n              "+_vm._s(_vm.label)+"\n            ")]:_vm._e()],2)],1)]:_vm._e()]},null,_vm.props)],2),_vm._v(" "),_c(_vm.portal ? 'portal' : 'div',{tag:"component",attrs:{"to":"dialog-container"}},[_c('transition',{attrs:{"name":"fade","appear":""}},[((_vm.show && _vm.clickaway) || (_vm.show && _vm.isFixed))?_c('div',{class:[
          'fixed inset-0 z-80000',
          { 'bg-black bg-opacity-10': _vm.overlay || _vm.isFixed }
        ],attrs:{"id":_vm.uuid + '-backdrop'},on:{"click":function($event){return _vm.close()}}}):_vm._e()]),_vm._v(" "),_c('dropdown-provider',_vm._b({key:_vm.uuid + '-provider',attrs:{"id":_vm.uuid + '-provider'}},'dropdown-provider',_vm.props,false),[_c('div',{ref:_vm.uuid,class:[
          'o-dropdown absolute z-80000',
          _vm.isFixed ? 'shadow' : 'filter drop-shadow-md',
          _vm.containerClass,
          { 'backdrop-filter backdrop-blur': _vm.translucent }
        ],attrs:{"id":_vm.uuid + '-dropdown'}},[_c('transition',{attrs:{"name":_vm.transition}},[(_vm.show)?_c('div',{class:_vm.dropdownClass},[_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"absolute h-full w-full inset-0 bg-white bg-opacity-25 backdrop-blur z-80000 dark:bg-gray-900"},[_c('div',{staticClass:"h-full w-full flex items-center justify-center"},[_c('o-loader')],1)]):_vm._e()]),_vm._v(" "),_vm._t("content",function(){return [_c('div',{staticClass:"flex flex-col divide-y opacity-80"},_vm._l((_vm.items),function(item,itemIndex){return _c('dropdown-item',_vm._g({key:`item-${itemIndex}`,attrs:{"name":item.name,"description":item.description,"icon":item.icon}},item.events))}),1)]},null,_vm.props)],2):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show && _vm.arrow && !_vm.isFixed),expression:"show && arrow && !isFixed"}],ref:"arrow",class:[
              'bg-white dark:bg-gray-900',
              { 'opacity-50': _vm.translucent }
            ],attrs:{"data-popper-arrow":""}})])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }