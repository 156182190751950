import Model from '../model'
import Enum from '../enums'

import { plugin as $date } from '../../plugins/date'

import { ReservationResource } from './reservationCustomer'
import { ServiceResourceDefaultSelection } from './serviceResource'

import Booking from './booking'
import CustomerPass from './customerPass'
import Employee from './employee'
import Service from './service'
import Voucher from './voucher'


export default class ReservationService extends Model {
  static modelName () {
    return 'reservationService'
  }

  objectID () {
    return this.idReservationService
  }

  relations () {
    return {
      bufferTimeMode: { type: ReservationBufferTimeMode },
      customerPass: { type: CustomerPass },
      employee: { type: Employee },
      resources: { type: ReservationResource },
      service: { type: Service },
      voucher: { type: Voucher },
      discountType: { type: ReservationServiceDiscountType }
    }
  }

  setValues (presets = {}) {
    const service = this.service || {}
    const employee = this.hasEmployee ? this.employee.getEmployeeServiceForService(service) : {}

    const defaults = {
      price: 0,
      startTime: 0,
      duration: 0,
      finishing: 0,
      processing: 0,
      bufferTimePre: 0,
      bufferTimePost: 0,
      allowInternet: true,
      customerMax: 1
    }

    for (const key in defaults) {
      if (presets[key] != null) {
        defaults[key] = presets[key]
      } else if (employee?.[key] != null) {
        defaults[key] = employee?.[key]
      } else if (service?.[key] != null) {
        defaults[key] = service?.[key]
      }
    }

    Object.assign(this, defaults)
  }

  setResources (assignment, existingResources) {
    const service = this.service
    const resourcesForAssignment = service?.resources ? service.resourcesForAssignment(assignment) : []

    const items = []
    const usedResources = []

    for (const serviceResource of resourcesForAssignment) {
      const existing = existingResources
        ?.filter(resource => resource.serviceResource.idServiceResource === serviceResource.idServiceResource)
        .map(resource => resource.resource)

      for (let i = 0; i < serviceResource.quantity; i++) {
        const reservationResource = new ReservationResource({ serviceResource })
        reservationResource.count = i

        if (existingResources !== undefined) {
          const available = existing
            .filter(r => !usedResources.includes(r.idEmployee))

          reservationResource.resource = available[0] || ServiceResourceDefaultSelection.none.value
          usedResources.push(reservationResource.resource.idEmployee)
        } else if (serviceResource.defaultSelection === ServiceResourceDefaultSelection.firstAvailable) {
          reservationResource.resource = ServiceResourceDefaultSelection.firstAvailable.value
        } else {
          reservationResource.resource = ServiceResourceDefaultSelection.none.value
        }

        items.push(reservationResource)
      }
    }

    this.resources = items
  }

  get defaults () {
    const service = this.service || {}
    const employee = this.hasEmployee && this.isFirstAvailable ? this.employee.getEmployeeServiceForService(service) : {}

    const defaults = {
      price: 0,
      startTime: 0,
      duration: 0,
      finishing: 0,
      processing: 0,
      bufferTimePre: 0,
      bufferTimePost: 0,
      allowInternet: true,
      customerMax: 1
    }

    for (const key in defaults) {
      defaults[key] = employee[key] || service[key] || defaults[key]
    }

    return defaults
  }

  get image () {
    if (this.hasImage) {
      return this.images.square
    }

    return null
  }

  get initials () {
    return this.title.charAt(0).toUpperCase()
  }

  get colour () {
    if (this.hasService) {
      return '#' + this.service.borderHexColour
    }

    return '#bbbbbb'
  }

  get startLabel () {
    return $date.minutes(this.startTime)
  }

  get finishLabel () {
    const startTime = Number(this.startTime)
    const duration = Number(this.duration)
    return $date.minutes(startTime + duration)
  }

  get durationLabel () {
    if (this.hasService) {
      return this.duration + ' mins'
    }

    return '-'
  }

  get discountTotal () {
    const discount = this.discount

    if (discount == null) { return 0 }

    switch (this.discountType) {
      case ReservationServiceDiscountType.fixed:
        return discount
      case ReservationServiceDiscountType.percentage:
        return this.price * (discount / 100)
    }
  }

  get discountedPriceLabel () {
    return this.price - this.discountTotal
  }

  get hasService () {
    return !!this.service
  }

  get hasEmployee () {
    return !!this.employee
  }

  get hasResources () {
    return this.resources?.length
  }

  get isFirstAvailable () {
    return this.employee === 0
  }

  get hasPreBuffer () {
    return this.bufferTimePre !== null
  }

  get hasPostBuffer () {
    return this.bufferTimePost !== null
  }

  get hasStartTime () {
    return this.startTime !== 0
  }

  get isServiceCustomerTypeSingle () {
    return this.service?.isServiceCustomerTypeSingle
  }

  get isPriceModified () {
    return this.hasService && this.defaults && this.price !== this.defaults.price
  }

  get isTimingModified () {
    return this.hasService && this.defaults && (this.isDurationModified || this.isProcessingModified || this.isFinishingModified || this.isPreBufferModified || this.isPostBufferModified)
  }

  get isDurationModified () {
    return this.hasService && this.defaults && this.duration !== this.defaults.duration
  }

  get isProcessingModified () {
    return this.hasService && this.defaults && this.processing !== this.defaults.processing
  }

  get isFinishingModified () {
    return this.hasService && this.defaults && this.finishing !== this.defaults.finishing
  }

  get isPreBufferModified () {
    return this.hasService && this.defaults && this.bufferTimePre !== this.defaults.bufferTimePre
  }

  get isPostBufferModified () {
    return this.hasService && this.defaults && this.bufferTimePost !== this.defaults.bufferTimePost
  }

  get isDiscountTypeFixed () {
    return this.discountType === ReservationServiceDiscountType.fixed
  }

  get isDiscountTypePercentage () {
    return this.discountType === ReservationServiceDiscountType.percentage
  }
}

export const ReservationBufferTimeMode = new Enum({
  reservationAggregate: { value: 0 },
  reservationLongest: { value: 1 },
  interService: { value: 2 },
  employeeAggregate: { value: 3 },
  employeeLongest: { value: 4 }
})

export const ReservationServiceDiscountType = new Enum({
  fixed: { value: 0, description: 'Fixed' },
  percentage: { value: 1, description: 'Percentage' }
})
