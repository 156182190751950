import Model, { Request } from '../model'
import Enum from '../enums'
import FormItem from './formItem'
import ServiceForm from './serviceForm'

export default class Form extends Model {
  static modelName () {
    return 'form'
  }

  objectID () {
    return this.idForm
  }

  relations () {
    return {
      items: { type: FormItem },
      permission: { type: FormPermission },
      services: { type: ServiceForm }
    }
  }

  save (images = {}) {
    const formData = new FormData()

    formData.append('_request', JSON.stringify(this.requestJSON()))
    formData.append('_method', this.isNewRecord ? 'POST' : 'PUT')

    for (const key in images) {
      formData.append(key, images[key])
    }

    const url = this.constructor.modelBaseURL() + (this.isNewRecord ? '' : '/' + this.objectID())
    const request = Request.jsonPost(url, formData)

    const that = this

    return this.constructor.requestItem(request, this.constructor).catch((err) => {
      throw err
    }).then((item) => {
      that.saturated = new Date()

      for (const key in item) {
        that[key] = item[key]
      }

      return that
    })
  }

  /* editItem (item) {
    const url = this.constructor.modelBaseURL() + '/editItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addItem (item) {
    const url = this.constructor.modelBaseURL() + '/addItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  } */

  editItem (data, image) {
    const url = this.constructor.modelBaseURL() + '/editItem?id=' + this.objectID()

    const formData = new FormData()
    formData.append('_request', JSON.stringify(data))

    if (image) {
      formData.append('image', image)
    }

    const request = Request.post(url, formData)

    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addItem (data, image) {
    const url = this.constructor.modelBaseURL() + '/addItem?id=' + this.objectID()

    const formData = new FormData()
    formData.append('_request', JSON.stringify(data))

    if (image) {
      formData.append('image', image)
    }

    const request = Request.post(url, formData)

    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deleteItem (item) {
    const url = this.constructor.modelBaseURL() + '/deleteItem?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, item.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  duplicate () {
    const url = this.constructor.modelBaseURL() + '/duplicate?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url), this.constructor)
  }

  static stamps () {
    const url = this.modelBaseURL() + '/stamps'
    return this.requestList(Request.get(url), AnnotatedStamp)
  }

  static colours () {
    const url = this.modelBaseURL() + '/colours'
    return this.requestList(Request.get(url), AnnotatedColour)
  }

  static annotatedOptions () {
    const url = this.modelBaseURL() + '/annotatedOptions'
    return this.requestList(Request.get(url), AnnotatedOptions)
  }
}

export class AnnotatedOptions extends Model {
  static modelName () {
    return 'AnnotatedOptions'
  }

  objectID () {
    return this.code
  }

  relations () {
    return {
      stamps: { type: AnnotatedStamp },
      colours: { type: AnnotatedColour },
      backgrounds: { type: AnnotatedBackground }
    }
  }
}


export class AnnotatedStamp extends Model {
  static modelName () {
    return 'AnnotatedStamp'
  }

  objectID () {
    return this.code
  }

  relations () {
    return {

    }
  }
}

export class AnnotatedColour extends Model {
  static modelName () {
    return 'AnnotatedColour'
  }

  objectID () {
    return this.code
  }

  relations () {
    return {

    }
  }
}

export class AnnotatedBackground extends Model {
  static modelName () {
    return 'AnnotatedBackground'
  }

  objectID () {
    return this.code
  }

  relations () {
    return {

    }
  }
}

export const FormPermission = new Enum({
  open: { value: 0, description: 'Open' },
  secure: { value: 1, description: 'Secure' }
})
