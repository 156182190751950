import Vue from 'vue'

import axios from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'
import asyncPool from 'tiny-async-pool'

import APIObject from './object'
import Boxer from './boxer'

axiosRetry(axios, {
  retries: 3,
  retryCondition (error) {
    return error?.response?.status !== 503 && isNetworkOrIdempotentRequestError(error)
  }
})

export default class Model extends APIObject {
  static modelName () {
    return null
  }

  static apiHostURL () {
    const defaultPort = process.env.PORT || 51088
    const defaultHost = 'localhost'

    return process.browser ? '' : `http://${defaultHost}:${defaultPort}`
  }

  static apiBaseURL () {
    return this.apiHostURL() + '/api'
  }

  static modelBaseURL () {
    return this.apiBaseURL() + '/v' + this.modelApiVersion() + '/' + this.modelName()
  }

  static modelApiVersion () {
    return 1
  }

  static createdNotificationName () {
    return this.modelName() + 'Created'
  }

  static updatedNotificationName () {
    return this.modelName() + 'Updated'
  }

  static deletedNotificationName () {
    return this.modelName() + 'Deleted'
  }

  static axios () {
    return axios.create({})
  }

  static validateStatus (status) {
    return (status >= 200 && status < 300) || status === 400
  }

  static async request (request) {
    const options = {
      method: request.method,
      url: request.url,
      data: request.data,
      responseType: request.responseType,
      headers: request.headers,
      validateStatus: this.validateStatus,
      ...request.options
    }

    options.headers = request.headers || {}

    if (request.authenticated && process.server && this.serverRequest && this.serverRequest.headers.cookie) {
      options.headers.Cookie = this.serverRequest.headers.cookie
    }

    try {
      const response = await axios(options)
      return response
    } catch (error) {
      const exception = new APIException(error)

      switch (exception.code) {
        // Authentication Error
        case 401:
          console.log('401')

          if (exception.logout) {
            if (Vue.prototype.authPlugin?.active) {
              Vue.notification.toast({
                title: 'Authentication Error',
                text: 'Your account details have timed out. Please log back in.',
                variant: 'danger'
              })
            }

            Vue.prototype.authPlugin.logout({ revokeTokens: false })
          }

          throw exception

        // Subscription Error
        case 402:
          console.log('402')

          throw exception // break

        // Permission Error
        case 403:
          console.log('403')

          Vue.notification.toast({
            title: 'Permission Error',
            text: 'You are not authorized to perform this action',
            variant: 'danger'
          })

          break

        // Maintenance
        case 503:
          console.log('503')

          Vue.prototype.authPlugin?.setMaintenance(exception.data)

          throw exception

        default:
          throw exception
      }
    }
  }

  static async requestSuccess (request) {
    const response = await this.request(request)

    if (response.data.success) {
      return response.data.success
    } else {
      throw response.data
    }
  }

  static async requestAuth (request) {
    const response = await this.request(request)

    if (!response.data.errors) {
      return response
    } else {
      throw response.data
    }
  }

  static async requestData (request) {
    const response = await this.request(request)

    if (!response.data.errors) {
      return response.data.data
    } else {
      throw response.data
    }
  }

  static async requestItem (request, type) {
    const response = await this.request(request)

    // load up with data from service
    if (response?.data.success) {
      return new type(response.data.data)
    } else {
      throw response?.data
    }
  }

  static async requestList (request, type) {
    const response = await this.request(request)

    if (response.data.success) {
      return new APIList(response.data, type)
    } else {
      throw response.data
    }
  }

  // should return a promise with this model type
  static defaults () {
    const url = this.modelBaseURL() + '/new'

    return this.requestItem(Request.get(url), this)
  }

  // should return a promise with this model type
  static get (id) {
    const url = this.modelBaseURL() + '/' + id

    return this.requestItem(Request.get(url), this).then((item) => {
      item.saturated = new Date()

      return item
    })
  }

  saturate () {
    const that = this

    return this.constructor.get(this.objectID()).then((item) => {
      // update this object with response
      that.saturated = new Date()

      for (const key in item) {
        that[key] = item[key]
      }

      return that
    })
  }

  // should return a Promise with APIList class
  static list (page = 1, modifiedSince = null) {
    let url = this.modelBaseURL() + '/?page=' + page

    const headers = {}
    if (modifiedSince) {
      url += '&modifiedSince=' + parseInt(modifiedSince.getTime() / 1000)
      headers['If-Modified-Since'] = modifiedSince.toUTCString()
    }

    return this.requestList(Request.get(url), this)
  }

  // should return a promise with array of this model type
  static listAll (modifiedSince, update, runner) {
    // use a default runner (to call the list method)
    const that = this
    const _runner = runner || ((page) => {
      return that.list(page, modifiedSince)
    })

    return this.listRunner(_runner, update)
  }

  static async listRunner (runner, update) {
    // store the response for each list run
    const pageResponses = {}

    // use the runner to fetch the initial list (page 1) and store it
    const list = await runner(1)
    pageResponses[1] = list

    // if an update callback supplied, run it with the initial list
    if (update) {
      const page = (list.paging && list.paging.page) ? list.paging.page : 1
      const pageCount = (list.paging && list.paging.pageCount) ? list.paging.pageCount : 1
      update(page, pageCount, list)
    }

    // if no paging info exists or we are on the last (and only) page, return items.
    if (!(list.paging && !list.paging.isLastPage)) {
      return (list.items) ? list.items : []
    }

    const pageCount = list.paging.pageCount

    // create an array with remaining pages.
    const pages = []
    for (let i = 2; i <= pageCount; i++) {
      pages.push(i)
    }

    /*
      Iterate through all remaining pages using async request buffer pool
      Limit requests to 3 concurrent requests
      Collect any errors and throw at end of loop
    */
    const results = []
    const errors = []
    const concurrency = 3

    const iterator = async (page) => {
      try {
        const runnerLoopList = await runner(page)
        pageResponses[page] = runnerLoopList
        if (update) {
          update(Object.keys(pageResponses).length, pageCount, runnerLoopList)
        }

        return runnerLoopList.items
      } catch (e) {
        errors.push(e)
      }
    }

    const pool = asyncPool(concurrency, pages, iterator)

    for await (const value of pool) {
      results.push(value)
    }

    if (errors.length) {
      console.log(errors)
      throw new Error('Unable to load data')
    }

    const items = [].concat.apply(list.items, results)

    return items
  }

  save () {
    const wasNewRecord = this.isNewRecord

    let url
    let request

    if (wasNewRecord) {
      url = this.constructor.modelBaseURL()
      request = Request.jsonPost(url, this.requestJSON())
    } else {
      url = this.constructor.modelBaseURL() + '/' + this.objectID()
      request = Request.jsonPut(url, this.requestJSON())
    }

    const that = this
    return this.constructor.requestItem(request, this.constructor).catch((err) => {
      throw err
    }).then((item) => {
      that.saturated = new Date()

      for (const key in item) {
        that[key] = item[key]
      }

      return that
    })
  }

  updateSelf (item) {
    for (const key in item) {
      this[key] = item[key]
    }
  }

  delete (data) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID()
    return this.constructor.requestSuccess(Request.delete(url, data), this)
  }

  restore () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/restore'
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  static reorder (models) {
    const request = []

    for (let i = 0; i < models.length; i++) {
      const model = models[i]
      const modelRequest = {
        id: model.objectID()
      }

      const additionalProperties = model.additionalPropertiesForReorder()
      if (additionalProperties) {
        for (const key in additionalProperties) {
          modelRequest[key] = additionalProperties[key]
        }
      }

      request.push(modelRequest)
    }

    const url = this.modelBaseURL() + '/reorder'
    return this.requestSuccess(Request.jsonPost(url, request))
  }

  static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page

    const data = {
      ids
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  modelInstantiationRequestObject () {
    const modelName = this.constructor.modelName()
    const type = modelName[0].toUpperCase() + modelName.substring(1)

    const requestObject = this.additionalPropertiesForModelInstantiation() || {}
    requestObject.type = type
    requestObject.id = this.objectID()

    return requestObject
  }

  additionalPropertiesForModelInstantiation () {
    return null
  }
}

export class Request {
  constructor (method, url) {
    this.method = method
    this.url = url
    this.authenticated = true
    this.responseType = 'json'
  }

  static get (url, headers) {
    const request = new this('get', url)
    request.headers = headers
    return request
  }

  static post (url, params) {
    const request = new this('post', url)
    request.data = params
    return request
  }

  static put (url, params) {
    const request = new this('put', url)
    request.data = params
    return request
  }

  static jsonPost (url, object) {
    const request = new this('post', url)
    request.data = object
    return request
  }

  static jsonPut (url, object) {
    const request = new this('put', url)
    request.data = object
    return request
  }

  static delete (url, data) {
    const request = new this('delete', url)
    request.data = data
    return request
  }
}

export class APIList {
  constructor (response, type) {
    for (const key in response) {
      this[key] = response[key]
    }

    this.items = Boxer.unbox(response.data, type)
    this.deleted = response.deleted

    if (response.paging) {
      this.paging = new APIListPaging(response.paging)
    }
  }

  toJSON () {
    const object = {}

    for (const key in this) {
      object[key] = this[key]
    }

    return object
  }
}

export class APIListPaging {
  constructor (paging) {
    this.page = parseInt(paging.page)
    this.limit = parseInt(paging.limit)
    this.itemCount = parseInt(paging.itemCount)
    this.pageCount = parseInt(paging.pageCount)
  }

  get isFirstPage () {
    return (this.page === 1)
  }

  get isLastPage () {
    return (this.itemCount === 0 || this.page === this.pageCount || this.page > this.pageCount)
  }

  get getProgress () {
    return (this.page / this.pageCount)
  }
}

export class APIException {
  constructor (exception) {
    this.type = exception.response?.data?.exception?.type
    this.message = exception.response?.data?.exception?.message
    this.logout = exception.response?.data?.exception?.logout
    this.code = exception.response?.data?.exception?.code || exception.response?.status

    this.status = exception.response?.status
    this.data = exception.response?.data
  }

  get errors () {
    if (this.message) {
      return [this.message]
    }

    return false
  }
}

