import Fuse from 'fuse.js'

import Model from '../model'
import Result from '../result'

import { sections } from '@/components/manage/pages'
import Component from '@/components/search/elements/Manage'


class Manage extends Model {
  constructor (options = {}) {
    super(options)

    this.name = 'Manage'
    this.component = Component

    this.items = []
  }

  init (context) {
    this.syncing = true

    const items = Object.values(sections)
      .filter((item) => {
        const features = item.path ? context.$features.route(item.path) : null

        if (features) {
          const access = context.$features.all(features)

          if (!access) {
            return false
          }
        }

        const grants = item.path ? context.$grants.route(item.path) : null

        if (!grants) {
          return true
        }

        return context.$grants.all(grants)
      })

    this.items = items
  }

  search (query) {
    const items = this.items

    const fuse = new Fuse(items, {
      keys: [
        'label',
        'info',
        'tags'
      ]
    })

    const results = fuse.search(query)
    const filtered = results.map(({ item }) => new ManageResult({ data: item }))

    filtered.length = Math.min(filtered.length, 5)

    this.results = filtered
  }
}

class ManageResult extends Result {
  get path () {
    return this.data.path
  }
}

export default new Manage()
