<template>
  <div
    :class="{ 'inline-flex h-full': $slots.default }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      :id="uuid + '-contents'"
      class="contents"
      @click="handleClick"
      @contextmenu.prevent="handleContextMenu"
    >
      <slot ref="trigger" v-bind="props">
        <template v-if="!anchor">
          <div class="flex items-center">
            <o-button
              v-if="split"
              :flat="flat"
              :outline="outline"
              :variant="variant"
              :icon="icon"
              :icon-size="12"
              rounded="rounded-l-lg mr-px"
              @click.stop.prevent="$emit('click')"
            >
              {{ label }}
            </o-button>
            <o-button
              ref="button"
              :flat="flat"
              :outline="outline"
              :variant="variant"
              :rounded="split ? 'rounded-r-lg' : 'rounded-lg'"
              icon="chevronDown"
              icon-after
              :icon-size="12"
            >
              <template v-if="!split">
                {{ label }}
              </template>
            </o-button>
          </div>
        </template>
      </slot>
    </div>

    <component :is="portal ? 'portal' : 'div'" to="dialog-container">
      <transition name="fade" appear>
        <div
          v-if="(show && clickaway) || (show && isFixed)"
          :id="uuid + '-backdrop'"
          :class="[
            'fixed inset-0 z-80000',
            { 'bg-black bg-opacity-10': overlay || isFixed }
          ]"
          @click="close()"
        />
      </transition>
      <dropdown-provider
        :id="uuid + '-provider'"
        :key="uuid + '-provider'"
        v-bind="props"
      >
        <div
          :id="uuid + '-dropdown'"
          :ref="uuid"
          :class="[
            'o-dropdown absolute z-80000',
            isFixed ? 'shadow' : 'filter drop-shadow-md',
            containerClass,
            { 'backdrop-filter backdrop-blur': translucent }
          ]"
        >
          <transition :name="transition">
            <div v-if="show" :class="dropdownClass">
              <transition name="fade">
                <div
                  v-if="loading"
                  class="absolute h-full w-full inset-0 bg-white bg-opacity-25 backdrop-blur z-80000 dark:bg-gray-900"
                >
                  <div class="h-full w-full flex items-center justify-center">
                    <o-loader />
                  </div>
                </div>
              </transition>
              <slot name="content" v-bind="props">
                <div class="flex flex-col divide-y opacity-80">
                  <dropdown-item
                    v-for="(item, itemIndex) in items"
                    :key="`item-${itemIndex}`"
                    :name="item.name"
                    :description="item.description"
                    :icon="item.icon"
                    v-on="item.events"
                  />
                </div>
              </slot>
            </div>
          </transition>
          <transition name="fade">
            <div
              v-show="show && arrow && !isFixed"
              ref="arrow"
              data-popper-arrow
              :class="[
                'bg-white dark:bg-gray-900',
                { 'opacity-50': translucent }
              ]"
            />
          </transition>
        </div>
      </dropdown-provider>
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createPopper, detectOverflow } from '@popperjs/core'

import DropdownProvider from './DropdownProvider'
import DropdownItem from './DropdownItem'

const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  }
}

export default {
  name: 'ODropdown',
  components: {
    DropdownProvider,
    DropdownItem
  },
  provide () {
    return {
      dropdown: () => this.props
    }
  },
  props: {
    anchor: {
      type: undefined,
      default: null
    },
    trigger: {
      type: Array,
      default: () => ['click']
    },
    placement: {
      type: String,
      default: 'bottom-end',
      validator (value) {
        const options = [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end'
        ]

        return options.includes(value)
      }
    },
    boundary: {
      type: String,
      default: 'scrollParent'
    },
    portal: {
      type: Boolean,
      default: true
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    overlay: {
      type: Boolean,
      default: false
    },
    clickaway: {
      type: Boolean,
      default: true
    },
    arrow: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'dropdown'
    },
    containerClass: {
      type: String,
      default: ''
    },
    defaultClass: {
      type: String,
      default:
        'z-10 origin-top-right min-w-52 bg-white dark:bg-gray-900 rounded dark:border dark:border-gray-800'
    },
    padding: {
      type: String,
      default: 'py-1 px-0'
    },
    sameWidth: {
      type: Boolean,
      default: false
    },
    detectOverflow: {
      type: Boolean,
      default: false
    },
    translucent: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    split: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: null
    },
    modifiers: {
      type: Array,
      default: () => [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        },
        {
          name: 'preventOverflow',
          enabled: true
        }
      ]
    }
  },
  data () {
    return {
      popper: null,
      show: false,
      overflow: null
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
    isFixed () {
      return this.isMobile && this.fixed
    },
    transition () {
      if (this.isFixed) {
        return 'slide-up'
      }

      return 'grow-in'
    },
    dropdownClass () {
      return [
        this.padding,
        this.defaultClass,
        { 'w-full h-auto fixed bottom-0 left-0 right-0': this.isFixed },
        { 'bg-opacity-50 border border-gray-200 dark:border-gray-800': this.translucent }
      ]
    },
    props () {
      return {
        close: this.close,
        open: this.open,
        show: this.show,
        autoHide: this.autoHide,
        overflow: this.overflow
      }
    },
    useTriggerClick () {
      return this.trigger.includes('click')
    },
    useTriggerHover () {
      return this.trigger.includes('hover')
    },
    useTriggerContextMenu () {
      return this.trigger.includes('contextmenu')
    }
  },
  watch: {
    show (show) {
      if (show) {
        this.$emit('show')
        if (!this.isFixed) {
          if (this.popper) {
            this.enableListeners()
          }
          this.update()
          this.$emit('shown')
        }
      } else {
        this.$emit('hide')
        if (this.popper) {
          this.disableListeners()
        }
        this.$emit('hidden')
      }
    },
    isFixed (isFixed) {
      if (isFixed) {
        this.destroy()
      }
    }
  },
  beforeCreate () {
    this.uuid = this._uid.toString()
  },
  beforeDestroy () {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  },
  methods: {
    handleClick () {
      if (this.useTriggerClick && !this.disabled) {
        this.show = !this.show
      }
    },
    handleMouseEnter () {
      if (this.useTriggerHover && !this.isFixed) {
        this.show = !this.disabled
      }
    },
    handleMouseLeave () {
      if (this.useTriggerHover && !this.isFixed) {
        this.show = false
      }
    },
    handleContextMenu () {
      if (this.useTriggerContextMenu && !this.disabled) {
        this.show = !this.show
      }
    },
    open () {
      this.show = true

      this.$emit('show')

      if (!this.isFixed) {
        if (this.popper) {
          this.enableListeners()
        }
        this.update()
        this.$emit('shown')
      }
    },
    close () {
      this.$emit('close')
      this.show = false

      this.$emit('hide')

      if (this.popper) {
        this.disableListeners()
      }
      this.$emit('hidden')
    },
    create () {
      this.$nextTick(() => {
        let trigger

        if (this.anchor) {
          trigger = this.anchor
        } else if (this.$slots.default) {
          trigger = this.$slots.default[0].elm
        } else if (this.$refs.button) {
          trigger = this.$refs.button.$el
        }

        const uuid = this.uuid
        const dropdown = this.$refs[this.uuid]
        const arrow = this.$refs.arrow

        if (trigger && dropdown) {
          const modifiers = [
            ...this.modifiers,
            {
              name: 'arrow',
              options: {
                element: arrow,
                padding: 5
              }
            }
          ]

          if (this.sameWidth) {
            modifiers.push(sameWidth)
          }

          if (this.detectOverflow) {
            const that = this

            modifiers.push({
              name: 'overflow',
              enabled: true,
              phase: 'main',
              requiresIfExists: ['offset'],
              fn: ({ state }) => {
                const overflow = detectOverflow(state)
                that.overflow = overflow
              }
            })
          }

          this.popper = createPopper(trigger, dropdown, {
            placement: this.placement,
            modifiers
          })
        } else {
          this.close()
        }
      })
    },
    update () {
      if (this.popper && !this.anchor) {
        this.popper.update()
      } else {
        this.create()
      }
    },
    enableListeners () {
      this.$nextTick(() => {
        if (this.popper) {
          this.popper.setOptions({
            modifiers: [
              ...this.modifiers,
              { name: 'eventListeners', enabled: true }
            ]
          })
        }
      })
    },
    disableListeners () {
      this.$nextTick(() => {
        if (this.popper) {
          this.popper.setOptions({
            modifiers: [
              ...this.modifiers,
              { name: 'eventListeners', enabled: false }
            ]
          })
        }
      })
    },
    destroy () {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    }
  }
}
</script>

<style lang="scss">
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}

.slide-up-enter,
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.grow-in-enter-active,
.grow-in-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}

.grow-in-enter,
.grow-in-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.o-dropdown {
  [data-popper-arrow],
  [data-popper-arrow]::before {
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    z-index: -10;

    .dark & {
      background: theme('colors.zinc.900');
    }
  }

  [data-popper-arrow] {
    visibility: hidden;
  }

  [data-popper-arrow]::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &[data-popper-placement^='top'] [data-popper-arrow] {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] [data-popper-arrow] {
    top: -4px;
  }

  &[data-popper-placement^='left'] [data-popper-arrow] {
    right: -4px;
  }

  &[data-popper-placement^='right'] [data-popper-arrow] {
    left: -4px;
  }

  &[data-popper-reference-hidden] [data-popper-arrow]::before {
    visibility: hidden;
  }
}
</style>
