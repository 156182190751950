export default (context, inject) => {
  const plugin = {
    /**
     * Check if the location has the required feature
     * @param {string} model - Name of the feature
     */
    get (feature) {
      const active = context.$auth.active
      const features = this.list

      // Check if there is an active user and features are available
      if (!active || !features) {
        return false
      }

      // Check if the user has the required model
      return features.includes(feature)
    },

    /**
     * Check if the location has all the required features in array
     * @param {array} featured - A list of features
     * @returns boolean
     */
    all (features) {
      return features
        .every((feature) => {
          return this.get(feature)
        })
    },

    /**
     * Return the required features for a given path
     * @param {string} path - A path to check
     * @returns boolean
     */
    route (path) {
      if (!path) {
        return false
      }

      const resolved = context.app.router.resolve(path)
      const features = resolved && resolved.route ? resolved.route.meta.features : null

      return features
    },

    /**
     * Check if location has the required features for given path
     * @param {string} path - A path to check
     * @returns boolean
     */
    access (path) {
      if (!path) {
        return false
      }

      const resolved = context.app.router.resolve(path)
      const features = resolved && resolved.route ? resolved.route.meta.features : null

      if (!features) {
        return true
      }

      return this.all(features)
    },

    /**
     * Get a list of the locations features
     */
    get list () {
      const info = context.store.getters['auth/info']
      return info.features
    }
  }

  inject('features', plugin)
}
