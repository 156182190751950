import Model, { Request } from '../model'
import Customer from './customer'

export default class Review extends Model {
  static modelName () {
    return 'review'
  }

  objectID () {
    return this.idLocationReview
  }

  relations () {
    return {
      customer: { type: Customer }
    }
  }

  approve () {
    const url = this.constructor.modelBaseURL() + '/approve?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unapprove () {
    const url = this.constructor.modelBaseURL() + '/unapprove?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  feature () {
    const url = this.constructor.modelBaseURL() + '/feature?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  unfeature () {
    const url = this.constructor.modelBaseURL() + '/unfeature?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  respond (reply) {
    const url = this.constructor.modelBaseURL() + '/reply?id=' + this.objectID()

    const data = {
      reply
    }

    return this.constructor.requestItem(Request.post(url, JSON.stringify(data)), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }
}
