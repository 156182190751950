<template>
  <div class="flex w-full items-center space-x-2">
    <div class="w-18 h-18 relative">
      <o-dropdown
        :trigger="['contextmenu']"
        placement="bottom-start"
        :arrow="false"
        :disabled="!model.hasImage"
        :auto-hide="false"
      >
        <o-avatar :items="[model]" size="xl" />

        <template #content>
          <o-dropdown-item
            v-if="model.hasImage"
            name="Download image"
            icon="download"
            @click="handleOpenImage"
          />
        </template>
      </o-dropdown>
    </div>

    <o-button size="sm" flat outline @click="handleClick">
      Update Image
    </o-button>
  </div>
</template>

<script>
import OAvatar from '@/components/elements/Avatar.vue'
import ODropdownItem from '@/components/elements/dropdown/DropdownItem.vue'

export default {
  components: {
    OAvatar,
    ODropdownItem
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    },
    handleOpenImage () {
      const image = this.model?.images?.large
      const target = '_blank'

      if (image) {
        window.open(image, target)
      }
    }
  }
}
</script>
