<template>
  <div>
    <div v-if="ready">
      <transition name="fade">
        <div v-if="activePanels" class="o-panel--overlay overflow-auto inset-0 w-screen h-screen fixed bg-black opacity-50" @click="close()" />
      </transition>

      <panel-container
        v-for="(panel, index) in panels"
        :ref="`panel-${index}`"
        :key="panel.path"
        :panel="panel"
        :index="index"
        :count="count"
        @focus="focus(index)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PanelContainer from './PanelContainer'

export default {
  components: {
    PanelContainer
  },
  data () {
    return {
      ready: false,
      state: 0
    }
  },
  computed: {
    panels () {
      return this.$panel.panels.filter(({ active }) => active)
    },
    allPanels () {
      return this.$panel.panels
    },
    count () {
      return this.panels.length - 1
    },
    activePanels () {
      return this.panels.length
    }
  },
  watch: {
    allPanels () {

    }
  },
  mounted () {
    window.onpopstate = state => this.$panel.navigate(state)

    const route = this.$route
    const hash = route.hash

    if (hash) {
      const path = hash.replace('#', '')
      this.$panel.create(path)
    }

    this.ready = true
  },
  methods: {
    close () {
      const index = this.panels.length - 1
      const panel = this.panels[index]

      if (panel) {
        panel.close()
      }
    },
    focus (index) {
      this.$panel.popToPanel(index)
    }
  }
}
</script>

<style lang="scss">
  .o-panel {
    z-index: 70000;
    .o-panel--container {
      z-index: 700000
    }
  }
  .o-panel--overlay {
    z-index: 60000;
  }
</style>
