<template>
  <ul class="flex flex-col">
    <template v-for="item in items">
      <o-menu-item :key="item.path" :item="item" />
    </template>
  </ul>
</template>

<script>
import OMenuItem from './MenuItem.vue'

export default {
  components: {
    OMenuItem
  },
  props: {
    items: {
      type: [Array, Object],
      required: true
    }
  }
}
</script>
