var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.baseClass,
    { 'flex-col-reverse' : _vm.flip }
  ]},[(!_vm.noHeader)?_c('div',{class:[
      _vm.headerClass,
      { 'cursor-pointer' : _vm.clickable && !_vm.disabled }
    ],on:{"click":function($event){return _vm.handleHeaderClick()}}},[_vm._t("header",null,{"visible":_vm.show,"toggle":_vm.toggle}),_vm._v(" "),_vm._t("toggle",function(){return [(_vm.showToggle && !_vm.disabled)?_c('o-button',{staticClass:"-mr-1",attrs:{"size":"sm","flat":"","icon":"accordion"}}):_vm._e()]},{"visible":_vm.show,"toggle":_vm.toggle})],2):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"expand"},on:{"enter":_vm.enter,"after-enter":_vm.afterEnter,"leave":_vm.leave}},[(_vm.show)?_c('div',{class:_vm.bodyClass},[_c('div',[_vm._t("default",null,{"active":_vm.show})],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }