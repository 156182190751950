<template>
  <container v-bind="container" leading="datepicker">
    <v-date-picker
      ref="calendar"
      v-model="currentValue"
      :mode="mode"
      :min-date="minDate"
      :max-date="maxDate"
      :is-range="range"
      :first-day-of-week="firstDayOfWeek"
      :timezone="timezone"
      :popover="{
        placement: 'bottom-start',
        positionFixed,
        visibility
      }"
      class="block h-full w-full text-sm"
      :is-dark="$colorMode.value === 'dark'"
      @dayclick="handleDayClick"
      v-on="handlers"
    >
      <template #default="{ inputValue, inputEvents, togglePopover }">
        <slot v-bind="{ inputValue, inputEvents, togglePopover }">
          <input
            type="text"
            :value="displayValue(inputValue)"
            v-bind="attributes"
            class="h-full w-full bg-transparent"
            v-on="inputEvents"
            @click="togglePopover"
          >
        </slot>
      </template>
    </v-date-picker>

    <template #leading>
      <slot name="leading" />
    </template>

    <template #trailing>
      <slot name="trailing" />
    </template>
  </container>
</template>

<script>
import Container from './Container'

import classes from '@/mixins/fields/classes.js'
import attributes from '@/mixins/fields/attributes.js'
import methods from '@/mixins/fields/methods.js'
import augmenters from '@/mixins/fields/augmenters'
import input from '@/mixins/fields/input.js'

export default {
  components: {
    Container
  },
  mixins: [attributes, classes, methods, input],
  props: {
    value: {
      type: [String, Number, Object, Date],
      default: null
    },
    range: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
      required: false,
      default: null
    },
    maxDate: {
      type: Date,
      required: false,
      default: null
    },
    augmenter: {
      type: [Object, Boolean],
      required: false,
      default: () => augmenters.YMD
    },
    visibility: {
      type: String,
      default: 'click'
    },
    timezone: {
      type: String,
      default: undefined
    },
    mode: {
      type: String,
      default: 'date'
    },
    positionFixed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentValue: {
      get () {
        if (this.value === '') { return null }

        if (this.augmenter && this.augmenter.input) {
          if (this.range) {
            return {
              start: this.augmenter.input(this.value.start),
              end: this.augmenter.input(this.value.end)
            }
          }

          return this.augmenter.input(this.value)
        }

        return this.value
      },
      set (value) {
        const output = this.augment(value)

        this.$emit('input', output)
      }
    },
    firstDayOfWeek () {
      const day = this.$auth?.info?.location?.startDayOfWeek?.value + 1

      return day === 8 ? 1 : day
    }
  },
  methods: {
    augment (value) {
      if (this.augmenter && this.augmenter.output) {
        if (this.range) {
          return {
            start: this.augmenter.output(value.start),
            end: this.augmenter.output(value.end)
          }
        }

        return this.augmenter.output(value)
      }

      return value
    },
    displayValue (value) {
      if (this.range) {
        if (value.start && value.end) {
          return `${value.start} to ${value.end}`
        }

        return ''
      }

      return value
    },
    handleDayClick (prop) {
      if (prop.date?.getTime() === this.currentValue?.getTime()) {
        this.$nextTick(() => {
          this.currentValue = prop.date
        })
      }
    }
  }
}
</script>
