import APIObject from '../object'
import Enum from '../enums'

import Customer from './customer'
import Order from './order'

export default class ProductInventoryTransaction extends APIObject {
  relations () {
    return {
      customer: { type: Customer },
      order: { type: Order },
      type: { type: TransactionType }
    }
  }

  static addTypes () {
    const { refund, addStock, adjustment, other } = TransactionType
    return [
      refund,
      addStock,
      adjustment,
      other
    ]
  }

  static removeTypes () {
    const { sale, returnStock, adjustment, writeoff, damaged, outOfDate, internal, other } = TransactionType
    return [
      sale,
      returnStock,
      adjustment,
      writeoff,
      damaged,
      outOfDate,
      internal,
      other
    ]
  }
}

export const TransactionType = new Enum({
  sale: { value: 0, description: 'Sale' },
  refund: { value: 1, description: 'Refund' },
  addStock: { value: 2, description: 'Add Stock' },
  returnStock: { value: 3, description: 'Return Stock' },
  adjustment: { value: 4, description: 'Adjustment' },
  writeoff: { value: 5, description: 'Written Off' },
  damaged: { value: 6, description: 'Damaged' },
  outOfDate: { value: 7, description: 'Out of Date' },
  other: { value: 8, description: 'Other' },
  internal: { value: 9, description: 'Internal use' },
  undefined: { value: 10, description: 'Undefined' } // NOTE: I was getting some 10s through for some reason
})

export const TransactionAction = new Enum({
  add: { value: 0, description: 'Add' },
  remove: { value: 1, description: 'Remove' }
})
