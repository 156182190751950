import Watcher from './watcher'

export default class Filter {
  constructor (config) {
    if (config) {
      this._value = config.value
      this.label = config.label
      this.info = config.info
      this.tooltip = config.tooltip
      this.options = config.options
      this.component = config.component
      this.force = config.force
      this.watch = config.watch
      this.props = config.props
      this.custom = config.custom
      this.features = config.features
      this.config = config
    }
  }

  get value () {
    const value = Watcher.create(this._value, this.watch, () => {
      this.valueChanged()
    })
    return value
  }

  set value (newValue) {
    this._value = newValue

    this.valueChanged()
  }

  valueChanged () {
    this.items = null
  }

  get valueLabel () {
    if (this.config && this.config.valueLabel) {
      return this.config.valueLabel.apply(this)
    } else {
      return this.value
    }
  }

  clear () {
    if (this.config && this.config.clear) {
      return this.config.clear.apply(this)
    } else {
      this.value = null
    }
  }

  isEmpty () {
    if (this.config && this.config.isEmpty) {
      return this.config.isEmpty.apply(this, [this.value])
    } else {
      if (Array.isArray(this.value) && !this.value.length) {
        return true
      } else if (!this.value) {
        return true
      }

      return false
    }
  }

  matches (items) {
    if (this.config && this.config.matches) {
      return this.config.matches.apply(this, [items])
    }

    return null
  }

  get validation () {
    if (this.config && this.config.validation) {
      return this.config.validation.apply(this, [this.value])
    }

    return null
  }
}
