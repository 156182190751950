<template>
  <div
    v-bind="getAttributes()"
    :class="currentClass"
    :style="styles"
    @click="onClick"
    @onFocus="onFocus"
    @blur="onBlur"
  >
    <o-icon
      v-if="icon && !iconAfter"
      :icon="icon"
      :size="13"
      :class="[
        hasActionListener ? 'bg-white/20 hover:bg-opacity-50 transition duration-300 ml-2px' : ($slots.default ? '-ml-1' : ''),
        'cursor-pointer pointer-events-auto p-2px rounded',
        $slots.default ? 'md:mr-2' : '-mx-1'
      ]"
      v-on="handlers"
    />
    <slot />
    <o-icon
      v-if="icon && iconAfter"
      :icon="icon"
      :size="13"
      :class="[
        hasActionListener ? 'bg-white/20 hover:bg-opacity-50 transition duration-300' : ($slots.default ? '-mr-1' : ''),
        'cursor-pointer pointer-events-auto p-2px rounded',
        $slots.default ? 'md:ml-2' : '-mx-1'
      ]"
      v-on="handlers"
    />
  </div>
</template>

<script>
import colours from '~/mixins/elements/colours'

export default {
  name: 'OChip',
  mixins: [colours],
  props: {
    id: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    tagName: {
      type: String,
      default: 'button',
      validator (value) {
        return ['button', 'a'].includes(value)
      }
    },
    size: {
      type: String,
      default: null,
      validator (value) {
        return value === null || ['lg', 'sm', 'xs', '2xs'].includes(value)
      }
    },
    icon: {
      type: [String, Array],
      default: null
    },
    iconAfter: {
      type: Boolean,
      default: false
    },
    translucent: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: true
    },
    colour: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      baseClass: 'flex flex-row items-center inline-flex items-center justify-center transition ease-in-out duration-300 leading-none',

      disabledClass: 'opacity-50 pointer-events-none',
      translucentClass: 'bg-opacity-10',
      transparentClass: 'bg-opacity-0',

      defaultSizeClass: 'px-3 py-1 min-h-6 text-xs font-medium',
      largeSizeClass: 'px-3 h-7 text-sm font-medium',
      smallSizeClass: 'px-2 py-1 min-h-6 text-xs font-medium',
      xsSizeClass: 'px-2 h-5 text-xs font-medium',
      '2xsSizeClass': 'px-1 h-4 text-2xs',

      roundedClass: 'rounded-full',
      squareClass: 'rounded'
    }
  },
  computed: {
    currentClass () {
      const classes = [
        `${this.$options._componentTag}`,
        this.baseClass
      ]
      if (this.disabled) {
        classes.push(this.disabledClass)
      }
      if (this.translucent) {
        classes.push(this.translucentClass)
      }
      if (this.transparent) {
        classes.push(this.transparentClass)
      }

      if (this.size === null) {
        classes.push(this.defaultSizeClass)
      } else if (this.size === 'sm') {
        classes.push(this.smallSizeClass)
      } else if (this.size === 'lg') {
        classes.push(this.largeSizeClass)
      } else if (this.size === 'xs') {
        classes.push(this.xsSizeClass)
      } else if (this.size === '2xs') {
        classes.push(this['2xsSizeClass'])
      }

      if (this.square) {
        classes.push(this.squareClass)
      } else {
        classes.push(this.roundedClass)
      }

      if (!this.hasClickListener && !this.hover) {
        classes.push('pointer-events-none')
      }

      if (this.hover) {
        classes.push('cursor-pointer')
      }

      return this.variants(classes)
    },
    styles () {
      const styles = {}

      if (this.colour) {
        styles.backgroundColor = this.flat ? `#${this.colour}25 !important` : `#${this.colour} !important`
        styles.color = this.flat ? `#${this.colour} !important` : '#fff !important'
      }

      return styles
    },
    handlers () {
      const that = this

      if (this.hasActionListener) {
        return {
          'click' (e) {
            e.stopPropagation()
            that.onAction(e)
          }
        }
      }

      return {}
    },
    hasClickListener () {
      return !!this.$listeners && !!this.$listeners.click
    },
    hasActionListener () {
      return !!this.$listeners && !!this.$listeners.action
    }
  },
  methods: {
    onBlur (e) {
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.$emit('focus', e)
    },
    onClick (e) {
      this.$emit('click', e)
    },
    onAction (e) {
      this.$emit('action', e)
    },
    blur () {
      this.$el.blur()
    },
    focus () {
      this.$el.focus()
    },
    getAttributes () {
      return {
        id: this.id,
        value: this.value,
        autofocus: this.autofocus,
        disabled: this.disabled,
        name: this.name
      }
    }
  }
}
</script>
