import Vue from 'vue'
import PanelLink from '../components/panel/PanelLink.vue'

class Panel {
  constructor (path, options, controller) {
    const { props, events } = options

    this.hash = this.hash()

    this.path = path
    this.props = props
    this.events =  events

    this._active = true
    this.component =  null
    this.keys = [Number(history.state.key || 0)]
    this.future = false
    this.resolve = null

    this.controller = controller
  }

  set active (value) {
    this._active = value

    if (!value) {
      this.component = null
    } else {
      this.future = false
    }
  }

  get active () {
    return this._active
  }

  hash () {
    let dt = new Date().getTime()
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return guid
  }

  close (force) {
    return new Promise((resolve) => {
      this.resolve = resolve

      const component = this.component
      const page = component.$refs.component
      const panel = page?.$refs?.panel

      if (panel) {
        if (force === true || !panel.shouldDismiss || panel.shouldDismiss()) {
          if (panel.willDismiss) {
            panel.willDismiss()
          }

          this.controller.pop(this.hash)

          if (panel.didDismiss) {
            panel.didDismiss()
          }

          this.resolve(true)
        } else if (panel.didAttemptToDismiss) {
          panel.didAttemptToDismiss()
        }
      }
    })
  }

  replace (path) {
    this.path = path
  }

  get portal () {
    return `portal-${this.hash}`
  }

  get extension () {
    return `extension-${this.hash}`
  }
}

export const plugin = context => Vue.observable({
  // Array of all panels
  panels: [],

  // The current page history key
  state: 0,

  /**
   * Create a new panel and push it
   * @param {string} path - path to the page
   * @param {object} options - containing props and events
   */
  create (path, options = {}) {
    const page = context.app.router.resolve(path)
    const valid = !!page.route.matched.length && page.route.meta.panel

    if (valid) {
      const panel = new Panel(path, options, this)
      this.push(panel)
    } else {
      console.error(`The path "${path}" is not a valid panel route`)
    }
  },

  /**
   * Add the new panel class to the panels array
   * @param {object} panel - instance of the Panel class
   */
  push (panel) {
    if (!this.findPanelByHash(panel.hash)) {
      this.panels.push(panel)
      this.state = Number(history.state.key)
      this.clean()
    }
  },

  /**
   * Remove the specified panel from the array
   * @param {string} hash - the hash id panel to remove
   */
  pop (hash) {
    const panel = this.findPanelByHash(hash)

    panel.active = false

    const route = { ...context.route }
    const next = this.nextPanel()

    if (next) {
      route.hash = '#' + next.path
      context.app.router.push(route, () => {
        next.keys.push(Number(history.state.key))
      })
    } else if (context.route.hash) {
      delete route.hash
      delete route.name
      context.app.router.push(route)
    }
  },

  /**
   * Iterate through all panels, triggering their close process until the target panel is reached
   * @param {number} target - the target panel to focus
   */
  async popToPanel (target) {
    const activePanels = this.activePanels()
    let index = activePanels.length - 1

    while (index > target) {
      const panel = activePanels[index]
      const closed = await panel.close()

      if (!closed) {
        return false
      }

      index--
    }

    return true
  },

  /**
   * Go straight to the root panel
   */
  async popToRoot () {
    return await this.popToPanel(0)
  },

  /**
   * Pop all active panels
   */
  async popToClear () {
    return await this.popToPanel(-1)
  },

  /**
   * Handle browser back/forward button
   * @param {object} state - the history pop state
   */
  async navigate (state) {
    const fromKey = this.state
    const nextKey = Number(state.state?.key)

    const route = context.route

    const activePanels = this.activePanels()
    const alreadyActive = activePanels.find(({ keys }) => keys.includes(nextKey))

    if (route.hash && !alreadyActive) {
      const panel = this.findPanelByKey(nextKey)

      if (panel) {
        panel.active = true
        panel.keys.push(nextKey)
      }
    } else if (activePanels.length) {
      const panel = this.findPanelByKey(fromKey)
      const closed = await panel?.close()

      if (closed) {
        if (fromKey && nextKey > fromKey) {
          panel.future = false
        } else {
          panel.future = true
        }
      }

      if (!closed) {
        const panel = this.nextPanel()
        const route = { ...context.route }
        route.hash = '#' + panel.path

        context.app.router.replace(route)
      }
    }

    this.state = nextKey
  },

  /**
   * Find panel by hash id in history array and add it to current panels array
   * @param {string} hash - the hash key panel to find
   */
  findPanelByHash (hash) {
    return this.panels.find(panel => panel.hash === hash)
  },

  /**
   * Find panel by history state key in history array and add it to current panels array
   * @param {string} key - the history state key of the panel to find
   */
  findPanelByKey (key) {
    return this.panels.find(panel => panel.keys.includes(key))
  },

  /**
   * Return all currently active panels
   */
  activePanels () {
    return this.panels.filter(({ active }) => active)
  },

  /**
   * Return the next active panel in the stack
   */
  nextPanel () {
    const active = this.activePanels()
    const index = active.length - 1
    return active[index]
  },

  /**
   * Remove all panels from array that can no longer be accessed using back/forward browser navigation
   */
  clean () {
    const panels = this.panels
    this.panels = panels.filter(({ future }) => !future)
  }
})

export default (context, inject) => {
  inject('panel', plugin(context))
}

Vue.component(PanelLink.name, PanelLink)
