<template>
  <component
    :is="componentToRender"
    v-bind="getAttributes()"
    :class="currentClass"
    @click="onClick"
    @onFocus="onFocus"
    @blur="onBlur"
  >
    <o-icon
      v-if="icon && !iconAfter"
      :icon="icon"
      :size="iconSize ? iconSize : isSmall ? 12 : 14"
      :class="leftIconClass"
      fill
    />
    <div v-if="$slots.default || label" :class="{ 'opacity-0' : loading }" class="leading-none">
      <slot>
        {{ label }}
      </slot>
    </div>
    <o-icon
      v-if="icon && iconAfter"
      :icon="icon"
      :size="iconSize ? iconSize : isSmall ? 12 : 14"
      :class="rightIconClass"
      fill
    />

    <div
      v-if="loading"
      class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <o-loader
        size="18px"
      />
    </div>

    <div v-if="badge" class="mt-1 flex items-center justify-center min-w-5 h-5 absolute rounded-xl bg-red-600 text-white border border-white shadow text-xs top-0 right-0 transform translate-x-2 -translate-y-2 leading-none text-center">
      <p class="px-1">
        {{ badge }}
      </p>
    </div>
  </component>
</template>

<script>
import colours from '~/mixins/elements/colours'
import componentType from '~/mixins/elements/type'

export default {
  name: 'OButton',
  mixins: [componentType, colours],
  props: {
    id: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    badge: {
      type: [String, Number],
      default: null
    },
    tabindex: {
      type: [String, Number],
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String,
      default: 'button'
    },
    href: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: null
    },
    icon: {
      type: [String, Array],
      default: null
    },
    iconSize: {
      type: Number,
      default: undefined
    },
    iconAfter: {
      type: Boolean,
      default: false
    },
    method: {
      type: String,
      default: undefined
    },
    data: {
      type: Object,
      default: undefined
    },
    preserveState :{
      type: Boolean,
      default: false
    },
    preserveScroll :{
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: undefined
    },
    replace: {
      type: Boolean,
      default: false
    },
    append: {
      type: Boolean,
      default: false
    },
    baseClass: {
      type: String,
      default: 'leading-loose block inline-flex items-center justify-center transition ease-in-out duration-300 transform cursor-pointer'
    },
    hoverClass: {
      type: String,
      default: 'hover:scale-102 hover:shadow'
    },
    activeClass: {
      type: String,
      default: 'router-link-active'
    },
    exact: {
      type: Boolean,
      default: false
    },
    exactActiveClass: {
      type: String,
      default: 'router-link-exact-active'
    },
    rounded: {
      type: String,
      default: 'rounded-lg'
    },
    translucent: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    dashed: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      disabledClass: 'opacity-50 pointer-events-none cursor-default',
      translucentClass: 'bg-opacity-10',
      transparentClass: 'bg-opacity-0',

      defaultSizeClass: 'px-3 h-8 text-sm',
      slimSizeClass: 'px-2 h-8 text-sm',
      largeSizeClass: 'px-4 h-10 text-base',
      smallSizeClass: 'px-2 h-7 py-1 text-sm',
      extraSmallSizeClass: 'px-2 h-6 text-sm'
    }
  },
  computed: {
    currentClass () {
      const classes = [
        `${this.$options._componentTag}`,
        this.baseClass,
        this.rounded
      ]
      if (this.disabled || this.loading) {
        classes.push(this.disabledClass)
      }
      if (this.translucent) {
        classes.push(this.translucentClass)
      }
      if (this.transparent) {
        classes.push(this.transparentClass)
      }
      if (this.hover) {
        classes.push(this.hoverClass)
      }
      if (this.dashed) {
        classes.push('border-dashed')
      }
      if (this.size === null) {
        classes.push(this.defaultSizeClass)
      } else if (this.size === 'sm') {
        classes.push(this.smallSizeClass)
      } else if (this.size === 'lg') {
        classes.push(this.largeSizeClass)
      } else if (this.size === 'xs') {
        classes.push(this.extraSmallSizeClass)
      } else if (this.size === 'slim') {
        classes.push(this.slimSizeClass)
      }

      if (!this.$slots.default && !this.label) {
        if (this.size === null) {
          classes.push('w-8')
        } else if (this.size === 'sm') {
          classes.push('w-7')
        } else if (this.size === 'lg') {
          classes.push('w-10')
        } else if (this.size === 'xs') {
          classes.push('w-6')
        } else if (this.size === 'slim') {
          classes.push('w-8')
        }
      }

      return this.variants(classes)
    },
    leftIconClass () {
      const classes = []

      if (this.$slots.default || this.label) {
        classes.push(this.isSmall ? 'mr-2' : 'mr-3')
        classes.push('hidden md:block')
      }

      if (this.loading) {
        classes.push('opacity-0')
      }

      return classes
    },
    rightIconClass () {
      const classes = []

      if (this.$slots.default) {
        classes.push(this.isSmall ? 'ml-2' : 'ml-3')
        classes.push('hidden md:block')
      }

      if (this.loading) {
        classes.push('opacity-0')
      }

      return classes
    },
    isSmall () {
      return ['xs', 'sm'].includes(this.size)
    }
  },
  methods: {
    onBlur (e) {
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.$emit('focus', e)
    },
    onClick (e) {
      this.$emit('click', e)
    },
    blur () {
      this.$el.blur()
    },
    focus () {
      this.$el.focus()
    },
    getAttributes () {
      if (this.isAnIntertiaLink) {
        return {
          href: this.href,
          method: this.method,
          data: this.data,
          preserveState: this.preserveState,
          preserveScroll: this.preserveScroll,
          id: this.id,
          value: this.value,
          autofocus: this.autofocus,
          disabled: this.disabled || this.loading,
          name: this.name,
          type: this.type
        }
      }
      if (this.isARouterLink) {
        return {
          to: this.to,
          replace: this.replace,
          append: this.append,
          tag: this.tagName,
          activeClass: this.activeClass,
          exact: this.exact,
          event: ['click', 'focus', 'blur'],
          exactActiveClass: this.exactActiveClass,
          id: this.id,
          value: this.value,
          autofocus: this.autofocus,
          disabled: this.disabled || this.loading,
          name: this.name,
          type: this.type,
          target: this.target
        }
      }
      return {
        id: this.id,
        value: this.value,
        autofocus: this.autofocus,
        disabled: this.disabled || this.loading,
        name: this.name,
        href: this.href,
        type: this.type,
        target: this.target
      }
    }
  }
}
</script>
