import Model from '../model'
import Enum from '../enums'

export default class ChargeModel extends Model {
  static modelName () {
    return 'chargeModel'
  }

  objectID () {
    return this.idChargeModel
  }

  relations () {
    return {
      amountType: { type: ChargeAmountType }
    }
  }

  get isAmountTypeFixed () {
    return this.amountType === ChargeAmountType.fixed
  }

  get isAmountTypePercentage () {
    return this.amountType === ChargeAmountType.percentage
  }
}

export const ChargeAmountType = new Enum({
  fixed: { value: 0, description: 'Fixed' },
  percentage: { value: 1, description: 'Percentage' }
})
