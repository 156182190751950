<template>
  <component
    :is="tagName"
    :class="currentClass"
    :to="to"
  >
    <slot v-if="noBody" />
    <template v-else>
      <div
        v-if="hasHeaderSlot"
        ref="header"
        class="w-full"
        :class="[headerClass, expandable ? expandableClass : '' ]"
        @click="expandable ? toggle() : null"
      >
        <slot name="header" />
        <o-icon
          v-if="expandable"
          icon="chevronDown"
          :size="12"
          :class="{ 'transform rotate-180' : visible }"
          class="m-0 cursor-pointer transition duration-200 ease-in-out"
        />
      </div>
      <div
        v-else-if="header"
        ref="header"
        :class="[ expandable ? [ headerClass, expandableClass] : headerClass ]"
        @click="expandable ? toggle() : null"
      >
        <div>
          <h2 :class="headerLabelClass">
            {{ header }}
          </h2>
          <p v-if="info" class="text-sm opacity-80 font-normal">
            {{ info }}
          </p>
        </div>
        <div>
          <slot name="control" />
          <o-icon
            v-if="expandable"
            icon="chevronDown"
            :size="12"
            :class="{ 'transform rotate-180' : visible }"
            class="m-0 cursor-pointer transition duration-200 ease-in-out"
          />
        </div>
      </div>
      <div
        v-if="visible"
        ref="body"
        :class="bodyClass"
      >
        <slot />
      </div>
      <div
        v-if="hasFooterSlot"
        ref="footer"
        :class="footerClass"
      >
        <slot name="footer" />
      </div>
    </template>
  </component>
</template>

<script>
export default {
  name: 'OCard',
  props: {
    tagName: {
      type: String,
      default: 'div'
    },
    to: {
      type: String,
      default: null
    },
    header: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    expandable: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    actionable: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    noBody: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      default: 'flex justify-between px-5 pt-4 pb-3 border-b border-gray-100 dark:border-gray-900'
    },
    headerLabelClass: {
      type: String,
      default: 'font-medium'
    },
    baseClass: {
      type: String,
      default: 'bg-white dark:bg-gray-800/40 rounded-lg border overflow-hidden'
    },
    bodyClass: {
      type: String,
      default: 'p-5'
    },
    flatClass: {
      type: String,
      default: 'bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-800 shadow-none overflow-hidden'
    },
    footerClass: {
      type: String,
      default: 'px-5 py-3 border-t'
    }
  },
  data () {
    return {
      visible: !this.expandable,
      expandableClass: 'hover:bg-green-100 cursor-pointer transition-all duration-500 ease-in-out cursor-pointer',
      actionableClass: 'transition ease-in-out duration-300 transform hover:-translate-y-1 hover:shadow-md'
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    },
    currentClass () {
      const classes = [
        `${this.$options._componentTag}`
      ]
      if (this.flat) {
        classes.push(this.flatClass)
      } else {
        classes.push(this.baseClass)
      }
      if (this.actionable) {
        classes.push(this.actionableClass)
      }

      return classes
    }
  },
  mounted () {
    this.visible = !this.collapsed
  },
  methods: {
    toggle () {
      this.visible = !this.visible
    }
  }
}
</script>
