import APIObject from '../object'
import Enum from '../enums'
import { DateYMD } from '../dates'

export default class EmployeeHour extends APIObject {
  relations () {
    return {
      date: { type: DateYMD },
      type: { type: EmployeeHourType },
      rosterFrequency: { type: EmployeeHourRosterFrequency },
      rosterWeek: { type: EmployeeHourRosterWeek },
      leaveType: { type: EmployeeHourLeaveType }
    }
  }

  get isDefaultHours () {
    return this.type === EmployeeHourType.defaultHour
  }

  get isOverrideHours () {
    return this.type === EmployeeHourType.override
  }

  get isLeaveTypeNone () {
    return this.leaveType === EmployeeHourLeaveType.none
  }

  get isLeaveTypeSick () {
    return this.leaveType === EmployeeHourLeaveType.sick
  }

  get isLeaveTypeHoliday () {
    return this.leaveType === EmployeeHourLeaveType.holiday
  }

  get isLeaveTypeOther () {
    return this.leaveType === EmployeeHourLeaveType.other
  }

  get isOnLeave () {
    return !this.isLeaveTypeNone
  }

  get isRosterFrequencyWeekly () {
    return this.rosterFrequency === EmployeeHourRosterFrequency.weekly
  }

  get isRosterFrequencyFortnightly () {
    return this.rosterFrequency === EmployeeHourRosterFrequency.fortnightly
  }

  get isRosterFrequencyMonthly () {
    return this.rosterFrequency === EmployeeHourRosterFrequency.monthly
  }

  get status () {
    if (this.isOnLeave) {
      return EmployeeHourRosterStatus.leave
    }

    if (this.working) {
      return EmployeeHourRosterStatus.working
    }

    return EmployeeHourRosterStatus.notWorking
  }

  set status (value) {
    if (value === EmployeeHourRosterStatus.leave) {
      this.working = false
      this.leaveType = EmployeeHourLeaveType.holiday
    } else if (value === EmployeeHourRosterStatus.working) {
      this.working = true
      this.leaveType = EmployeeHourLeaveType.none
    } else {
      this.working = false
      this.leaveType = EmployeeHourLeaveType.none
    }
  }
}

export const EmployeeHourRosterStatus = new Enum({
  working: { value: 'working', description: 'Working' },
  notWorking: { value: 'notWorking', description: 'Not working' },
  leave: { value: 'leave', description: 'Leave' }
})

export const EmployeeHourRosterFrequency = new Enum({
  weekly: { value: 1, description: 'Weekly' },
  fortnightly: { value: 2, description: 'Fortnightly' },
  monthly: { value: 4, description: 'Monthly' }
})

export const EmployeeHourRosterWeek = new Enum({
  a: { value: 1, description: 'A' },
  b: { value: 2, description: 'B' },
  c: { value: 3, description: 'C' },
  d: { value: 4, description: 'D' }
})

export const EmployeeHourType = new Enum({
  none: { value: 'none', description: 'None' },
  default: { value: 'default', description: 'Default' },
  override: { value: 'override', description: 'Override' }
})

export const EmployeeHourLeaveType = new Enum({
  none: { value: 0, description: 'None' },
  sick: { value: 1, description: 'Sick' },
  holiday: { value: 2, description: 'Holiday' },
  other: { value: 3, description: 'Other' }
})
