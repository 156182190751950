<template>
  <div
    :class="[
      currentClass,
      { 'border' : outline},
      { 'opacity-75' : disabled }
    ]"
  >
    <div class="relative flex items-center h-full w-full">
      <!-- pill -->
      <div
        v-if="selectedIndex !== -1 && currentValue !== undefined"
        :class="pillClass"
        :style="pillStyle"
      />

      <!-- segments -->
      <template v-for="(option, index) in normalizedOptions">
        <component :is="option.tooltip ? 'o-tooltip' : 'div'" :ref="`segment-${index}`" :key="`${ id || name || key }-${index}`" :text="option.tooltip" class="flex-grow relative">
          <div
            class="relative flex-grow flex text-sm  items-center justify-center h-full border-r border-gray-200 dark:border-gray-800 last:border-r-0 py-1"
            :class="[
              disabled || option.disabled ? 'opacity-75 cursor-not-allowed' : 'hover:text-primary-600 cursor-pointer dark:hover:text-primary-300',
              currentValue === option.value ? activeClass : 'opacity-80',
              sizeClass
            ]"
            @click="handleOptionClick(option)"
          >
            <template v-if="option.icon">
              <o-icon :icon="option.icon" />
            </template>
            
            <template v-else>
              {{ option.text }}
            </template>
          </div>
        </component>
      </template>
    </div>
  </div>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import multi from '@//mixins/fields/multi.js'

export default {
  mixins: [multi, classes],
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    name: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'flex bg-gray-100 dark:bg-gray-900 dark:border dark:border-gray-800 rounded-lg p-1 h-9 focus-within:ring-2'
    },
    activeClass: {
      type: [String, Object, Array],
      default: 'text-primary-600 active:text-primary-600 dark:text-primary-300 dark:active:text-primary-300'
    },
    pillClass: {
      type: [String, Object, Array],
      default: 'absolute left-0 inset-y-0 flex bg-white dark:bg-gray-800 shadow transition-all ease-in-out duration-200 rounded-md'
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: ''
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border border-yellow-400 bg-yellow-100 dark:bg-yellow-800/20'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border border-red-300 bg-red-100 dark:bg-red-800/20'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border border-green-300 bg-green-100 dark:bg-green-800/20'
    },
    sizeClass: {
      type: [String, Object, Array],
      default: 'px-2'
    },
    outline: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: `${Date.now()}-${Math.random()}`,
      currentValue: this.value,
      pillStyle: null
    }
  },
  computed: {
    selectedIndex () {
      return this.options.findIndex(segment => segment.value === this.currentValue)
    },
    segmentWidth () {
      return 100 / this.options.length
    }
  },
  watch: {
    value (value) {
      this.currentValue = value
    },
    currentValue (currentValue) {
      this.$emit('input', currentValue)
      this.$emit('change', currentValue)
    },
    selectedIndex: {
      handler () {
        this.calcPillStyle()
      },
      immediate: true
    }
  },
  mounted () {
    this.calcPillStyle()

    setTimeout(() => {
      this.calcPillStyle()
    }, 500)
  },
  methods: {
    calcPillStyle () {
      const selected = this.$refs[`segment-${this.selectedIndex}`]

      if (selected) {
        const segment = selected[0]

        const transform = `translateX(${segment?.offsetLeft || 0}px)`
        const opacity = this.selectedIndex === -1 ? '0' : '1'
        const width = `${segment?.offsetWidth || 0}px`

        this.pillStyle = {
          transform,
          opacity,
          width
        }
      } else {
        this.pillStyle = null
      }
    },
    handleOptionClick (option) {
      if (!this.disabled && !option.disabled) {
        this.currentValue = option.value
      }

      this.$emit('click', this.currentValue )
    }
  }
}
</script>

<style lang="scss">
  .segment-control {
    grid-auto-columns: 1fr;

    .option {

      &:active input:not(:checked) + label span {
        transform: scale(.95);
      }

      &:first-of-type {
        grid-column: 1;
        grid-row: 1;
        box-shadow: none;

        input {
          &::before
          {
            opacity: 0;
          }
        }
      }

      &:last-of-type {
        input {
          &::after
          {
            opacity: 0;
          }
        }
      }

      input {
        &:checked+label {
          cursor: default;

          &::before,
          &::after {
            background: var(--background);
            z-index: 1;
          }
        }

        &::before,
        &::after {
          content: '';
          width: 1px;
          background: rgba(142,142,147,.15) !important;
          position: absolute;
          top: 10%;
          bottom: 10%;
          border-radius: 0.25rem;
          will-change: background;
          -webkit-transition: background .2s ease;
          transition: background .2s ease;
        }

        &::before {
          left: 0;
          transform: translateX(-.5px);
        }

        &::after {
          right: 0;
          transform: translateX(.5px);
        }
      }
    }
  }
</style>
