<template>
  <component
    :is="scroll ? 'o-scroll' : 'div'"
    :id="id"
    ref="radio-group"
    class="p-1"
  >
    <div>
      <div
        v-for="(option, index) in normalizedOptions"
        :key="`${ id || name }-${index}`"
      >
        <radio
          :id="`${ id || name || key }-${index}`"
          v-model="currentValue"
          :value="option.value"
          :name="name"
          :required="required"
          :disabled="disabled"
          :status="status"
          :size="size"
          :class="inputClass"
          :base-class="radioBaseClass"
          :option-class="optionClass"
          :option-class-active="optionClassActive"
          :label="option.text || option.label"
          :info="option.info"
          @input="onInput"
          @blur="onBlur"
          @focus="onFocus"
        >
          <slot name="option" :option="option" :selected="value === option.value" />
        </radio>
      </div>
    </div>
  </component>
</template>

<script>
import Radio from './Radio'
import multi from '@//mixins/fields/multi.js'
import classes from '@//mixins/fields/classes.js'

export default {
  components: {
    Radio
  },
  mixins: [multi, classes],
  props: {
    id: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: true
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'flex flex-col'
    },
    radioBaseClass: {
      type: [String, Object, Array],
      default: undefined
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: ''
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'text-red-700'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'text-green-700'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'text-yellow-700'
    },
    disabledClass: {
      type: [String, Object, Array],
      default: 'cursor-not-allowed opacity-75'
    },
    defaultSizeClass: {
      type: [String, Object, Array],
      default: ''
    },
    largeSizeClass: {
      type: [String, Object, Array],
      default: 'text-lg'
    },
    smallSizeClass: {
      type: [String, Object, Array],
      default: 'text-sm'
    },
    inputClass: {
      type: [String, Object, Array],
      default: ''
    },
    labelClass: {
      type: [String, Object, Array],
      default: 'w-full ml-2 text-gray-800 flex justify-between items-center'
    },
    optionClass: {
      type: [String, Object, Array],
      default: undefined
    },
    optionClassActive: {
      type: [String, Object, Array],
      default: undefined
    }
  },
  data () {
    return {
      key: `${Date.now()}-${Math.random()}`,
      currentValue: this.value
    }
  },
  watch: {
    value (value) {
      this.currentValue = value
    },
    currentValue (currentValue) {
      this.$emit('input', currentValue)
      this.$emit('change', currentValue)
    }
  },
  methods: {
    onInput (value) {
      this.currentValue = value
    },
    onBlur (e) {
      this.$emit('blur', e)
    },
    onFocus (e) {
      this.$emit('focus', e)
    }
  }
}
</script>
