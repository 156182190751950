export default (context, inject) => {
  const plugin = (content) => {
    console.log({ content })
    const styles = document.getElementsByTagName('style')

    const head = [...styles].map((style) => {
      return style.outerHTML
    }).join('')

    const page = window.open('about:blank', 'print_preview')

    page.document.write(`
        <!DOCTYPE html>
          <html>
            <head>
              ${head}
              <style>
                html {
                  overflow-x: visible;
                  overflow-y: visible;
                }

                body {
                  pointer-events: none
                }

                ::-webkit-scrollbar {
                  display: none;
                }
              </style>
            </head>
            <body>${content}</body>
          </html>
        `)

    setTimeout(() => {
      page.document.close()

      page.focus()
      page.print()
      page.close()
    }, 1000)
  }

  inject('print', plugin)
}
