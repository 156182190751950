export default (context, inject) => {
  const plugin = {
    format (amount = 0) {
      const symbol = this.symbol
      const value = Number(amount)
      const number = (value || 0).toFixed(2)
      const negative = number < 0

      if (negative) {
        return `-${symbol}${Math.abs(number)}`
      }

      return `${symbol}${number}`
    },
    get symbol () {
      return context.$auth.info.location.currencySymbol
    }
  }

  inject('currency', plugin)
}
