<template>
  <div>
    <segment-field
      v-model="_value.hasDob"
      class="mb-1"
      :options="[
        { label: 'Has D.O.B.', value: 'true' },
        { label: 'Has no D.O.B.', value: 'false' }
      ]"
      outline
      base-class="flex rounded-lg p-1 h-9 focus-within:ring-2"
    />
    <div
      v-if="_value.hasDob === 'true'"
      :class="currentClass"
    >
      <div class="flex flex-grow items-start">
        <div class="w-1/4 flex-shrink-0 text-xs px-3 py-2 text-gray-600 dark:text-gray-300">
          Date
        </div>
        <div class="flex-grow">
          <select-field
            v-model="_value.day"
            :disabled="_value.hasDob === 'false'"
            :border="false"
            :highlight="false"
            placeholder="Any"
            :options="dayOptions"
          />
        </div>
      </div>

      <div class="flex flex-grow items-start">
        <div class="w-1/4 flex-shrink-0 text-xs px-3 py-2 text-gray-600 dark:text-gray-300">
          Month
        </div>
        <div class="flex-grow">
          <select-field
            v-model="_value.month"
            :disabled="_value.hasDob === 'false'"
            :border="false"
            :highlight="false"
            placeholder="Any"
            :options="monthOptions"
          />
        </div>
      </div>

      <div class="flex flex-grow items-start">
        <div class="w-1/4 flex-shrink-0 text-xs px-3 py-2 text-gray-600 dark:text-gray-300">
          Year
        </div>
        <div class="flex-grow">
          <select-field
            v-model="_value.year"
            :disabled="_value.hasDob === 'false'"
            :border="false"
            :highlight="false"
            placeholder="Any"
            :options="yearOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import classes from '@/mixins/fields/classes.js'
import { SelectField, SegmentField } from '@/components/fields'

export default {
  components: {
    SelectField,
    SegmentField
  },
  mixins: [classes],
  props: {
    value: {
      type: Object,
      required: true
    },
    baseClass: {
      type: [String, Object, Array],
      default: 'border border-gray-200 rounded-md bg-white overflow-hidden divide-y divide-gray-200 focus-within:ring-2 dark:border-gray-800 dark:bg-gray-900 dark:divide-gray-800'
    },
    defaultStatusClass: {
      type: [String, Object, Array],
      default: 'bg-white'
    },
    warningStatusClass: {
      type: [String, Object, Array],
      default: 'border border-yellow-400 bg-yellow-100 dark:bg-yellow-400/20'
    },
    errorStatusClass: {
      type: [String, Object, Array],
      default: 'border border-red-300 bg-red-100 dark:bg-red-400/20'
    },
    successStatusClass: {
      type: [String, Object, Array],
      default: 'border border-green-300 bg-green-100 dark:bg-green-400/20'
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    dayOptions () {
      const options = []
      const days = this.$dateFns.getDaysInMonth(new Date(this._value.year || 2000, this._value.month || 0))

      for (let i = 1; i <= days; i++) {
        options.push({ text: i, value: i })
      }

      return options
    },
    monthOptions () {
      const options = []

      for (let i = 0; i < 12; i++) {
        options.push({ text: this.$date.format(new Date(0, i), 'MMMM'), value: i })
      }

      return options
    },
    yearOptions () {
      const options = []
      const total = 100
      const current = this.$dateFns.getYear(new Date())

      for (let i = current; i >= current - total; i--) {
        options.push({ text: i, value: i })
      }

      return options
    }
  },
  watch: {
    'value.month' () {
      this.validateDay()
    },
    'value.year' () {
      this.validateDay()
    },
    'value.hasDob' (value) {
      if (value === 'false') {
        this._value = { day: null, month: null, year: null, hasDob: value }
      }
    }
  },
  methods: {
    validateDay () {
      const days = this.$dateFns.getDaysInMonth(new Date(this._value.year || 2000, this._value.month || 1))
      if (this._value.day > days) {
        this._value.day = null
      }
    }
  }
}
</script>
