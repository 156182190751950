import localforage from 'localforage'
import * as API from '~/api'

export const state = () => ({
  segments: [],
  sync: {
    running: false,
    progress: null,
    date: null,
    error: false
  },
  hydrated: false,
  count: null
})

export const mutations = {
  setSync (state, { running, progress, date }) {
    if (!(running === undefined)) {
      state.sync.running = running
    }
    if (!(progress === undefined)) {
      state.sync.progress = progress
    }
    if (!(date === undefined)) {
      state.sync.date = date
    }
  },
  updateSegments (state, segments) {
    state.segments = Object.freeze(API.Segment.ensure(segments))
  },
  setHydrated (state, hydrated) {
    state.hydrated = hydrated
  },
  setCount (state, count) {
    state.count = count
  }
}

export const getters = {
  isSyncing (state) {
    return state.sync.running
  },
  syncProgress (state) {
    return state.sync.progress
  },
  syncDate (state) {
    return state.sync.date
  },
  all (state) {
    return state.segments
  },
  count (state) {
    return state.segments.length
  }
}

export const actions = {
  async sync ({ commit, state, rootState, rootGetters  }, { force = false, change = false } = {}) {
    const guid = rootState.auth.active
    const info = rootGetters['auth/info']
    const features = info?.features

    if (features && !features.includes('segments')) {
      return
    }

    // Check permission
    if (this.$grants && !this.$grants.all({ segment: ['view'] })) {
      console.log('no segment permissions')
      return
    }

    if (!state.hydrated || change) {
      const segments = await localforage.getItem(guid + '.segments.segments')
      const sync = await localforage.getItem(guid + '.segments.sync')

      if (segments) {
        commit('updateSegments', segments)
      } else {
        commit('updateSegments', [])
      }

      if (sync) {
        commit('setSync', sync)
      } else {
        commit('setSync', { date: null, progress: null, running: false })
      }

      commit('setHydrated', true)
    }

    const date = new Date()

    commit('setSync', { running: true, progress: 0 })

    const sinceDate = (force) ? null : state.sync.date

    const existing = {}
    if (!force) {
      for (let i = 0; i < state.segments.length; ++i) {
        const segments = state.segments[i]
        existing[segments.idSegment] = segments
      }
    }

    const errors = []

    try {
      await API.Segment.listAll(sinceDate, (page, pageCount, list) => {
        if (list.items) {
          for (let i = 0; i < list.items.length; ++i) {
            const segments = list.items[i]
            existing[segments.idSegment] = segments
          }
        }

        if (list.deleted) {
          for (let i = 0; i < list.deleted.length; ++i) {
            const id = list.deleted[i]
            delete existing[id]
          }
        }

        commit('setSync', { running: true, progress: (page / pageCount) })
      })
    } catch (e) {
      if (e?.status === 304) {
        console.log('Segment data is up to date')
      } else {
        errors.push(e)
      }
    } finally {
      commit('updateSegments', Object.values(existing))

      if (errors.length) {
        commit('setSync', { running: false, progress: null, date: null, error: true })
      } else {
        commit('setSync', { running: false, progress: null, date })
      }

      localforage.setItem(guid + '.segments.segments', state.segments)
      localforage.setItem(guid + '.segments.sync', state.sync)
    }
  }
}
