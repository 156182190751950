import Model, { Request } from '../model'
import Enum from '../enums'

export default class Upload extends Model {
  static modelName () {
    return 'upload'
  }

  objectID () {
    return this.idPhoto
  }

  relations () {
    return {
      dataType: { type: DataType }
    }
  }

  static upload ({ file, params = {} }, options = {}) {
    const url = this.modelBaseURL()

    const formData = new FormData()
    formData.append('photo', file)

    for (const param in params) {
      formData.append(param, params[param])
    }

    const request = Request.post(url, formData)
    request.options = options

    return this.requestItem(request, this)
  }

  get isDataTypePhoto () {
    return this.dataType === DataType.photo
  }

  get isDataTypeDocument () {
    return this.dataType === DataType.document
  }
}

export const DataType = new Enum({
  photo: { value: 0, description: 'Photo' },
  document: { value: 1, description: 'Document' }
})
