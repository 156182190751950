var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[{
    [_vm.classNameActive]: _vm.enabled,
    [_vm.classNameDragging]: _vm.dragging,
    [_vm.classNameResizing]: _vm.resizing,
    [_vm.classNameDraggable]: _vm.draggable,
    [_vm.classNameResizable]: _vm.resizable
  }, _vm.className, 'group'],style:(_vm.style),on:{"mousedown":_vm.elementMouseDown,"touchstart":_vm.elementTouchDown}},[_vm._t("default"),_vm._v(" "),(_vm.resizable)?_vm._l((_vm.actualHandles),function(point){return _c('div',{key:point,class:[_vm.classNameHandle, _vm.classNameHandle + '-' + point, 'z-50 opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out p-1 text-gray-700'],on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleDown(point, $event)},"touchstart":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleTouchDown(point, $event)}}},[_c('o-icon',{attrs:{"icon":"resize","size":11}})],1)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }