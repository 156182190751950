import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

export default class Campaign extends Model {
  static modelName () {
    return 'campaign'
  }

  objectID () {
    return this.idLocationMassSend
  }

  relations () {
    return {
      type: { type: CampaignType },
      status: { type: CampaignStatus },
      attachments: { type: CampaignAttachment },
      contentType: { type: CampaignContentType }
    }
  }

  get reachedLabel () {
    const data = this.analyticsData
    return Math.round((Number(data.clicked) / Number(data.total) || 0) * 100) + '%'
  }

  get openedLabel () {
    const data = this.analyticsData
    return Math.round((Number(data.opened) / Number(data.total) || 0) * 100) + '%'
  }

  get unopenedLabel () {
    const data = this.analyticsData
    return Math.round(((Number(data.total) - Number(data.opened)) / Number(data.total) || 0)  * 100) + '%'
  }

  get clickedLabel () {
    const data = this.analyticsData
    return Math.round((Number(data.clicked) / Number(data.total) || 0) * 100) + '%'
  }

  get isCampaignTypeEmail () {
    return this.type === CampaignType.email
  }

  get isCampaignTypeSMS () {
    return this.type === CampaignType.sms
  }

  get isCampaignStatusNone () {
    return this.status === CampaignStatus.none
  }

  get isCampaignStatusQueued () {
    return this.status === CampaignStatus.queued
  }

  get isCampaignStatusSending () {
    return this.status === CampaignStatus.sending || this.status === CampaignStatus.building
  }

  get isCampaignStatusSent () {
    return this.status === CampaignStatus.sent
  }

  get isCampaignStatusError () {
    return this.status === CampaignStatus.error
  }

  get isCampaignStatusDraft () {
    return this.status === CampaignStatus.draft
  }

  get isCampaignStatusScheduled () {
    return this.status === CampaignStatus.scheduled
  }

  get isContentTypeMarketing () {
    return this.contentType === CampaignContentType.marketing
  }

  get isContentTypeAdministrative () {
    return this.contentType === CampaignContentType.administrative
  }

  static filtered ({ page = 1, filters = {} } = {}) {
    let url = this.modelBaseURL() + '/?page=' + page

    for (const filter in filters) {
      if (filters[filter] !== null) {
        url += `&filter.${filter}=${filters[filter]}`
      }
    }

    return this.requestList(Request.get(url), Campaign)
  }

  stats () {
    const url = this.constructor.modelBaseURL() + '/stats?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), CampaignStats)
  }

  sendPreview () {
    const url = this.constructor.modelBaseURL() + '/preview?id=' + this.objectID()
    return this.constructor.requestSuccess(Request.get(url))
  }

  send ({ scheduledDate = null } = {}) {
    let url = this.constructor.modelBaseURL() + '/send?id=' + this.objectID()

    if (scheduledDate) {
      url += '&scheduledDate=' + scheduledDate
    }

    return this.constructor.requestSuccess(Request.get(url))
  }

  emails ({ type, page = 1 } = {}) {
    const url = this.constructor.modelBaseURL() + '/emails?id=' + this.objectID() + '&page=' + page + '&type=' + type
    return this.constructor.requestList(Request.get(url), CampaignEmail)
  }

  calculateCredits () {
    const url = this.constructor.modelBaseURL() + '/calculateCredits?id=' + this.objectID()
    return this.constructor.requestData(Request.get(url))
  }

  addAttachment ({ attachment, idPhoto } = {}, options) {
    const url = this.constructor.modelBaseURL() + '/addAttachment?id=' + this.objectID()

    const formData = new FormData()

    if (attachment) {
      formData.append('attachment', attachment)
    }

    if (idPhoto) {
      formData.append('idPhoto', idPhoto)
    }

    const request = Request.post(url, formData)
    request.options = options

    return this.constructor.requestItem(request, Campaign)
  }

  removeAttachment (attachment) {
    const guid = attachment.guid
    const url = this.constructor.modelBaseURL() + '/removeAttachment?id=' + this.objectID() + '&guid=' + guid
    return this.constructor.requestItem(Request.get(url), Campaign)
  }
}

export class CampaignAttachment extends APIObject {
  relations () {
    return {

    }
  }

  get isImage () {
    return ['image/png', 'image/jpeg', 'image/gif'].includes(this.type)
  }
}

export class CampaignStats extends APIObject {
  relations () {
    return {
      recentOpens: { type: CampaignEmail }
    }
  }
}

export class CampaignEmail extends APIObject {
  relations () {
    return {
    }
  }
}

export const CampaignType = new Enum({
  email: { value: 0, description: 'Email' },
  sms: { value: 1, description: 'SMS' }
})

export const CampaignStatus = new Enum({
  none: { value: 0, description: 'None' },
  queued: { value: 1, description: 'Queued' },
  sending: { value: 2, description: 'Sending' },
  sent: { value: 3, description: 'Sent' },
  error: { value: 4, description: 'Error' },
  draft: { value: 5, description: 'Draft' },
  scheduled: { value: 6, description: 'Scheduled' },
  automated: { value: 7, description: 'Automated' },
  building: { value: 8, description: 'Sending' },
})

export const CampaignContentType = new Enum({
  marketing: { value: 0, description: 'Marketing' },
  administrative: { value: 1, description: 'Administrative' }
})
