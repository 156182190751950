import Model from '../model'
// import APIObject from '../object'
import Enum from '../enums'

export default class ReservationCancellationType extends Model {
  static modelName () {
    return 'ReservationCancellationType'
  }

  objectID () {
    return this.idReservationCancellationType
  }

  relations () {
    return {
      displayMode: { type: ReservationCancellationTypeDisplayMode }
    }
  }
}

export const ReservationCancellationTypeDisplayMode = new Enum({
  both: { value: 0, description: 'Both' },
  internal: { value: 1, description: 'Internal' },
  customer: { value: 2, description: 'Customer' }
})
