<template>
  <div class="relative bg-primary-50 h-screen w-screen dark:bg-gray-900">
    <router-view />

    <portal-target name="modal-container" multiple />
    <portal-target name="dialog-container" multiple />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import theme from '@/mixins/layout/theme'

export default {
  mixins: [theme],
  mounted () {
    this.setWidth(window.innerWidth)

    window.addEventListener('resize', () => {
      this.setWidth(window.innerWidth)
    })
  },
  methods:{
    ...mapMutations([
      'setWidth',
      'setMobile'
    ])
  }
}
</script>
