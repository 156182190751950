import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Employee from './employee'

export default class Role extends Model {
  static modelName () {
    return 'role'
  }

  objectID () {
    return this.idCustomRole
  }

  relations () {
    return {
      employees: { type: Employee },
      type: { type: RoleType }
    }
  }

  static grants () {
    const url = this.modelBaseURL() + '/grants'
    return this.requestItem(Request.get(url), RoleGrants)
  }

  static bundles () {
    const url = this.modelBaseURL() + '/bundles'
    return this.requestList(Request.get(url), RoleGrantBundles)
  }

  static builtIns () {
    const url = this.modelBaseURL() + '/builtIns'
    return this.requestList(Request.get(url), this)
  }

  mapped (grants) {
    const role = {}

    for (const grant of this.grants) {
      const url = new URL(grant)
      const [, , model, action] = url.pathname.split('/')

      if (!role[model]) {
        // role[model] = []
        role[model] = {}
      }
      const query = grant.split('?')[1]
      role[model][action] = { query }
    }

    const mapped = {}

    for (const [key, value] of Object.entries(grants)) {
      mapped[key] = {
        grants: {},
        disabled: value.disabled,
        dependencies: value.dependencies
      }

      for (const grant of value.actions) {
        mapped[key].grants[grant] = {
          name: grant,
          enabled: !!(role[key] && role[key][grant]),
          params: role[key] && role[key][grant] ? role[key][grant].query : ''
        }
      }
    }

    return mapped
  }

  get isRoleTypeOwner () {
    return this.type === RoleType.owner
  }

  get isRoleTypeAdmin () {
    return this.type === RoleType.admin
  }

  get isRoleTypeLegacy () {
    return this.type === RoleType.legacy
  }

  get isRoleTypeEmployee () {
    return this.type === RoleType.employee
  }

  get isRoleTypeRestrictedEmployee () {
    return this.type === RoleType.restricted
  }

  get isRoleTypeCustom () {
    return this.type === RoleType.custom
  }
}

export class RoleBuiltIn extends Model {
  static modelName () {
    return 'role'
  }

  objectID () {
    return this.name
  }

  relations () {
    return {
      employees: { type: Employee }
    }
  }
}

export class RoleGrants extends APIObject {
  relations () {
    return {

    }
  }
}

export class RoleGrantBundles extends APIObject {
  relations () {
    return {

    }
  }
}

export const RoleType = new Enum({
  owner: { value: 'owner', description: 'Owner' },
  admin: { value: 'admin', description: 'Admin' },
  legacy: { value: 'legacy', description: 'Legacy' },
  employee: { value: 'employee', description: 'Employee' },
  restricted: { value: 'restrictedEmployee', description: 'Restricted employee' },
  custom: { value: 6, description: 'Custom' }
})
