import localforage from 'localforage'

export default class Controller {
  constructor (model, label, config = {}) {
    this.model = model
    this.label = label

    this.feature = config.feature
  }

  get state () {
    return () => ({
      items: [],
      sync: {
        running: false,
        progress: null,
        date: null,
        error: false
      },
      hydrated: false,
      count: null
    })
  }

  get mutations () {
    const { model } = this

    return {
      setSync (state, { running, progress, date }) {
        if (!(running === undefined)) {
          state.sync.running = running
        }
        if (!(progress === undefined)) {
          state.sync.progress = progress
        }
        if (!(date === undefined)) {
          state.sync.date = date
        }
      },
      update (state, items) {
        state.items = Object.freeze(model.ensure(items))
      },
      setHydrated (state, hydrated) {
        state.hydrated = hydrated
      },
      setCount (state, count) {
        state.count = count
      }
    }
  }

  get getters () {
    return {
      isSyncing (state) {
        return state.sync.running
      },
      syncProgress (state) {
        return state.sync.progress
      },
      syncDate (state) {
        return state.sync.date
      },
      all (state) {
        return state.items
      },
      count (state) {
        return state.items?.length
      }
    }
  }

  get actions () {
    const { model, label, feature } = this

    return {
      async sync ({ commit, state, rootState, rootGetters }, { force = false, change = false } = {}) {
        const guid = rootState.auth.active
        const info = rootGetters['auth/info']
        const features = info?.features

        if (feature && features && !features.includes(feature)) {
          return
        }

        const modelName = model.modelName()
        const grants = {}
        grants[modelName] = ['view']

        // Check permission
        if (this.$grants && !this.$grants.all(grants)) {
          return
        }

        if (!state.hydrated || change) {
          const items = await localforage.getItem(`${guid}.${label}.items`)
          const sync = await localforage.getItem(`${guid}.${label}.sync`)

          if (items) {
            commit('update', items)
          } else {
            commit('update', [])
          }

          if (sync) {
            commit('setSync', sync)
          } else {
            commit('setSync', { date: null, progress: null, running: false })
          }

          commit('setHydrated', true)
        }

        const date = new Date()

        commit('setSync', { running: true, progress: 0 })

        const sinceDate = (force) ? null : state.sync.date

        const existing = {}

        if (!force) {
          for (let i = 0; i < state.items.length; ++i) {
            const items = state.items[i]
            existing[items.objectID()] = items
          }
        }

        const errors = []

        try {
          await model.listAll(sinceDate, (page, pageCount, list) => {
            if (list.items) {
              for (let i = 0; i < list.items.length; ++i) {
                const items = list.items[i]
                existing[items.objectID()] = items
              }
            }

            if (list.deleted) {
              for (let i = 0; i < list.deleted.length; ++i) {
                const id = list.deleted[i]
                delete existing[id]
              }
            }

            commit('setSync', { running: true, progress: (page / pageCount) })
          })
        } catch (e) {
          if (e?.status === 304) {
            console.log(`${label} data is up to date`)
          } else {
            errors.push(e)
          }
        } finally {
          commit('update', Object.values(existing))

          if (errors.length) {
            commit('setSync', { running: false, progress: null, date: null, error: true })
          } else {
            commit('setSync', { running: false, progress: null, date })
          }

          localforage.setItem(`${guid}.${label}.items`, state.items)
          localforage.setItem(`${guid}.${label}.sync`, state.sync)
        }
      }
    }
  }
}

