<template>
  <div class="flex items-center fixed inset-0 bg-gray-100 dark:bg-gray-900 h-screen w-screen overflow-hidden text-gray-900 dark:text-white z-80000">

    <div
      :class="[
        'inset-0 h-full w-full bg-cover bg-center absolute bg-no-repeat',
        'bg-gray-200/50 dark:bg-gray-950/30'
      ]"
      :style="mask"
    />

    <div class="relative mx-auto max-w-md px-5 text-center">
      <div class="text-6xl font-dark font-bold leading-tight">
        {{ error.statusCode || 'Error' }}
      </div>
      <p class="text-2xl tracking-tighter font-light leading-normal">
        {{ error.message || 'Sorry, something went wrong...' }}
      </p>
      <p class="mb-8 mt-8">
        Contact hello@ovatu.com if you need some help
      </p>

      <o-button to="/" size="lg" variant="primary">
        Back to safety
      </o-button>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    error: {
      type: [Number, String, Array, Object, Boolean]
    }
  },
  mounted () {
    console.log(this.error)
  },
  computed: {
    mask () {
      const imgUrl = '/images/illustrations/illustration-green.webp'

      return {
        '-webkit-mask-image': `url('${imgUrl}')`,
        'mask-image': `url('${imgUrl}')`,
        // '-webkit-mask-size': '130%',
        // 'mask-size': '130%',
        'webkit-mask-position': 'center',
        'mask-position': 'center',
        '-webkit-mask-size': 'cover',
        'mask-size': 'cover',

      }
    }
  }
}
</script>

<style lang="scss">
  $gradient-2: rgb(0, 154, 255);
  $gradient-1: rgb(24, 198, 82);
  $gradient-3: rgb(68, 93, 140);
  $gradient-4: yellow;

  .gradient-background {
    background: {
      image:
        radial-gradient(rgba($gradient-1, 0.5), rgba($gradient-1, 0) 40vw),
        radial-gradient(rgba($gradient-2, 0.3), rgba($gradient-2, 0) 40vw),
        radial-gradient(rgba($gradient-3, 0.5), rgba($gradient-3, 0) 40vw);
      position:
        -40vw 14rem,
        50% 10rem,
        60vw 14rem,
        -10vw calc(14rem + 20vw),
        30vw calc(14rem + 20vw);
      size: 80vw 80vw;
      repeat: no-repeat;
    }
  }
</style>
