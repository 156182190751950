import Model from '../model'
import Enum from '../enums'

export default class DiscountModel extends Model {
  static modelName () {
    return 'discountModel'
  }

  objectID () {
    return this.idDiscountModel
  }

  relations () {
    return {
      amountType: { type: DiscountAmountType },
      discountTaxType: { type: DiscountTaxType }
    }
  }

  get isAmountTypeFixed () {
    return this.amountType === DiscountAmountType.fixed
  }

  get isAmountTypePercentage () {
    return this.amountType === DiscountAmountType.percentage
  }
}

export const DiscountAmountType = new Enum({
  fixed: { value: 0, description: 'Fixed' },
  percentage: { value: 1, description: 'Percentage' }
})

export const DiscountTaxType = new Enum({
  post: { value: 0, description: 'Post' },
  pre: { value: 1, description: 'Pre' }
})
