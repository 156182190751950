<template>
  <ValidationObserver ref="form" v-slot="slot">
    <slot v-bind="slot" />
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'OForm',
  components: {
    ValidationObserver
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    reset () {
      return this.$refs.form.reset()
    }
  }
}
</script>
