import Model from '../model'

export default class PaymentModel extends Model {
  static modelName () {
    return 'paymentModel'
  }

  objectID () {
    return this.idPaymentModel
  }

  relations () {
    return {
    }
  }
}
