import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import { FilterController, RemoteFilter, FilterOptions } from '../filter'

import CustomField from './customField'
import { EmployeeService } from './employee'
import Images from './images'
import ServiceCategory from './serviceCategory'
import ServiceResource from './serviceResource'
import ServiceForm from './serviceForm'
import Tax from './tax'

export default class Service extends Model {
  static modelName () {
    return 'service'
  }

  objectID () {
    return this.idService
  }

  relations () {
    return {
      category: { type: ServiceCategory },
      customerTimeMode: { type: ServiceCustomerTimeMode },
      customerType: { type: ServiceCustomerType },
      customFields: { type: CustomField },
      employees: { type: EmployeeService },
      images: { type: Images },
      options: { type: ServiceOptions },
      resources: { type: ServiceResource },
      reviewReminderMode: { type: ServiceReviewReminderMode },
      bufferTimeMode: { type: ServiceBufferTimeMode },
      forms: { type: ServiceForm },
      onlinePaymentDepositFixedType: { type: ServiceOnlinePaymentDepositFixedType }
    }
  }

  static filter ({ page, filters = {}, limit = 20 } = {}) {
    const url = this.modelBaseURL() + '/list?page=' + page + '&limit=' + limit
    const request = {
      ...filters
    }

    return this.requestList(Request.post(url, JSON.stringify(request)), this)
  }

  matchesQuery (query) {
    if (this.title && this.title.toLowerCase().contains(query.toLowerCase())) {
      return true
    }

    return false
  }

  duplicate () {
    const url = this.constructor.modelBaseURL() + '/duplicate?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url), this.constructor)
  }

  resourcesForAssignment (assignment) {
    const assignmentResources = []
    for (const serviceResource of this.resources) {
      if (serviceResource.assignment === assignment) {
        assignmentResources.push(serviceResource)
      }
    }
    return assignmentResources
  }

  durationForEmployee (employee) {
    if (employee) {
      const employeeService = employee.getEmployeeServiceForService(this)

      if (employeeService && employeeService.duration) {
        return employeeService.duration
      }
    }

    return this.duration
  }

  processingForEmployee (employee) {
    if (employee) {
      const employeeService = employee.getEmployeeServiceForService(this)

      if (employeeService && employeeService.processing) {
        return employeeService.processing
      }
    }

    return this.processing
  }

  finishingForEmployee (employee) {
    if (employee) {
      const employeeService = employee.getEmployeeServiceForService(this)

      if (employeeService && employeeService.finishing) {
        return employeeService.finishing
      }
    }

    return this.finishing
  }

  priceForEmployee (employee) {
    if (employee) {
      const employeeService = employee.getEmployeeServiceForService(this)

      if (employeeService && employeeService.price) {
        return employeeService.price
      }
    }

    return this.price
  }

  addForm (serviceForm) {
    const url = this.constructor.modelBaseURL() + '/addForm?id=' + this.objectID()

    const data = {
      idForm: serviceForm.form.idForm,
      ...serviceForm.toJSON()
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  updateForm (serviceForm) {
    const url = this.constructor.modelBaseURL() + '/updateForm?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, serviceForm.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  removeForm (serviceForm) {
    const url = this.constructor.modelBaseURL() + '/removeForm?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, serviceForm.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addResource (serviceResource) {
    const url = this.constructor.modelBaseURL() + '/addResource?id=' + this.objectID()

    const data = {
      idEmployee: serviceResource.resource?.idEmployee,
      idResourceType: serviceResource.resourceType?.idResourceType,
      ...serviceResource.toJSON()
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  updateResource (serviceResource) {
    const url = this.constructor.modelBaseURL() + '/updateResource?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, serviceResource.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  removeResource (serviceResource) {
    const url = this.constructor.modelBaseURL() + '/removeResource?id=' + this.objectID()
    return this.constructor.requestItem(Request.post(url, serviceResource.toJSON()), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  addPhoto (image, options) {
    const url = this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('image', image)

    const request = Request.post(url, formData)
    request.options = options
    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deletePhoto () {
    const url = this.constructor.modelBaseURL() + '/deletePhoto?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  additionalPropertiesForReorder () {
    return {
      idServiceCategory: this.idServiceCategory
    }
  }

  updatePrices ({ updateEmployees = false, updateFutureReservations = false } = {}) {
    const url = this.constructor.modelBaseURL() + '/updatePrices?id=' + this.objectID()

    const data = {}

    // if (updateEmployees) {
    data.updateEmployees = updateEmployees
    // }

    // if (updateFutureReservations) {
    data.updateFutureReservations = updateFutureReservations
    // }

    return this.constructor.requestSuccess(Request.post(url, data))
  }

  static categorised (modifiedSince = null) {
    const url = this.modelBaseURL() + '/categorised'
    let headers = {}

    if (modifiedSince) {
      headers = {
        'if-modified-since': modifiedSince
      }
    }
    return this.requestList(Request.get(url, headers), ServiceCategorised)
  }

  get description () {
    return this.title
  }

  get customerTypeLabel () {
    switch (this.customerType.value) {
      case 0:
        return 'Single'
      case 1:
        return 'Group / Class'
    }
  }

  get image () {
    if (this.hasImage) {
      return this.images.square
    }

    return null
  }

  get initials () {
    return this.title?.charAt(0).toUpperCase()
  }

  get overridesLabel () {
    let label = ''

    label += this.overrides.hasPriceOverrides && !this.overrides.hasZeroPricesOverrides ? 'Some employees have price overrides. ' : ''
    label += this.overrides.hasZeroPricesOverrides ? 'Some employees have 0 price overrides. ' : ''
    label += this.overrides.hasTimingOverrides ? 'Some employees have timing overrides. ' : ''

    return label
  }

  get formsLabel () {
    const forms = this.forms.map(form => form.form.title)

    return forms.join(', ')
  }

  get resourcesLabel () {
    const resources = this.resources.map(resource => resource.name)

    return resources.join(', ')
  }

  get isServiceCustomerTypeSingle () {
    return this.customerType === ServiceCustomerType.single
  }

  get isServiceCustomerTypeGroup () {
    return this.customerType === ServiceCustomerType.group
  }

  get hasOverrides () {
    return this.overrides.hasPriceOverrides || this.overrides.hasTimingOverrides || this.overrides.hasZeroPricesOverrides
  }
}

export class ServiceOptions extends APIObject {
  relations () {
    return {
      serviceCategories: { type: ServiceCategory },
      taxes: { type: Tax },
      colors: { type: ServiceOptionsColor },
      customerType: { type: SeviceOptionsCustomerType }
    }
  }
}

export class ServiceOptionsColor extends APIObject {
}

export class SeviceOptionsCustomerType extends APIObject {
}

export class ServiceCategorised extends APIObject {
  relations () {
    return {
      category: { type: ServiceCategory },
      services: { type: Service }
    }
  }

  objectID () {
    if (this.category) {
      return this.category.objectID()
    } else {
      return 0
    }
  }
}

// CustomerType used as both class defintion and enum
export const ServiceCustomerType = new Enum({
  single: { value: 0, description: 'Single' },
  group: { value: 1, description: 'Group' }
})

export const ServiceReviewReminderMode = new Enum({
  none: { value: 0, description: 'None' },
  every: { value: 1, description: 'Every appointment' },
  one: { value: 2, description: 'Once' }
})

export const ServiceBufferTimeMode = new Enum({
  aggregate: { value: 0, description: 'Aggregrate all service buffer times' },
  longest: { value: 1, description: 'Longest service buffer time applies' },
  between: { value: 2, description: 'Apply buffer times between services' }
})

export const ServiceCustomerTimeMode = new Enum({
  all: { value: 0, description: 'Duration + Processing + Finishing times' },
  duration: { value: 1, description: 'Duration time only' },
  durationProcessing: { value: 2, description: 'Duration + Processing times only' }
})

export const ServiceOnlinePaymentDepositFixedType = new Enum({
  fixed: { value: 0, description: 'Fixed' },
  percentage: { value: 1, description: 'Percentage' }
})

export class ServiceFilterController extends FilterController {
  constructor (context) {
    super(Service, { context })

    this.query = new RemoteFilter('search', {
      label: 'Search',
      info: 'Search service details',
      component: 'TextField',
      getParams () {
        return this.value
      }
    })

    this.category = new RemoteFilter('category', {
      label: 'Category',
      component: 'CheckboxGroupField',
      props: {
        search: true,
        placeholder: 'Filter by category',
        class: 'h-80'
      },
      value: [],
      options: [],
      getParams () {
        return this.value.map(item => item?.idServiceCategory || null)
      },
      valueLabel () {
        return this.value.map(item => item?.name || 'No category').join(', ')
      },
      clear () {
        this.value = []
      }
    })

    this.categoryOptions = new FilterOptions({
      async map () {
        const serviceCategories = await ServiceCategory.listAll()
        return serviceCategories
          .reduce((options, serviceCategory) => {
            options.push({
              label: serviceCategory.name,
              value: serviceCategory
            })

            return options
          }, [{ label: 'No category', value: null }])
      },
      set: (options) => {
        this.category.options = options
      }
    })

    this.filters = [
      this.category
    ]
  }
}

