import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import { LocalFilterController, RemoteFilter } from '../filter'

import { plugin as $date } from '../../plugins/date'

import Employee from './employee'

export default class PurchaseOrder extends Model {
  static modelName () {
    return 'purchaseOrder'
  }

  objectID () {
    return this.idPurchaseOrder
  }

  relations () {
    return {
      status: { type: PurchaseOrderStatus },
      data: { type: PurchaseOrderData },
      employee: { type: Employee }
    }
  }

  static create ({ supplier, employee, auto = false } = {}) {
    const url = this.modelBaseURL()
    const data = {
      idSupplier: supplier.idSupplier,
      idEmployee: employee?.idEmployee,
      auto: auto ? 1 : 0
    }

    return this.requestItem(Request.post(url, data), PurchaseOrder)
  }

  static filter (filters) {
    const url = this.modelBaseURL() + '/filter'
    const request = {
      filters
    }

    return this.requestItem(Request.post(url, JSON.stringify(request)), PurchaseOrderFilterResponse)
  }

  static ids (ids, page = 1) {
    const url = this.modelBaseURL() + '/ids?page=' + page

    const data = {
      ids
    }

    return this.requestList(Request.post(url, JSON.stringify(data)), this)
  }

  static listIDs (ids) {
    // use a default runner (to call the list method)
    const that = this

    const runner = (page) => {
      return that.ids(ids, page)
    }

    return this.listRunner(runner)
  }

  send () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/send'
    return this.constructor.requestSuccess(Request.get(url))
  }

  receive () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/receive'
    return this.constructor.requestSuccess(Request.get(url))
  }

  receiveAll () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/receiveAll'
    return this.constructor.requestSuccess(Request.get(url))
  }

  receiveItems (items) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/receiveItems'
    const data = {
      items: items.map(({ idPurchaseOrderItem, receivedQuantity }) => {
        return {
          idPurchaseOrderItem,
          receivedQuantity
        }
      })
    }
    return this.constructor.requestSuccess(Request.post(url, JSON.stringify(data)))
  }

  complete () {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/complete'
    return this.constructor.requestSuccess(Request.get(url))
  }

  addItems (quantities) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/products'
    const data = {
      quantities: quantities
        .map(({ product, quantity }) => {
          return {
            product: product.idProduct,
            quantity
          }
        })
    }

    return this.constructor.requestItem(Request.post(url, data), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  getItem (purchaseOrderItem) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/products/' + purchaseOrderItem.idPurchaseOrderItem
    return this.constructor.requestItem(Request.get(url), PurchaseOrderItem)
  }

  saveItem (purchaseOrderItem) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/products/' + purchaseOrderItem.idPurchaseOrderItem
    return this.constructor.requestItem(Request.put(url, purchaseOrderItem.toJSON()), PurchaseOrderItem)
  }

  removeItem (purchaseOrderItem) {
    const url = this.constructor.modelBaseURL() + '/' + this.objectID() + '/products/' + purchaseOrderItem.idPurchaseOrderItem
    return this.constructor.requestSuccess(Request.delete(url))
  }

  get isStatusOpen () {
    return this.status === PurchaseOrderStatus.open
  }

  get isStatusSent () {
    return this.status === PurchaseOrderStatus.sent
  }

  get isStatusReceiving () {
    return this.status === PurchaseOrderStatus.receiving
  }

  get isStatusComplete () {
    return this.status === PurchaseOrderStatus.complete
  }
}

export class PurchaseOrderData extends APIObject {
  relations () {
    return {
      items: { type: PurchaseOrderItem }
    }
  }
}

export class PurchaseOrderItem extends Model {
  static modelName () {
    return 'purchaseOrderItem'
  }

  objectID () {
    return this.idPurchaseOrderItem
  }

  relations () {
    return {
    }
  }
}

export const PurchaseOrderStatus = new Enum({
  open: { value: 0, description: 'Open' },
  sent: { value: 1, description: 'Sent' },
  receiving: { value: 2, description: 'Receiving' },
  complete: { value: 3, description: 'Complete' }
})

export class PurchaseOrderFilterResponse extends APIObject {

}

export class PurchaseOrderFilterController extends LocalFilterController {
  constructor (context) {
    super(PurchaseOrder, { context })

    const { $store } = context

    this.dates = new RemoteFilter('date', {
      label: 'Dates',
      value: {
        start: null,
        finish: null
      },
      component: 'DateRangeFilter',
      validation () {
        const { start, finish } = this.value
        const valid = start && finish

        if (!valid) {
          return {
            status: false,
            message: 'Start and finish date must be provided'
          }
        }

        return null
      },
      valueLabel () {
        const start = (this.value.start) ? new Date(this.value.start * 1000) : null
        const finish = (this.value.finish) ? new Date(this.value.finish * 1000) : null

        return ((start) ? $date.format(start, $date.presets.short) : '') + ' » ' + ((finish) ? $date.format(finish, $date.presets.short) : '')
      },
      getParams () {
        return {
          from: this.value.start,
          to: this.value.finish
        }
      },
      clear () {
        this.value.start = null
        this.value.finish = null
      },
      isEmpty () {
        return !this.value.start && !this.value.finish
      }
    })

    this.filters = [
      this.dates
    ]
  }
}
