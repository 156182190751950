// eslint-disable-next-line no-undef
RedactorX.add('plugin', 'definedlinks', {
  defaults: {
    items: false
  },
  subscribe: {
    'popup.open' () {
      if (this.opts.definedlinks.items === false) { return }

      const name = this.app.popup.getName()
      if (name === 'link') {
        this._build()
      }
    }
  },

  // private
  _build () {
    const $item = this.app.popup.getFormItem('text')
    const $box = this.dom('<div>').addClass(this.prefix + '-form-item')

    // select
    this.$select = this._create()

    $box.append(this.$select)
    $item.before($box)
  },
  _change (e) {
    const key = this.dom(e.target).val()
    const data = this.opts.definedlinks.items[key]
    const $text = this.app.popup.getInput('text')
    const $url = this.app.popup.getInput('url')
    let name = data.name
    let url = data.placeholder

    if (data.url === false) {
      url = ''
      name = ''
    }

    // text
    if ($text.val() === '') {
      $text.val(name)
    }

    // url
    $url.val(url)
  },
  _create () {
    const items = this.opts.definedlinks.items
    const $select = this.dom('<select>').addClass(this.prefix + '-form-select')
    $select.on('change', this._change.bind(this))

    for (let i = 0; i < items.length; i++) {
      const data = items[i]
      const $option = this.dom('<option>')
      $option.val(i)
      $option.html(data.name)

      $select.append($option)
    }

    return $select
  }
})
